<template>
    <body>
        
       
      <div class="headerMain">
        <div class="headerContainer">
          <p style=" margin-block: 20px; font-size: 0.8rem; color:gainsboro;"> <span style="cursor: pointer; color:gainsboro;" @click="goHome"><font-awesome-icon  icon="house" class="chevron"  /></span>  <font-awesome-icon  icon="chevron-right" class="chevron"  /> {{ auth.currentUser.email }} <font-awesome-icon  icon="chevron-right" class="chevron" /><span style="color: slategray;"> Favorites </span></p>
          <h1  style=" color:navy; padding-block: 5px;">Favorites </h1>
          <p style="font-size: 0.8rem; color:slategray; max-width:500px;">Keep track of all your saved posts!</p>
       
            <div style="height:1px; background: gainsboro; width: 100%; margin-block: 25px;"></div>
              <div class="dropdown">
                <div class="select">
                    <span class="selected">Filter By</span>
                    <div class="caret"></div>
                </div>
                <ul class="menu">
                   <li v-for="option in options" :key="option" @click="filterBy(option)">
                    {{ option  }}
                   </li>
                </ul>
            </div>
     
          </div>
      </div>

    <div v-if="favoritePosts" class="promotionalPosts" id="promotions" :class="{'opacity' : loading}">
        
        <div class="promoPost" v-for="post in favoritePosts">
          <img :src="post.imageUrl" alt="company Image">
          <!-- <div id="timer" :title="post.expiration">
          <font-awesome-icon icon="clock" class="fa" style="margin-right:5px; color:white;" /> Expires:   <span style="font-weight: 700;"></span> {{ post.expiration }}
          </div>   -->
          <div id="timer" :title="post.expiration" :style="{ backgroundColor : checkExpire(post.expiration, post.id) ? 'rgb(212, 62, 62)' : 'gainsboro'}" >
            <font-awesome-icon  icon="clock" class="fa" style="margin-right:5px;" />{{ getExpireTime(post.expiration) }}
            </div>  

          <div class="companyInfo">
            <h4>{{ post.business }}</h4>
            <p class="address" @click="openMap(post.address)" style="font-size: 12px; margin-bottom: 5px; margin-top: 5px; cursor: pointer;"><font-awesome-icon icon="location-pin" class="fa" style="margin-right:10px; color: #8a8a8a;" />{{ post.address }}</p>
            <a :href="'tel:' +1+post.phone"><p class="phoneNumber" style="font-size: 12px; margin-block: 5px;"><font-awesome-icon icon="phone" class="fa" style="margin-right:10px; color: #8a8a8a;" />{{ formattedPhoneNumber(post.phone) }}</p>
            </a>
            <a v-show="post.website !== ''" :href="post.website" target="_blank"><p class="phoneNumber" style="font-size: 12px;"><font-awesome-icon icon="globe" class="fa" style="margin-right:10px; color: #8a8a8a;" />{{ post.website.substring(0,45) }}</p>
              </a>
          </div>
          <hr style="margin: 0 auto;">

  
          <div style="white-space: wrap;">
            <h3 style="text-align: center; margin-top: 5px;">{{ post.title }}</h3>
          <p style="font-size: 12px; padding:10px; text-align: center;">{{ post.description }}</p>
     
          </div>
          <p v-if="post.promoCode && post.postType == 'Promotion'"  class="couponButton"><span style="font-weight: 700; margin-right: 10px;">Promo Code:</span>{{ post.promoCode }}</p>


          <p v-if="post.postType == 'Coupon'" class="couponButton" @click="useCoupon(post)">Save Coupon</p>

          <div class="testDiv" @click="removeFavorite(post)" title="Delete">
            <font-awesome-icon icon="trash" class="fa favIcon"  />
          </div>

        </div>
        <div class="lastElement">
           <p style="font-weight: bold; font-size: 1.5rem; margin:100px auto;"></p>
          </div>

   </div>
   <div v-if="noPostsFound">
    <Vue3Lottie :animationData="nothingFoundAnimation" :height="250" :width="250" />
   </div>
   <div id="loader-container" v-show="loading">
    <div id="loader-bar"></div>
  </div>
    </body>
</template>

<script setup>
import { onMounted,  ref  } from 'vue';
import { db } from '../../firebase';
import { collection, getDocs, doc, deleteDoc, orderBy, startAfter, limit, query, where, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import nothingFoundAnimation from '../assets/animations/nothing_found_lottie_animation.json';
import { pageview } from 'vue-gtag'
import Swal from 'sweetalert2'

const favoritePosts = ref([])
const loading = ref(false)
const noPostsFound = ref(false);
const lastVisible = ref('')
const postReady = ref(false);
const userFilter = ref(false);
const userFilterSelection = ref('');
const individual = ref(true);
const userName = ref('');
const options = ref(['All', 'Promotion', 'Deal', 'Coupon']);


const auth = getAuth()
const uid = auth.currentUser.uid

let cssShowClass = {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          }
let cssHideClass = {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }

const useCoupon = async (coupon) => {
  if(!auth.currentUser) {
    toast("Login to save coupons.", {
                                "type": "info",
                                "transition": "zoom",
                                autoClose: 1000
                              })
      return false;
  }
 
  const usedQuery = query(collection(db, 'Coupons'),
                  where('postId', '==', coupon.postId),
                  where('useruid', '==', uid),
                  where('hidden', '==', true)
                 );
  const querySnapshotOne = await getDocs(usedQuery);

  if (!querySnapshotOne.empty) {
    // Post already exists in favorites
    toast("Coupon already used.", {
      "type": "info",
      "transition": "bounce",
      autoClose: 2000
    })
    return;
  }

  const q = query(collection(db, 'Coupons'),
                  where('postId', '==', coupon.postId),
                  where('useruid', '==', uid),
                 );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // Post already exists in favorites
    toast("You already have this coupon.", {
      "type": "info",
      "transition": "bounce",
      autoClose: 2000
    })
    return;
  }

  loading.value = true;
  if(window.confirm(`Save Coupon: ${coupon.title}`)) {
    try {
        await addDoc(collection(db, 'Coupons'), coupon).then(() =>  {
            loading.value = false;
            toast("Coupon Saved!", {
                                "type": "success",
                                "transition": "slide",
                                autoClose: 2000
                              })
          });

      } catch (e) {
        loading.value = false;
        alert(e.message)
      }
  } else {
    loading.value = false; //If user cancels 
  }
}

const openMap = (addy) => {
  window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addy)}`);
}

const getExpireTime = (expireTime) => {
    const expireDate = new Date(expireTime);
    const today = new Date();

    if (expireDate < today) {
        return 'Expired';
    }

    const timeLeft = expireDate - today;
    const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

    if (hoursLeft < 24) {
        if (hoursLeft < 1) {
            return `Expires in ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
        } else {
            if (hoursLeft < 2) {
                return `Expires in ${hoursLeft} hour ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            } else {
                return `Expires in ${hoursLeft} hours ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            }
        }
    } else {
        return `Expires: ${expireTime}`;
    }
};

const deletePost = async  (post) => {
  console.log(post)
 const docRef = doc(db, 'Favorites', post)
 await deleteDoc(docRef);
}

const checkExpire = (time, postId) => {
 
  const expireDate = new Date(time);
  const today = new Date();
  if(today > expireDate) {
    let difference = today - expireDate
    const hoursLeft = Math.floor(difference / (1000 * 60 * 60));
   if(hoursLeft > 72) {
    const days = hoursLeft / 24
    if(days > 3) {
      deletePost(postId);
   }
  }
  if(expireDate < today) {
    return true;
  } else return false;
}
}

const removeFavorite = async (post) => {

  Swal.fire({
  text: `Remove ${post.title}?`,
  showCancelButton: true,
  confirmButtonText: "Delete",
  showClass: cssShowClass,
  hideClass: cssHideClass,
}).then(async(result) => {
  if (result.isConfirmed) {
    await deleteDoc(doc(db, 'Favorites', post.id))
        .then(() => {
            const updatedFavorites = favoritePosts.value.filter(item => item.id !== post.id)
            favoritePosts.value = updatedFavorites;
            toast("Successfully Deleted.", {
                "type" : "success",
                "transition": "flip",
                autoClose: 2000
                })
        })
        .catch((e) => {
            toast("Something went wrong, try again.", {
                    "type": "error",
                    "transition": "flip",
                    autoClose: 3000
                    })
        })
  } else if (result.isDenied) {
  }
});
    }

const formattedPhoneNumber = (phone) => {
  const areaCode = phone.substring(0, 3);
  const firstPart = phone.substring(3, 6);
  const secondPart = phone.substring(6, 10);
  return `(${areaCode}) ${firstPart}-${secondPart}`;
};

const getAllPosts = async () => {
  loading.value = true;
  try {
      const emptyArray = []
    const promoQuery = query(collection(db, 'Favorites'),
                                    where('useruid', '==', uid) ,
                                    where('approved', '==', true),
                                    orderBy('business'), 
                                    limit(5));
    const snapShot = await getDocs(promoQuery)
    snapShot.forEach(doc => {
      emptyArray.push({id:doc.id, ...doc.data()})
    })
    if(emptyArray.length > 0) {
      favoritePosts.value = emptyArray
      lastVisible.value = snapShot.docs[snapShot.docs.length - 1];

      if(emptyArray.length < 5) {
        lastVisible.value = null;
      }
    } else {
      toast("No Favorites Found...", {
          "type": "info",
          "transition": "flip",
          autoClose: 3000
        })
    }
    loading.value = false;
} catch(e) {
  loading.value = false;
}
}

const filterBy = async (filterType) => {
  console.log('hello from filterBy function!')
  if(filterType === 'All') {
    userFilter.value = false;
    await getAllPosts();
    return;
  }

  userFilterSelection.value = filterType
  userFilter.value = true;
  loading.value = true;
  
try {
      // postType.value = userPostSelection.value
      const emptyArray = []
    const promoQuery = query(collection(db, 'Favorites'),
                                    where('useruid', '==', uid) ,
                                    where('approved', '==', true),
                                    where('postType', '==', filterType),
                                    orderBy('business'), 
                                    limit(5));
    const snapShot = await getDocs(promoQuery)
    snapShot.forEach(doc => {
      emptyArray.push({id:doc.id, ...doc.data()})
    })
    if(emptyArray.length > 0) {
      favoritePosts.value = emptyArray
      lastVisible.value = snapShot.docs[snapShot.docs.length - 1];

      if(emptyArray.length < 5) {
        lastVisible.value = null;
      }
    } else {
      toast(`No ${filterType}'s found`, {
          "type": "info",
          "transition": "flip",
          autoClose: 2000
        })
    }
    loading.value = false;
} catch(e) {
  loading.value = false;
}
}

const getMoreFavorites = async () => {
  if(!postReady || !lastVisible.value) {
    return;
  }

  loading.value = true;
  if(userFilter.value === true) {
    try {
        const favoritesQuery = query(collection(db, 'Favorites'),
                                        where('useruid', '==', uid) ,
                                        where('approved', '==', true),
                                        where('postType', '==', userFilterSelection.value),
                                        orderBy('business'), 
                                        startAfter(lastVisible.value),
                                        limit(5));
            const querySnapshot = await getDocs(favoritesQuery);
            
            const emptyArray = [];
            querySnapshot.forEach(doc => {
                emptyArray.push({ ...doc.data(), id: doc.id });
            });
            if (emptyArray.length > 0) {
                lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
                favoritePosts.value = [...favoritePosts.value, ...emptyArray]
            } 
            if(emptyArray.length < 5) {
              lastVisible.value = null;
            }
            loading.value = false;
        } catch (error) {
          loading.value = false;

        }
  } else {
    try {

        const favoritesQuery = query(collection(db, 'Favorites'),
                                        where('useruid', '==', uid) ,
                                        where('approved', '==', true),
                                        orderBy('business'), 
                                        startAfter(lastVisible.value),
                                        limit(5));
            const querySnapshot = await getDocs(favoritesQuery);
            
            const emptyArray = [];
            querySnapshot.forEach(doc => {
                emptyArray.push({ ...doc.data(), id: doc.id });
            });
            
            
            if (emptyArray.length > 0) {
                lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
                favoritePosts.value = [...favoritePosts.value, ...emptyArray]
            } 
            
            if(emptyArray.length < 5) {
              lastVisible.value = null;
            }
            loading.value = false;
        } catch (error) {
          loading.value = false;
        }
  }
  
}

const getFavorites = async () => {
    try {
      const favoritesQuery = query(collection(db, 'Favorites'),
                                    where('useruid', '==', uid) ,
                                    where('approved', '==', true),
                                    orderBy('business'), 
                                    limit(5));
      
        const querySnapshot = await getDocs(favoritesQuery);
        
        const emptyArray = [];
        querySnapshot.forEach(doc => {
            emptyArray.push({ ...doc.data(), id: doc.id });
        });
        favoritePosts.value = emptyArray;
        
        if (emptyArray.length > 0) {
            lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        } 
        
        if(emptyArray.length < 5) {
          lastVisible.value = null;
        }
        
        if(emptyArray.length == 0) {
          noPostsFound.value = true;
            toast("No posts found.", {
                "type": "info",
                "transition": "zoom",
                autoClose: 1000
            });
        }
    } catch (e) {
        console.error(e.message);
        // Handle error appropriately
    }
};

const getUserName = async () => {
  const querySnapshot = await getDocs(collection(db, `Users/${uid}/Docs`));
  querySnapshot.forEach((doc) => {
   const data = doc.data();
   
    if(data.userType == 'Individual') {
      let name = data.name + "'s"
      userName.value = name;
      individual.value = true
    } else individual.value = false;
  
 });
}


onMounted(async () => {

  pageview({ page_path: '/Favorites' })

  const dropdown = document.querySelector(".dropdown");
    const select = dropdown.querySelector(".select");
    const caret = dropdown.querySelector(".caret");
    const menu = dropdown.querySelector(".menu");
    const options = dropdown.querySelectorAll(".menu li");
    const selected = dropdown.querySelector(".selected");
    select.addEventListener("click", () => {
        select.classList.toggle("select-clicked");
        caret.classList.toggle("caret-rotate");
        menu.classList.toggle("menu-open")
    })
    options.forEach(option => {
        option.addEventListener("click", () => {
            selected.innerText = option.innerText;
            select.classList.remove("select-clicked");
            caret.classList.remove("caret-rotate");
            menu.classList.remove("menu-open");
            options.forEach(option => {
                option.classList.remove("menuActive")
            })
            option.classList.add("menuActive")
        })
    })

    document.addEventListener("click", (event) => {
    if (!dropdown.contains(event.target)) {
        select.classList.remove("select-clicked");
        caret.classList.remove("caret-rotate");
        menu.classList.remove("menu-open");
    }
});

  const lastDiv = document.querySelectorAll('.lastElement')
    const observer = new IntersectionObserver((element) => {
      element.forEach((item) => {
        if(item.isIntersecting) {
         getMoreFavorites()
        }
      })
    }, {
      rootMargin: '0px 50px 0px 50px',
      threshold: 0
    })
    lastDiv.forEach((element) => observer.observe(element))
    loading.value = true;
    await getUserName()
    await getFavorites()
    .finally(() => {
        loading.value = false;
        postReady.value = true;
    })
    })

</script>

<style scoped>

* {
  color: navy;
}

.companyInfo a {
  text-decoration: none;
}

h1 {
    text-align: left;
    color: navy;
    font-size: 3.5rem;
    font-weight: 900;
}

#loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust the height as needed */
  background-color: white;
  overflow: hidden;
  z-index: 1000; /* Ensure it's above other content */
}

#loader-bar {
  position: absolute;
  top: 0;
  left: -200px; /* Start the bar off-screen */
  width: 200px;
  border-radius: 15px;
  height: 5px; /* Same as loader container height */
  background-color: #11ff09; /* Bar color */
  animation: slide 750ms linear infinite;
}

@keyframes slide {
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
}


.promotionalPosts {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping */
  display: flex;
  flex-direction: row;
  margin: 25px auto;
  scroll-snap-type: x mandatory;
}



.promoPost {
  min-height:550px;
  min-width: 350px;
  max-height: 550px;
  max-width: 350px;
  background-color: rgb(255, 255, 255);
  margin:25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
 scroll-snap-align: center;
 box-shadow: 0 10px 10px rgba(23, 79, 138, 0.2);
 border:1px solid gainsboro;
}

.promotionalPosts .promoPost img {
  height:200px;
  width:100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid gainsboro;
}

.promotionalPosts .promoPost .testDiv {
  position: absolute;
  height:40px;
  width:30px;
  border-radius: 5px;
  bottom: 5px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 400ms ease-in-out;
}



.promotionalPosts .promoPost .testDiv .favIcon {
  color: rgb(226, 226, 226);
  cursor: pointer;
  font-size: 20px;
  margin-right: 4px;
  transition: 400ms ease-in-out;
}

.promotionalPosts .promoPost .testDiv .favIcon:hover {
  color: rgb(199, 43, 43);
  
}



.promotionalPosts .promoPost  #timer {
  padding:5px;
                /* background-color: rgb(50, 112, 228); */
                background:rgb(89, 73, 194); 
                border-radius: 5px;
                min-width: 250px;
                margin: 0px auto;
                color: rgb(65, 65, 65);
                text-align: center;
                position: absolute;
                top:183px;
                left:50%;
                transform: translate(-50%, 0%);
                font-size: 0.8rem;
                font-weight: 500;
                box-shadow:  0 0 2px rgba(0, 0, 0, 0.3);
            
           
}

.promotionalPosts .promoPost .companyInfo {
  margin-top: 20px;
  text-align: left;
  padding:20px;
}



.promotionalPosts .promoPost .promoText {
  margin: 15px auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.promotionalPosts .promoPost .couponButton {
  outline: none;
      color: hsl(162, 54%, 53%);
      background: transparent;
      border: 1px solid hsl(162, 54%, 53%);
      min-width: 200px;
      max-width: 275px;
      padding-inline: 20px;
      padding-block: 7px;
      text-align: center;
      font-weight: 500;
      font-size: 0.8rem;
      border-radius: 2px;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
      cursor: pointer;
      transition: 400ms ease;
}

.promotionalPosts .promoPost .couponButton:hover {
  color: white;
  background: hsl(162, 54%, 53%);
}
      .loaderContainer {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        display: grid;
        place-content: center;
      }

      .custom-loader {
      width:40px;
      height:40px;
      border-radius:50%;
      background:conic-gradient(#0000 10%,#FFFFFF);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 0.5s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}

.opacity {
opacity: 0.5;
filter: brightness(0.5);
}


.headerContainer {
              padding-block: 25px; 
               padding-inline:28px; 
               background:white;
               max-width:1000px;
            }

            .filter {
              display: flex;
              flex-direction: row
            }

            .filter button{
              padding-inline: 30px;
              padding-block: 10px;
              border-radius: 5px;
              background:white;
              outline: 1px solid gainsboro;
              border: none;
              cursor: pointer;
              transition: 200ms ease-in-out;
            }

            .filter button:hover {
              outline: 1px solid navy;
            }

            .headerContainer p {
              color: rgb(32, 32, 32);
            }


            .headerMain {
                padding:20px 20px;
                width: 100%;
                background:white;
            }
            

            @media(max-width:500px) {
              h1 {
                font-size: 1.75rem;
              }
            }

            .chevron {
              margin-inline:5px;
              color: gainsboro;
            }

</style>