
    <template>
      <body>
        <div class="container" :class="{ 'currentlyLoading': loading}" >
          <form @submit.prevent="login" class="registration-form-">
      
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <!-- <h1 style="font-weight: bold; font-size: 2rem; text-shadow: 0 0 5px rgba(0,0,0,0.3); letter-spacing: 1px;">Desoto Deals</h1> -->
          <p style="font-size: 2rem;">Login</p>
          <!-- <div class="googleSignUp" @click="googleSignIn"> <img src="../assets/icons8-google-48.png" height="30" width="30" /></div>
          <p>OR</p> -->
          <p style="padding:25px; font-size: 0.8rem;">Enter your account email and password</p>
          </div>
            <div class="form-group">
              <label for="email">Email</label>
              <div class="inputHolder">
                <font-awesome-icon icon="envelope" class="fa" />
              <input type="email" id="email" v-model="email" required />
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
             <div class="inputHolder">
              <font-awesome-icon icon="lock" class="fa" />
              <input type="password" id="password" v-model="password" required />
             </div>

              <p @click="forgotPassword" class="forgotPasswordText" style="text-align: left; margin-left: 10px; width: 120px; font-size: 12px; margin-top: 10px;">Forgot <span style="font-weight: 600;">Password</span></p>
              <p style="text-align: left; font-weight: 400; margin-left: 10px; font-size: 12px; color: red" v-if="errorMsg"> {{ errorMsg }}</p>
            </div>
            <button type="submit">Login</button>
            <p @click="pushToRegister" style="text-align: center; margin-left: 10px; padding:10px; font-size: 13px;" class="registerText">Not a Member? <span style="color: #2c97df">Register</span></p>
          </form>
          <div class="welcomeBackRobot">
            <h1 style="color: white;">Welcome Back!</h1>
            <Vue3Lottie :animationData="welcomeAnimation" :height="275" :width="275"  />
          </div>
        </div>
        <div v-if="loading" class="loaderIcon">
          <Vue3Lottie :animationData="spinnerAnimation" :height="150" :width="150" />
        </div>
      </body>
      </template>
      
      <script setup>
      import { ref } from 'vue';
      import { collection, getDocs } from "firebase/firestore"; 
      import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'; // Import Firebase authentication method
      import { db } from '../../firebase'
      import { useRouter } from 'vue-router';
      import Swal from 'sweetalert2';
      import spinnerAnimation from '../assets/animations/loading_animation.json';
      import welcomeAnimation from '../assets/animations/lottie_welcome_animation.json';
      import { toast } from 'vue3-toastify';
      import 'vue3-toastify/dist/index.css';
      const router = useRouter();
      const provider = new GoogleAuthProvider();
      
      
      // Define reactive variables for form inputs
      const email = ref('');
      const password = ref('');
      let errorMsg = ref('');
      let loading = ref(false);
      const auth = getAuth();
  

      const googleSignIn = async () => {
        const auth = getAuth();
        signInWithPopup(auth, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log(user)
            // IdP data available using getAdditionalUserInfo(result)
            // ...
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
          });
      }
  
  
  
  
  
      const forgotPassword = async () => {
        Swal.fire({
        title: "Are you sure you want to change your password?",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          sendPasswordResetEmail(auth, email.value)
          .then(() => {
            Swal.fire("An email has been sent to reset your password.", "", "success");
          }).catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          })
         
        } 
  });
      }
  
      const pushToRegister = () => {
        router.push('/userRegister')
      }
  
  
      const readDocument = async (userId) => {
      const querySnapshot = await getDocs(collection(db, `Users/${userId}/Docs`));
       querySnapshot.forEach((doc) => {
        const data = doc.data();
        let typeUser = data.userType
        if(typeUser == 'Company') {
          router.push('/dashboard')
        } else {
          router.push('/')
        }
      });
    }
  
      
      // Function to handle registration
      const login = async () => {
        loading.value = true;
        try {
          await signInWithEmailAndPassword(auth, email.value, password.value)
          .then(async(userCredential) => {
            loading.value = false;
            var user = userCredential.user;
            if(user) {
              await readDocument(user.uid)
            // router.push('/dashboard');
            } else {
              
            }
          })
        } catch (error) {
          loading.value = false;
          errorMsg.value = "Email or Password is incorrect"
        }
      };
      </script>
      
      <style scoped>
      body {
        margin: 0px;
        padding: 0px;
        background: #f7f7f7;

      }
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 100vh;
      }
  
      h1 {
        font-weight: 500;
        text-align: center;
        padding: 50px;
      }
      
      .registration-form- {
        background-color: ghostwhite;
        padding: 20px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        width:500px;
        max-width: 500px;
        animation-name: fade-in;
        animation-duration: 2s;
      height: 500px;
      max-height: 500px;
  
      }

      .welcomeBackRobot {
        background: linear-gradient(to bottom right, #1670e6, rgb(184, 85, 241));
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        width:375px;
        max-width: 375px;
        animation-name: fade-in;
        animation-duration: 2s;
      height: 500px;
      max-height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      animation: slide-in-right 1s forwards;
      }

      @keyframes slide-in-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  /* Define animation keyframes for sliding in from the right */
  @keyframes slide-in-right {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
      .currentlyLoading {
        opacity: 0.5;
        filter: brightness(0.5);
      }
  
      @keyframes fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      
      .form-group {
        margin-bottom: 20px;
        position: relative;
      }
  
      .form-group .padlock {
      position: absolute;
      right:20px;
      top:53%;
      transform: translateY(-50%);
      }
  
      .form-group .email {
        position: absolute;
      right:16px;
      top:72%;
      transform: translateY(-50%);
      }
      
      label {
        display: block;
        text-align: left;
        padding-bottom: 15px;
        margin-left: 5px;
        color: rgb(37, 37, 37);
      }
      
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        width: 90%;
        padding: 15px;
        border:none;
        outline: none;
        border-bottom: 2px solid #d4d4d4;
        box-sizing: border-box;
      }

  
      
      
      button {
        background-color: #2c97df;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
        font-size: 13px;
        transition: 0.5s ease-in-out;
        text-transform: uppercase;
        box-shadow: 0px 2px rgba(0, 0, 0, 0.6);
  transition: 500ms ease;
      }

      button:hover {
        box-shadow:  0px 0px;
        transform: scale(0.95);
      }
      
     
  
      .registerText:hover {
        cursor: pointer;
      }
  
      .forgotPasswordText:hover {
        cursor: pointer;
      }
  
      .loaderIcon {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
      }

      .inputHolder {
        display: flex;
        flex-direction: row;
      }

      .container .form-group .inputHolder .fa {
        color: white;
        padding:16px;
        background-color: #d4d4d4;
      }

      .googleSignUp {
    width:100%;
    padding:10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px auto;
    cursor: pointer;
    font-weight: 500;

  }
  
      @media(max-width: 700px) {
        .registration-form- {
          max-width: 400px;
        }

        .welcomeBackRobot {
          display: none;
        }
      }

      @media(max-width:400px) {
        .registration-form- {
          width: 330px;
          max-width: 330px;
        }
      }
      </style>
      