<template>
     <body>
      <div class="header " style="z-index: 100;">
      <div>
        <h1 class="mainTitle">
          Desoto 
      </h1>
      <h1 class="mainTitle">Deals</h1>
        <h2 class="headerDescription" style="color:navy;"><br>  Find discounts on restaurants, entertainment, <br> shopping, and more. Get the best Deals, <br> Coupons & Promotions from your favorite places!</h2>
    <a href="#promotions" id="goToPromoBtn" style="text-decoration: none;"><button  class="downloadButton">Get Started<font-awesome-icon icon="arrow-right" style="margin-left: 10px;" class="fa" /></button></a>
    
      </div>
    <div id="lottiePig">
      <Vue3Lottie :animationData="lottieAnimation" id="lottie-pig-anim" :loop="false" />
    </div>
     </div>


    
   
 
 
  <div class="explore" style=" width: 100%; height:150px; padding:25px 25px;">
      <h2 style="letter-spacing: 1px; color: navy;">
    Explore Local <span style="color:hsl(162, 54%, 53%); font-weight:900;" class="typed-text"></span><span class="cursor">&nbsp;</span> Find Discounts Near You <font-awesome-icon icon="location-dot" class="fa" style="margin-left:10px; font-size: 30px; color:red" /></h2>
    </div>
  <GoogleMap
        :api-key="apiKey"
        style="width: 100%; height: 75vh;"
        :center="{lat: 34.96, lng: -89.89}"
        :zoom="12"
        >
          <Marker
            v-for="(marker, i) in markers"
            :key="i"
            :options="{ position: {lat:marker.lat, lng: marker.lng}}"
          >
          <InfoWindow
          style="padding:15px;"
             :options="{ position: {lat: marker.lat, lng: marker.lng} }">
             <!-- <p class="marker-title">{{ marker.postType }}</p> -->
              <p style="font-weight: bold;">{{ marker.business }} </p>
              <p style="margin-top: 15px; font-style: italic; font-size: 1rem;">{{ marker.title }}</p>
              <p style="margin-top: 15px; line-height: 1.6; font-size: 0.8rem;">{{ marker.description.length > 100 ? marker.description.substring(0, 100) + '...' : marker.description }}</p>
              <p @click="openMap(marker.address)" style="margin-top: 15px; cursor: pointer;">{{ marker.address }} <span  style="margin-left:10px;"><font-awesome-icon icon="diamond-turn-right" class="fa" style="color:goldenrod; font-size: 1rem;" /></span></p>
              <button @click="searchCo(marker.business)" class="seeMoreBtn">See More</button>
              </InfoWindow>
        </Marker>
        </GoogleMap>


        <div class="mainPromoDiv">
          <h1 v-show="postType == 'Promotion' " style="margin: 25px; margin-top: 25px; ">Hot {{ postType + 's'}}<font-awesome-icon icon="fire" class="fa" style="color: #ff4500; font-size: 25px; margin-bottom: 20px;" /></h1>
        <h1 v-show="postType == 'Deal' " style=" margin: 25px; margin-top: 25px;  ">Amazing {{ postType + 's'}} <img src="../assets/icons8-smiling-face-with-sunglasses-94.png" height="40" width="40" alt="smile"></h1>
        <h1 v-show="postType == 'Coupon' " style=" margin: 25px; margin-top: 25px;  ">Awesome {{ postType + 's' }}<img src="../assets/icons8-hushed-face-48.png" height="40" width="40" alt="emoji"></h1>
        <h1 v-show="postType == 'All' " style=" margin: 25px; margin-top: 25px;  ">Dont Miss Out 🚀</h1>


        <div class="dropdown">
                <div class="select">
                    <span class="selected">Filter By</span>
                    <div class="caret"></div>
                </div>
                <ul class="menu">
                   <li v-for="option in options" :key="option" @click="handlePostType(option)">
                    {{ option  }}
                   </li>
                </ul>
            </div>
        
        
        <div class="promotionalPosts" id="promotions">
        
          <div  class="promoPost" v-for="post, index in companyPosts" :key="post.id">
            <img :src="post.imageUrl" alt="company Image">
            <div id="timer" :title="post.expireTime" :style="{ backgroundColor : checkExpire(post.expireTime, post.id) ? 'rgb(212, 62, 62)' : 'gainsboro'}" >
            <font-awesome-icon  icon="clock" class="fa" style="margin-right:5px;" />{{ getExpireTime(post.expireTime) }}
            </div>  
          


            <div class="companyInfo">
              <h4 >{{ post.business }}</h4>
              <p class="address" @click="openMap(post.address)" style="font-size: 12px; margin-bottom: 5px; margin-top: 5px; cursor: pointer;"><font-awesome-icon icon="location-pin" class="fa" style="margin-right:10px; color: gainsboro;" />{{ post.address }}</p>
              <a :href="'tel:' +1+post.phone"><p class="phoneNumber" style="font-size: 12px; margin-bottom:5px;"><font-awesome-icon icon="phone" class="fa" style="margin-right:10px; color: gainsboro;" />{{ formattedPhoneNumber(post.phone) }}</p>
              </a>
              <a v-show="post.website !== ''" :href="post.website" target="_blank"><p class="phoneNumber" style="font-size: 12px;"><font-awesome-icon icon="globe" class="fa" style="margin-right:10px; color: gainsboro;" />{{ post.website.substring(0,45) }}</p>
              </a>
            </div>
            <hr style="margin: 0 auto;">

    
            <div style="white-space: wrap;">
              <h3 style="text-align: center; margin-top: 5px;">{{ post.title }}</h3>
            <p style="font-size: 12px; padding:10px; text-align: center;">{{ post.description }}</p>
            </div>
            <p v-show="post.promoCode && post.postType == 'Promotion'" class="couponButton"><span style="font-weight: 700; margin-right: 10px;">Promo Code:</span>{{ post.promoCode }}</p>
            <!-- <p v-if="post.postType == 'Deal'" class="couponButton">Deal</p> -->

            <p v-show="post.postType == 'Coupon'" class="couponButton" @click="useCoupon(post)">Save Coupon</p>

            <div v-show="post.postType !== 'Coupon'" class="testDiv" @click="addToFavorites(post)" title="Add to Favorites!">
              <font-awesome-icon icon="heart" class="fa favIcon"  />
            </div>
          </div>
          <div class="lastElement">
           <p style="font-weight: bold; font-size: 1.5rem; margin:100px auto;"></p>
          </div>

         

     </div>
  
        </div>

        

   <div class="wrapperElement">
    <div class="catHeadings">
      <h1>Popular</h1>
      <h3>Categories</h3>
    </div>

    <div class="categoryContainer">
        <div @click="checkCategory('Health & Fitness')">
          <p>Health & Fitness</p>
          <font-awesome-icon icon="person-walking" class="fa catIcon" />
          <p style="margin-top: 15px;">Gyms, personal trainers, fitness classes, wellness centers, nutritionists, and any services or products related to physical health and fitness.</p>
        </div>
        <div @click="checkCategory('Food & Beverage')">
          <p>Food & Beverage</p>
          <font-awesome-icon icon="utensils" class="fa catIcon" />
          <p style="margin-top: 15px;">Restaurants, cafes, bars, food trucks, catering services, and any establishments or services offering food and drinks.</p>
        </div >
        <div @click="checkCategory('Sports')">
          <p>Sports</p>
          <font-awesome-icon icon="football" class="fa catIcon" />
          <p style="margin-top: 15px;">Sports leagues, sporting goods stores, training facilities, sports events, and any other sports-related services or products.</p>
        </div>
        <div @click="checkCategory('Home Services')">
          <p>Home Services</p>
          <font-awesome-icon icon="house" class="fa catIcon" />
          <p style="margin-top: 15px;">Includes home repair, maintenance, cleaning services, landscaping, pest control, and other services for home improvement and upkeep.</p>
        </div>
        <div @click="checkCategory('Photo & Video')">
          <p>Photo & Video</p>
          <font-awesome-icon icon="camera" class="fa catIcon" />
          <p style="margin-top: 15px;">Photography studios, videographers, photo and video editing services, and other related businesses.</p>
        </div>
        <div @click="checkCategory('Vehicle Services')">
          <p>Vehicle Services</p>
          <font-awesome-icon icon="car" class="fa catIcon" />
          <p style="margin-top: 15px;">Car repair, maintenance, detailing, car wash services, and any other businesses related to vehicles.</p>
        </div>
      </div>
   </div>







    
   
 

    <div class="appDownloadDiv" >

     

     <div>
      <p style=" color: navy;">Enter your <font-awesome-icon icon="envelope" style="padding-inline: 5px;" class="fa" /> to get notified when the App is available.
      It's completely free to use!</p>
     
     <form @submit.prevent="addUserEmail">
      <!-- <input type="email" v-model="userEmail" placeholder="your@email.com" required> -->
      <div class="form-group">
            <input type="email" placeholder=" " v-model="userEmail" id="inputField" required name="Email">
            <label for="inputField">Email Address</label>
          </div>
      <button :disabled="loading ? true : false"><span v-if="!loading">Notify Me</span>  <font-awesome-icon v-if="!loading" icon="paper-plane" style="margin-left: 10px;" class="fa" />
        <div v-if="loading" class="custom-loader"></div>
      </button>
     </form>
     </div>

     <img class="imgIcons" src="../assets/Download_on_the_App_Store_Badge.svg" alt="img" @click="appComingSoon">

    </div>

 


  

  
   

  
    <!-- <div class="loaderContainer" v-show="pageLoading">
      <div class="custom-loader"></div>
    </div> -->

      <!-- <div class="blacked-out"  v-show="loadingFilter || pageLoading"></div> -->
    <div id="loader-container" v-show="loadingFilter || pageLoading">
    <div id="loader-bar"></div>
  </div>
 
   
   
     </body>
  
</template>



<script setup>

import lottieAnimation from '../assets/animations/piggy_bank_animatin.json';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { db } from '../../firebase';
import { collection, getDocs, query, where, addDoc, orderBy, limit, startAfter, deleteDoc, doc, setDoc, increment, updateDoc, QuerySnapshot } from 'firebase/firestore';
import { pageview } from 'vue-gtag'



const router = useRouter();

import { GoogleMap, Marker, InfoWindow, MarkerCluster } from 'vue3-google-map'
import { getAuth } from 'firebase/auth';
import { toast } from 'vue3-toastify';
import Swal from 'sweetalert2';
import 'vue3-toastify/dist/index.css';

const markers = ref([]);
const companyPosts = ref([]);
const userPostSelection = ref('')
const postTitle = ref('')
const userEmail = ref('')
const postType = ref('All');
const companyList = ref([]);
const lastVisible = ref(null);
const apiKey = ref(process.env.VUE_APP_GOOGLE_MAP_API_KEY);
const auth = getAuth()
const loading = ref(false);
const pageLoading = ref(false);
const loadingFilter = ref(false);
const categoryArray = ref([]);
const options = ref(['All', 'Promotion', 'Deal', 'Coupon']);



const searchCo = (company) => {
  router.push({name:'CompanySearch', params: {companyName: company}})
}


const useCoupon = async (coupon) => {
  if (!auth.currentUser) {
    toast("Login to save coupons.", {
      type: "info",
      transition: "flip",
      autoClose: 1000
    });
    return false;
  }

  const uid = auth.currentUser.uid;
  const usedQuery = query(
    collection(db, 'Coupons'),
    where('postId', '==', coupon.id),
    where('useruid', '==', uid),
    where('hidden', '==', true)
  );
  const querySnapshotOne = await getDocs(usedQuery);

  if (!querySnapshotOne.empty) {
    toast("Coupon already used.", {
      type: "info",
      transition: "flip",
      autoClose: 1000
    });
    return;
  }

  const q = query(
    collection(db, 'Coupons'),
    where('postId', '==', coupon.id),
    where('useruid', '==', uid)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    toast("You already have this coupon.", {
      type: "info",
      transition: "flip",
      autoClose: 1000
    });
    return;
  }


  Swal.fire({
    text: `Save Coupon: ${coupon.title}?`,
    showDenyButton: true,
    confirmButtonText: "Save",
    denyButtonText: `Cancel`,
    showClass: {
    popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `
  },
  hideClass: {
    popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `
  },
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await queryAndUpdateFavDoc(coupon.id)
        await addDoc(collection(db, 'Coupons'), {
          approved: true,
          postId: coupon.id,
          useruid: uid,
          uid: coupon.uid,
          business: coupon.business,
          address: coupon.address,
          phone: coupon.phone,
          website: coupon.website,
          postType: coupon.postType,
          title: coupon.title,
          hidden: false,
          description: coupon.description,
          imageUrl: coupon.imageUrl,
          expiration: coupon.expireTime,
          couponRedemptionTime: coupon.couponRedemption,
          couponRedeemed: false,
          couponRedemptionDate: null,
          couponRedemptionExpired: false,
          category: coupon.category
        }).then(() => {
          toast("Coupon Saved!", {
            type: "success",
            transition: "flip",
            autoClose: 1000
          });
        });

      } catch (e) {
        alert(e.message);
      }
    } else if (result.isDenied) {
    }
  });
}



const addUserEmail = async () => {

  if(userEmail.value != null) {
    loading.value = true;
     try {
      const q = query(collection(db, `EmailList`), where('email', '==', userEmail.value.toLowerCase()));
      const querySnapshot = await getDocs(q);
      if(!querySnapshot.empty) {
        toast('Email already added!', {
          type: 'info',
          transition: 'flip',
          autoClose: 2000
        })
        loading.value = false
        return false;
      }
      await addDoc(collection(db, `EmailList`), {
          email: userEmail.value.toLocaleLowerCase()
          }).then(() =>   toast("Email added to list!", {
                                "type": "success",
                                "transition": "flip",
                                autoClose: 1000
                              }));
                                userEmail.value = ''
                                loading.value = false;
     } catch (error) {
      toast("Something went wrong, try again.", {
                                "type": "error",
                                "transition": "flip",
                                autoClose: 1000
                              })
                              loading.value = false;
     }
  }
}

const getExpireTime = (expireTime) => {
    const expireDate = new Date(expireTime);
    const today = new Date();

    if (expireDate < today) {
        return 'Expired';
    }

    const timeLeft = expireDate - today;
    const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

    if (hoursLeft < 24) {
        if (hoursLeft < 1) {
            return `Expires in ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
        } else {
            if (hoursLeft < 2) {
                return `Expires in ${hoursLeft} hour ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            } else {
                return `Expires in ${hoursLeft} hours ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            }
        }
    } else {
        return `Expires: ${expireTime}`;
    }
};

const deletePost = async  (post) => {
 const docRef = doc(db, 'CompanyPosts', post)
 await deleteDoc(docRef);
}

const checkExpire = (time, postId) => {
 
  const expireDate = new Date(time);
  const today = new Date();
  if(today > expireDate) {
    let difference = today - expireDate
    const hoursLeft = Math.floor(difference/ (1000 * 60 * 60));
   if(hoursLeft > 72) {
    const days = hoursLeft / 24
    if(days > 3) {
      deletePost(postId);
   }
  }
  if(expireDate < today) {
    return true;
  } else return false;
}
}

const getAllPosts = async () => {
  loadingFilter.value = true;
  try {
    const tempPosts = [];
    const uniquePosts = new Map(); // Map to store unique posts by company

    const promoQuery = query(
      collection(db, 'CompanyPosts'), 
      where('approved', '==', true),
      orderBy('business'),
    );

    const snapShot = await getDocs(promoQuery);
    snapShot.forEach(doc => {
      const data = doc.data();
      if (!uniquePosts.has(data.business)) {
        uniquePosts.set(data.business, { id: doc.id, ...data });
      }
    });

    uniquePosts.forEach(post => {
      tempPosts.push(post);
    });

    if(tempPosts.length > 0) {
      const shuffledArray = shuffleArray(tempPosts)
      companyPosts.value = shuffledArray
    } else {
      toast("No Deals Found...", {
        "type": "info",
        "transition": "flip",
        autoClose: 3000
      });
    }
    loadingFilter.value = false;
  } catch(e) {
    loadingFilter.value = false;
    console.error(e);
  }
}



const handlePostType = async (filterType) => {
  // if(postType.value == filterType) {
  //   return;
  // }

  if(filterType === 'All') {
    
    userPostSelection.value = filterType
    await getAllPosts();
    return;
  }
  
  loadingFilter.value = true;
try {
      // postType.value = userPostSelection.value
      const tempPosts = [];
    const uniquePosts = new Map(); // Map to store unique posts by company

    const promoQuery = query(
      collection(db, 'CompanyPosts'), 
      where('approved', '==', true),
      where('postType', '==', filterType),
      orderBy('business'),
    );

    const snapShot = await getDocs(promoQuery);
    snapShot.forEach(doc => {
      const data = doc.data();
      if (!uniquePosts.has(data.business)) {
        uniquePosts.set(data.business, { id: doc.id, ...data });
      }
    });

    uniquePosts.forEach(post => {
      tempPosts.push(post);
    });

    if(tempPosts.length > 0) {
      const shuffledArray = shuffleArray(tempPosts)
      companyPosts.value = shuffledArray
    } else {
      toast(`No ${filterType}'s found.`, {
        "type": "info",
        "transition": "flip",
        autoClose: 3000
      });
    }
    loadingFilter.value = false;
  } catch(e) {
    loadingFilter.value = false;
    console.error(e);
  }
}

async function queryAndUpdateFavDoc(id) {
    try {
      // Define the query
      const q = query(collection(db, 'FavClicks'), where('postId', '==', id));

      // Execute the query
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (documentSnapshot) => {
          const docRef = doc(db, 'FavClicks', documentSnapshot.id);
          await updateDoc(docRef, {
           clicks: increment(1)
          });
        });
      } else {
      }
    } catch (error) {
    }
  }



const addToFavorites = async (post) => {

  if(!auth.currentUser) {
    toast("Login to save favorites!", {
      "type": "info",
      "transition": "flip",
      autoClose: 1000
    })
    return false;
  }

  // const favoritesRef = collection(db, `Favorites/${uid}/Docs`);
  // const querySnapshot = await getDocs(query(favoritesRef, where('postId', '==', post.id)));
  const uid = auth.currentUser.uid;
  const q = query(collection(db, `Favorites`), where('postId', '==', post.id),where('useruid', '==', uid));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // Post already exists in favorites
    toast("This is already in your favorites!", {
      "type": "info",
      "transition": "flip",
      autoClose: 1000
    })
    return;
  }
 
    Swal.fire({
    text: `Save ${post.title}?`,
    showDenyButton: true,
    confirmButtonText: "Save",
    showClass: {
    popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `
  },
  hideClass: {
    popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `
  },
  }).then(async(result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
          
    try {
        postTitle.value = post.title;
        await queryAndUpdateFavDoc(post.id)
        const couponFav = {
            approved: true,
            postId: post.id,
            useruid: uid,
            uid: post.uid,
            business: post.business,
            address: post.address,
            phone: post.phone,
            website: post.website,
            postType: post.postType,
            title: post.title,
            hidden:false,
            description: post.description,
            imageUrl: post.imageUrl,
            expiration: post.expireTime,
            couponRedemptionTime: post.couponRedemption,
            couponRedeemed: false,
            couponRedemptionDate: null,
            couponRedemptionExpired: false,
            category: post.category
        }
        const regFav = {
            approved:true,
            postId: post.id,
            uid: post.uid,
            useruid: uid,
            business: post.business,
            address: post.address,
            phone: post.phone,
            website: post.website,
            postType: post.postType,
            title: post.title,
            description: post.description,
            imageUrl: post.imageUrl,
            expiration: post.expireTime,
            promoCode: post.promoCode ? post.promoCode : null,
            category: post.category
        }
        await addDoc(collection(db, `Favorites`), post.postType === 'Coupon' ? couponFav : regFav).then(() => {
       
                              toast(`Saved!`, {
                                "type": "success",
                                "transition": "flip",
                                autoClose: 1000
                              })
          });

      } catch (e) {
   
        alert(e.message)
      }
    } else if (result.isDenied) {
    
    } else {
      
    }
  });

}




function appComingSoon () {
  toast("Coming Soon!", {
          "type": "success",
          "transition": "slide",
          autoClose: 1000
                              })
}

const openMap = (addy) => {
  window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addy)}`);
}

const formattedPhoneNumber = (phone) => {
  const areaCode = phone.substring(0, 3);
  const firstPart = phone.substring(3, 6);
  const secondPart = phone.substring(6, 10);
  return `(${areaCode}) ${firstPart}-${secondPart}`;
};



const loadMorePosts = async () => {
  if(!lastVisible.value) {
    return false;
  }

  let promoQuery;

  try {
    if(postType.value == 'All') {
      promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    orderBy('business'),
    startAfter(lastVisible.value),
    limit(5)
);
    } else {
      promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    where('postType', '==', postType.value),
    orderBy('business'),
    startAfter(lastVisible.value),
    limit(5)
);
    }

const querySnapshot = await getDocs(promoQuery);
const companyArray = []
querySnapshot.forEach(doc => {
  companyArray.push({id: doc.id, ...doc.data()})
})

if(companyArray.length > 0) {
  lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1]
}

if(companyArray.length < 5) {
  lastVisible.value = null;
}

companyPosts.value = [...companyPosts.value, ...companyArray];


  } catch (e) {

  }
}

const checkCategory = (item) => {
    let selectedCategory = item;
    const existingCategories = categoryArray.value.filter(category => category == selectedCategory)
    if(existingCategories.length > 0) {
      router.push({name:'categorySearch', params: {categoryName: selectedCategory}})
    }
    else {
      toast("No posts found.", {
            "type": "info",
            "transition": "flip",
            autoClose: 1000
          })
    }
  }



  function shuffleArray(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}



const getMarkers = async () => {
  pageLoading.value = true;
  const tempPosts = [];
  const companyArray = []
  const markerArray = []
  const emptyCatArray = []
  const uniquePosts = new Map();


  const promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    orderBy('business'),
);



  const markerQuery = query(
      collection(db, 'CompanyPosts'), 
      where('approved', '==', true)
  );

  const markerSnapShot = await getDocs(markerQuery)
  markerSnapShot.forEach(doc => {
    markerArray.push({id: doc.id, ...doc.data()})
    companyArray.push(doc.data().business)
    emptyCatArray.push(doc.data().category)
  })
  markerArray.forEach(element => {
    markers.value.push({
          lat: element.coordinates.lat,
          lng: element.coordinates.lng,
          business: element.business,
          title: element.title,
          description: element.description,
          address: element.address,
          image: element.imageUrl,
          postType: element.postType
        })
  })

  const uniqueCompanyList = [...new Set(companyArray)];
  const uniqueCategoryList = [...new Set(emptyCatArray)]
  companyList.value = uniqueCompanyList
  categoryArray.value = uniqueCategoryList;

// categoryArray.value.map(item => {
//   console.log(item)
// })

   
   
    // const queryAll = query(collection(db, 'CompanyPosts'), where('approved', '==', true));

  //   const querySnapshot = await getDocs(promoQuery);
  //   querySnapshot.forEach(doc => {
  //     tempPosts.push({ id: doc.id, ...doc.data() })
  //   });


  //     if(tempPosts.length > 0) {
  //    lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
  //  }

  //  if(tempPosts.length < 5) {
  //    lastVisible.value = null;
  //  }

  const querySnapshot = await getDocs(promoQuery);
    querySnapshot.forEach(doc => {
      const data = doc.data();
      if (!uniquePosts.has(data.business)) {
        uniquePosts.set(data.business, { id: doc.id, ...data });
      }
    });

    uniquePosts.forEach(post => {
      tempPosts.push(post);
    });
      

    const shuffledArray = shuffleArray(tempPosts)

    companyPosts.value = shuffledArray
    pageLoading.value = false;
    
 
  }



onMounted(async () => {

  window.scrollTo(0,0);

  pageview({ page_path: '/' })

  const typedTextSpan = document.querySelector(".typed-text");
const cursorSpan = document.querySelector(".cursor");

const textArray = ['Deals', 'Promotions', 'Events', 'Coupons'];
const typingDelay = 200;
const erasingDelay = 100;
const newTextDelay = 2000; // Delay between current and next text
let textArrayIndex = 0;
let charIndex = 0;

function type() {
  if (charIndex < textArray[textArrayIndex].length) {
    if(!cursorSpan.classList.contains("typing")) cursorSpan.classList.add("typing");
    typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
    charIndex++;
    setTimeout(type, typingDelay);
  } 
  else {
    cursorSpan.classList.remove("typing");
  	setTimeout(erase, newTextDelay);
  }
}

function erase() {
	if (charIndex > 0) {
    if(!cursorSpan.classList.contains("typing")) cursorSpan.classList.add("typing");
    typedTextSpan.textContent = textArray[textArrayIndex].substring(0, charIndex-1);
    charIndex--;
    setTimeout(erase, erasingDelay);
  } 
  else {
    cursorSpan.classList.remove("typing");
    textArrayIndex++;
    if(textArrayIndex>=textArray.length) textArrayIndex=0;
    setTimeout(type, typingDelay + 1100);
  }
}

if(textArray.length) setTimeout(type, newTextDelay + 250);

  const dropdown = document.querySelector(".dropdown");
    const select = dropdown.querySelector(".select");
    const caret = dropdown.querySelector(".caret");
    const menu = dropdown.querySelector(".menu");
    const options = dropdown.querySelectorAll(".menu li");
    const selected = dropdown.querySelector(".selected");
    select.addEventListener("click", () => {
        select.classList.toggle("select-clicked");
        caret.classList.toggle("caret-rotate");
        menu.classList.toggle("menu-open")
    })
    options.forEach(option => {
        option.addEventListener("click", () => {
            selected.innerText = option.innerText;
            select.classList.remove("select-clicked");
            caret.classList.remove("caret-rotate");
            menu.classList.remove("menu-open");
            options.forEach(option => {
                option.classList.remove("menuActive")
            })
            option.classList.add("menuActive")
        })
    })

    document.addEventListener("click", (event) => {
    if (!dropdown.contains(event.target)) {
        select.classList.remove("select-clicked");
        caret.classList.remove("caret-rotate");
        menu.classList.remove("menu-open");
    }
});

  const btn = document.getElementById('goToPromoBtn');
  const promos = document.getElementById('promotions')
  btn.addEventListener('click', (e) => {
     smoothScroll(e)

  })

  function smoothScroll(event) {
  event.preventDefault();
  const targetId = event.currentTarget.getAttribute("href");
  const targetPosition = document.querySelector(targetId).offsetTop + 150;
  const startPosition = window.scrollY
  const distance = targetPosition - startPosition;
  const duration = 1500;
  let start = null;
  
  window.requestAnimationFrame(step);

  function step(timestamp) {
    if (!start) start = timestamp;
    const progress = timestamp - start;
    // window.scrollTo(0, distance*(progress/duration) + startPosition);
    window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
    if (progress < duration) window.requestAnimationFrame(step);
  }
}

// Easing Functions

function linear(t, b, c, d) {
  return c*t/d + b;
};

function easeInOutQuad(t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

function easeInOutCubic(t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t*t + b;
  t -= 2;
  return c/2*(t*t*t + 2) + b;
};

  await getMarkers();

// const lastDiv = document.querySelectorAll('.lastElement')
//     const observer = new IntersectionObserver((element) => {
//       element.forEach((item) => {
//         if(item.isIntersecting) {
//           loadMorePosts()
//         }
//       })
//     }, {
//       rootMargin: '0px 50px 0px 50px',
//       threshold: 0
//     })

// lastDiv.forEach((element) => observer.observe(element))



  // if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       position => {
  //         center.value = { lat: position.coords.latitude, lng: position.coords.longitude}
  //       },
  //       error => {
  //       console.log(`Error getting location: ${error.message}`)
  //       center.value = { lat: 34.961230, lng: -89.897300}
  //       }
  //     );
  //   }
  }
)

</script>

<style scoped>

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1, h4, h3, p, a {
    color: navy;
  }

 

  body {
    scroll-behavior:smooth;
    background:ghostwhite;
  }

body, html {
  overflow-x: hidden;
}

.blacked-out {
  position:fixed;
  inset:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.5);
}

#loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust the height as needed */
  background-color: white;
  overflow: hidden;
  z-index: 1000; /* Ensure it's above other content */
}

#loader-bar {
  position: absolute;
  top: 0;
  left: -200px; /* Start the bar off-screen */
  width: 200px;
  border-radius: 15px;
  height: 5px; /* Same as loader container height */
  background-color: #11ff09; /* Bar color */
  animation: slide 750ms linear infinite;
}

@keyframes slide {
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
}

  .marker-title {
    position: absolute;
    top:15px;
    left:50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
  .mainTitle:first-child{
    color:navy;
    font-weight: 800;
    font-size: 6.5rem;
  }

  .mainTitle:nth-child(2) {
    margin-top: -70px;
    color:hsl(162, 54%, 53%);
    font-weight: 400;
    font-size: 4.5rem;
    margin-left: 100px;
  }

  .markerBtn {
    padding:5px 15px;
    outline: none;
    border: none;
    margin-block: 15px;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.4);
  }
  .dealSpan {
   font-weight: 900;
  }

  .loaderContainer {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        display: grid;
        place-content: center;
      }

      .custom-loader {
      width:40px;
      height:40px;
      border-radius:50%;
      background:conic-gradient(#0000 10%,#FFFFFF);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 0.5s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}

 .promotionalPosts .loaderIcon {

  position:absolute;
  z-index: 10;
  inset:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
}

.lottieLoader {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  filter: brightness(1.5);
}

  .testing {
    border:1px solid rgb(255, 255, 255);
    padding: 20px;
    width: 150px;
    margin-left: 25px;
    margin-top: -10px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
  }

  .testing p {
    color: white;
    font-weight: 600;
    font-style: italic;
  }

  .postModal {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 300px;
    background-color: ghostwhite;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .postModal p {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .postModal .closeBtn {
    position: absolute;
    top:10px;
    right:10px;
    background-color: gainsboro;
    padding:25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }


  section {
  padding: 12px 12px;
  background: #f3f3f3;
  color:gainsboro;
  cursor: pointer;
  margin:5px;
  border-radius: 20px;
  transition:200ms ease-in-out;

}

section:hover {
  color:white;
  background: linear-gradient(to bottom right, #1670e6, rgb(184, 85, 241));
  box-shadow: 0 0 3px rgba(0,0,0,0.8);
}


.glitch-wrapper {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   background-color: transparent;
}

.glitch {
   position: relative;
   font-size: 49px;
   font-weight: bold;
   color: #FFFFFF;
   letter-spacing: 3px;
   z-index: 1;
}

.glitch:before,
.glitch:after {
   display: block;
   content: attr(data-text);
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0.8;
}

.glitch:before {
   animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
   color: #00FFFF;
   z-index: -1;
}

.glitch:after {
   animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
   color: #FF00FF;
   z-index: -2;
}

@keyframes glitch-it {
   0% {
      transform: translate(0);
   }
   20% {
      transform: translate(-2px, 2px);
   }
   40% {
      transform: translate(-2px, -2px);
   }
   60% {
      transform: translate(2px, 2px);
   }
   80% {
      transform: translate(2px, -2px);
   }
   to {
      transform: translate(0);
   }
}



  .advertise {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* clip-path: polygon(0 0, 100% 0, 100% calc(100% - 15vw), 0% 100%); */
    background-color: ghostwhite;
    gap:25px;
    padding-bottom: 75px;
  }

  .advertise div {
    padding: 20px;
  }

  .advertise div:nth-child(1) {
    border-right: 1px solid black;
  }

  .advertise div:nth-child(2) {
    border-right: 1px solid black;
  }



  .advertise h2 {
    color: rgb(43, 43, 43);
    font-size: 1.8em;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
    text-transform: none;
  }

 

  .advertise p {
    line-height: 1.6;
    font-size: 14px;
    font-weight: 400;
    margin-top: 30px;
  }

  .downloadButton {
    background-color: hsl(0, 0%, 100%);
    padding: 18px;
    outline: none;
    border:1px solid hsl(162, 54%, 53%);
    border-radius: 5px;
    color: hsl(162, 54%, 53%);
    font-weight: 700;
    margin-top: 40px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    transition: 300ms ease;
    box-shadow: 0 10px 10px rgba(23, 79, 138, 0.3);

  }

  .downloadButton:hover {
    color: hsl(0, 0%, 100%);
    background-color: hsl(162, 54%, 53%);
  }

  .seeMoreBtn {
    background:hsl(162, 54%, 53%);
    padding: 18px;
    outline: none;
    border:1px solid hsl(162, 54%, 53%);
    color: white;
    font-weight: 700;
    margin-top: 40px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    transition: 300ms ease;
    box-shadow: 0 10px 10px rgba(23, 79, 138, 0.3);
    border-radius: 5px;
  }

  .seeMoreBtn:hover {
    background:white;
    color: hsl(162, 54%, 53%);
  }

.signUpButton {
  background-color: #ffffff;
  border: 2px solid #ffffff;
    padding: 15px;
    outline: none;
    border-radius: 5px;
    color: #000000;
    font-weight: 700;
    margin-top: 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 200px;
    cursor: pointer;
    transition: 300ms ease;
}

.signUpButton:hover {
  color: #ffffff;
  background-color: rgb(0, 0, 0);
}


  /* hr {
    margin-top: 15px;
    width: 75%;
    margin-left: auto;
  } */

  .imgIcons {
    margin-top: 50px;
  }

  .header {
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-height: 70vh;
    background-image: linear-gradient(to bottom, rgba(248,248,255,0.7), rgba(248,248,255,1)), url('../assets/shape_background_2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

.headerDescription {
  font-size: 0.9rem;
  font-weight: 400 !important;
  text-align: left;
  color: navy;
}


  /* HTML: <div class="grid-lines"></div> */
.grid-lines {
  --s: 80px;  /* control the size of the grid */
  --n: 4;     /* control the granularity */
  --t: 2px;   /* the thickness */
  --g: 10px;  /* the gap between dashes */
  
  --c:#556270 25%,#0000 0;
  background: 
    conic-gradient(at var(--g) var(--t),var(--c))
     calc((var(--s)/var(--n) - var(--g) + var(--t))/2) 0/
     calc(var(--s)/var(--n)) var(--s),
    conic-gradient(from 180deg at var(--t) var(--g),var(--c))
     0 calc((var(--s)/var(--n) - var(--g) + var(--t))/2)/
     var(--s) calc(var(--s)/var(--n));
  /* you probably don't need to set any size in your case */
  width: calc(round(down,100%,var(--s)) + var(--t));
  height: calc(4*var(--s) + var(--t));
}
@supports not (opacity:round(1,1)) {
  .grid-lines {
    width: 100%;
  }
}

   .top {
    clip-path: polygon(0 0,100% 0,100% 100%,0 calc(100% - 50px));
    background-image:  url('../assets/adobe_microphone.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    height:600px;
    width: 100%;
    
  }

 .bottom {
    clip-path: polygon(0 0,100% 50px,100% 100%,0 100%);
    background: ghostwhite;
  margin-top: -50px;
  min-height: 700px;
  width: 100%;
  border-bottom: 2px solid navy;
  }
 

  h2 {
    font-size: 2.5rem;
    font-weight: 400;
    text-align: center;

  }


  .header p {
    line-height: 1.6;
    font-size: 18px;
    color: rgb(228, 228, 228);
    font-weight: 500;
  }

  .header h1 {
    font-weight: 700;
    font-size: 3.5rem;
  }

  .advertiseHeader h2 {
    font-size:2.5rem;
  }

  .advertiseHeader p{
      max-width: 800px;
    }

    .psychologyDiv {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          overflow: hidden;
          padding-bottom: 50px;
    }

    .psychologyDiv h2 {
 
      padding: 50px;
    }

    .psychologyDiv p {
      line-height: 1.6;
      font-size: 18px;
      color: rgb(228, 228, 228);
      font-weight: 500;
      text-align: left;
      padding: 20px;
      max-width: 600px;
    }

    .psychologyDiv .psychologyImg {
      width: 600px;
      object-fit: contain;
    }

    .appDownloadDiv {
      min-height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: linear-gradient(to bottom, rgba(255,255,255,0.8), rgba(255,255,255,1)), url('../assets/AdobeStock_709270708.jpeg');
    }

    .appDownloadDiv > div {
      min-width: 300px;
      max-width: 700px;
      padding:20px;
    }

    

    .appDownloadDiv h2 {
    color: black;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 500;
    min-width: 300px;
    max-width: 600px;
    }

    .appDownloadDiv p {
      line-height: 1.6;
      font-size: 20px;
      font-weight: 400;
      width: 500px;
      margin-bottom: 50px;
    }

    .appDownloadDiv img:hover {
      cursor: pointer;
    }


    .appDownloadDiv button {
    margin-top: -5px;
      width: 500px;
    padding:13px;
    color: white;
    background: hsl(162, 54%, 53%);
    outline: none;
    border: 1px solid hsl(162, 54%, 53%);
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 400ms ease-in-out;
  }






  .appDownloadDiv form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 300px;
    max-width: 600px;
  }

 input[type='email'] {
  width: 100%;
  background-color: #ffffff;
  outline: none;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding:10px;
} 





.form-group {
            position: relative;
            margin-bottom: 20px;
            }

        
            .form-group input{
            width: 500px;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
            transition: border-color 0.3s ease;
              margin-bottom: -75px;
            }
            .form-group label {
            position: absolute;
            left: 12px;
            top: 12px;
            font-size: 14px;
            font-weight: 500;
            color: #777;
            transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
            }
            .form-group input:focus, .form-group textarea:focus {
            border-color: navy;
            outline: none;
            }
            .form-group input:focus + label,
            .form-group input:not(:placeholder-shown) + label {
            top: -6px;
            font-size: 12px;
            color: navy;
            background-color: #ffffff;
            padding: 0 4px;
            border-radius: 4px;
            left: 8px;
            }

            .form-group textarea:focus + label,
            .form-group textarea:not(:placeholder-shown) + label {
            top: -6px;
            font-size: 12px;
            color: navy;
            background-color: #ffffff;
            padding: 0 4px;
            border-radius: 4px;
            left: 8px;
            }


            @media(max-width: 520px) {

            
              .appDownloadDiv input, .appDownloadDiv button, .appDownloadDiv p {
                width: 300px;
              }

              .mainPromoDiv h1 {
              font-size: 1.8rem;
            }
                        }

             
        

  

  @media(max-width: 900px) {

    .header {
      min-height: 60vh;
    }

    #lottiePig {
      display: none;
    }

    .psychologyDiv {
      flex-direction: column;
    }

    .psychologyDiv .psychologyImg {
      width: 450px;
      object-fit: contain;
      height: 400px;
   
    }

   
    .advertise div:nth-child(1) {
    border: none;
  }

  .advertise div:nth-child(2) {
    border: none;
  }

    .advertise {
      flex-direction: column;
    }

    h2 {
    font-size: 1.6em;
    font-weight: 500;
  }

  .appDownloadDiv h2 {
    font-size: 1.6em;
  }


  .header p, .psychologyDiv p {
    line-height: 1.6;
    font-size: 15px;
    color: rgb(228, 228, 228);
    font-weight: 500;
  }

  .appDownloadDiv p {
    font-size: 15px;
    font-weight: 400;
  }



    .header {
      flex-direction: column;
    }

    .header p {
      padding-right: 15px;
    }

    .header h2 {
    font-weight: bold;
  }
  }

  #map {
    height:750px;
    width:100%;
  }

  .mainPromoDiv {
    margin-top: -25px;
    padding-top: 25px;
    width: 100%;
    height: auto;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(255,255,255,1)), url('../assets/geometric_abstract.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  }


  .promotionalPosts {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping */
  display: flex;
  flex-direction: row;
  position: relative;
  scrollbar-width: thin;
  padding-block:25px;
  scroll-snap-type: x mandatory;
}

.promoPost {
  min-height:550px;
  min-width: 350px;
  max-height: 550px;
  max-width: 350px;
  background-color: rgb(255, 255, 255);
  margin:25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
 scroll-snap-align: center;
 box-shadow: 0 10px 10px rgba(23, 79, 138, 0.2);
 border:1px solid gainsboro;
}



.promotionalPosts .promoPost img {
  height:200px;
  width:100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid gainsboro;
}

.promotionalPosts .promoPost .testDiv {
  position: absolute;
  height:30px;
  width:40px;
  bottom:10px;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotionalPosts .promoPost .testDiv .favIcon {
  color: rgb(226, 226, 226);
  cursor: pointer;
  font-size: 20px;
  transition: 400ms ease-in-out;
}

.promotionalPosts .promoPost .testDiv .favIcon:hover {
  color: rgb(199,43,43);
}

.promotionalPosts .promoPost .testDiv .heartIcon {
  color: rgb(121, 121, 121);
  cursor: pointer;
  font-size: 30px;
}

.promotionalPosts .promoPost  #timer {
                padding:5px;
                /* background-color: rgb(50, 112, 228); */
                background:rgb(89, 73, 194); 
                border-radius: 5px;
                min-width: 250px;
                margin: 0px auto;
                color: rgb(65, 65, 65);
                text-align: center;
                position: absolute;
                top:183px;
                left:50%;
                transform: translate(-50%, 0%);
                font-size: 0.8rem;
                font-weight: 500;
                box-shadow:  0 0 2px rgba(0, 0, 0, 0.3);
}

.expired {
  background-color: rgb(221, 73, 73);
}

.promotionalPosts .promoPost .companyInfo {
  margin-top: 20px;
  text-align: left;
  padding:20px;
}



.promotionalPosts .promoPost .promoText {
  margin: 15px auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}


.userCompanySearch {
  top:50%;
  left:50%;
  position: absolute;
  transform: translate(-50%, -50%);
}


.custom-select {
  width: 225px;
  position: relative;
  margin-left:25px;
}

select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.6rem) center !important;
  appearance: none;
  -webkit-appearance: none;
  font-size: 1rem;
  font-style: italic;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #eeeeee;
  border: 1px solid #bbbbbb;
  border-radius: 0.25rem;
  color: navy;
  cursor: pointer;
  outline: none;
  border: none;
}


.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 0.8rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}

.mainPromoDiv h1 {
  font-size: 2.5rem;
  color: navy;
}

.promotionalPosts .promoPost .couponButton {
  outline: none;
      color: hsl(162, 54%, 53%);
      background: transparent;
      border: 1px solid hsl(162, 54%, 53%);
      min-width: 200px;
      max-width: 275px;
      padding-inline: 20px;
      padding-block: 7px;
      text-align: center;
      font-weight: 500;
      font-size: 0.8rem;
      border-radius: 2px;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
      cursor: pointer;
      transition: 400ms ease;
}

.promotionalPosts .promoPost .couponButton:hover {
  color: white;
  background: hsl(162, 54%, 53%);
}

.couponButton span {
  color:navy;
}



.chartContainer {
  display: flex; 
  justify-content: space-evenly;
  align-items: center; 
  height: 600px;
   background-color: rgb(250, 250, 250);
    padding:25px;
    flex-direction: row;

}

.chartContainer div:first-child{
  width:40%;
  font-size: 1.25rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chartContainer div:first-child p {
  max-width: 700px;
}

.chartContainer div:last-child{
  width: 50%;
  height: 400px;
}

@media(max-width:1160px) {
  .chartContainer {
    flex-direction: column;
    height:750px;
  }

  .chartContainer div:first-child {
    width:100%;
    padding:25px;
  }

  .chartContainer div:last-child {
    display: grid;
    place-items: center;
    width:100%;
  }
}

@media(max-width:500px) {

  h1 {
    font-size: 1.25rem;
  }


}



.wrapperElement {
  padding:20px 20px;
  min-height:750px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(250,250,250,0.9), rgb(250,250,250)), url('../assets/svg_background_shapes.jpg');
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.catHeadings {
  min-height: 200px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.catHeadings h1 {
  font-weight: 900;
  font-size: 6.5rem;
  color:navy;
}

.catHeadings h3 {
  margin-top: -40px;
  font-weight: 500;
  font-size: 4.5rem;
  color:hsl(162, 54%, 53%);
  margin-left: 100px;
}



.categoryContainer {
  min-height: 350px;
  min-width: 400px;
  margin:0px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-content: center;
  gap:25px;
  padding:20px 20px;
}




.categoryContainer div {
  padding:15px;
  height:250px;
  width: 200px;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.3);
  border-radius:10px;
  background:white;
  position:relative;
  cursor: pointer;
  transition: 100ms ease-in-out;
}

.categoryContainer div:hover {
  filter:brightness(0.97);
}

.categoryContainer p {
  font-size: 0.8rem;
}

.categoryContainer > div > p:first-child {
  font-weight: 600;
}


.catIcon {
  color: slategray;
  font-size: 1.5rem;
  position: absolute;
  font-size: 1.5rem;
  bottom:15px;
  right:15px;
  opacity: 0.5;
  border-radius: 5px;
}

@media(max-width: 1300px) {
  .wrapperElement {
    flex-direction: column;
  }
}

@media(max-width: 700px) {
  .catHeadings h1 {
    font-size: 4.5rem;
  }

  .catHeadings h3 {
    margin-top: -30px;
  font-size: 2.5rem;
  }

  .categoryContainer {
    margin-top: -50px;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    justify-items: center;

  }

  .categoryContainer > div {
    width: 175px;
    flex: 0 0 auto;
  }



  .categoryContainer > div {
    scroll-snap-align: start;
  }

  @media(max-width: 430px) {
    .categoryContainer {
      grid-template-columns: 1fr;
      min-width: 340px;
      max-width: 340px;
    }

    .categoryContainer > div {
      width: 300px;
      height: 200px
    }
  }
}

.dropdown {
    width:200px;
    position: relative;
    top: 0;
    font-size: 0.8rem;
    margin-left: 25px;
}
.select {
    background: white;
    color: #2a2f3b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px gainsboro solid;
    border-radius: 0.5em;
    padding: 0.75em;
    cursor: pointer;
    transition: 0.3s;
}
.select-clicked {
    border: 1px navy solid;
    box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
  }
.caret {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #030303;
    transition: 0.3s;
}
.caret-rotate {
    transform: rotate(180deg);
}
.menu {
    list-style: none;
    padding: 0.2em 0.5em;
    border: 1px gainsboro solid;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
    color: #0e2669;
    position: absolute;
    background:white;
    top: 4em;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 1;
}
.menu li {
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
}
.menu li:hover {
    background: #f3f3f3;
}
.menuActive {
    background: #f1f1f1;
}
.menu-open {
    display: block;
    opacity: 1;
}
  

@media(max-width:500px) {
  .mainTitle:first-child{

    font-size: 3.5rem;
  }

  .mainTitle:nth-child(2) {
    margin-top: -40px;
    font-size: 2.5rem;
    margin-left: 50px;
  }
}


 span.typed-text {
  font-weight: normal;
  color: #dd7732;
}
 span.cursor {
  display: inline-block;
  background-color: #ccc;
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}
span.cursor.typing {
  animation: none;
}
@keyframes blink {
  0%  { background-color: #ccc; }
  49% { background-color: #ccc; }
  50% { background-color: transparent; }
  99% { background-color: transparent; }
  100%  { background-color: #ccc; }
}
</style>
