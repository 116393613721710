<template>
    <body>
      <div class="headerMain">
        <div class="headerContainer">
          <p style=" margin-block: 20px; font-size: 0.8rem; color:gainsboro;"> <span style="cursor: pointer; color:gainsboro;" @click="goHome"><font-awesome-icon  icon="house" class="chevron"  /></span>  <font-awesome-icon  icon="chevron-right" class="chevron"  /> Companies <font-awesome-icon  icon="chevron-right" class="chevron" /><span style="color: slategray;"> {{ route.params.companyName }}</span></p>
      
            <h1  style=" color:navy; padding-block: 5px;">{{ route.params.companyName }} </h1>
            <p style="font-size: 0.8rem; color:slategray; max-width:500px;">{{ companyHeadline }}</p>

            <div style="height:1px; background: gainsboro; width: 100%; margin-block: 25px;"></div>
              <div class="dropdown">
                <div class="select">
                    <span class="selected">Filter By</span>
                    <div class="caret"></div>
                </div>
                <ul class="menu">
                   <li v-for="option in options" :key="option" @click="filterBy(option)">
                    {{ option  }}
                   </li>
                </ul>
            </div>
     
          </div>
      </div>

      <div  class="promotionalPosts" id="promotions">
        
        <div class="promoPost" v-for="post in companyList">
          <img :src="post.imageUrl" alt="company Image">
          <div id="timer" :title="post.expireTime" :style="{ backgroundColor : checkExpire(post.expireTime, post.id) ? 'rgb(212, 62, 62)' : 'gainsboro'}" >
            <font-awesome-icon  icon="clock" class="fa" style="margin-right:5px" />{{ getExpireTime(post.expireTime) }}
            </div> 

          <div class="companyInfo">
            <h4>{{ post.business }}</h4>
            <p class="address" @click="openMap(post.address)" style="font-size: 12px; margin-bottom: 5px; margin-top: 5px; cursor: pointer;"><font-awesome-icon icon="location-pin" class="fa" style="margin-right:10px; color: #8a8a8a;" />{{ post.address }}</p>
            <a :href="'tel:' +1+post.phone"><p class="phoneNumber" style="font-size: 12px; margin-bottom:5px;"><font-awesome-icon icon="phone" class="fa" style="margin-right:10px; color: #8a8a8a;" />{{ formattedPhoneNumber(post.phone) }}</p>
            </a>
            <a v-show="post.website !== ''" :href="post.website" target="_blank"><p class="phoneNumber" style="font-size: 12px;"><font-awesome-icon icon="globe" class="fa" style="margin-right:10px; color: #8a8a8a;" />{{ post.website.substring(0,45) }}</p>
            </a>
          </div>
          <hr style="margin: 0 auto;">

  
          <div style="white-space: wrap;">
            <h3 style="text-align: center; margin-top: 5px;">{{ post.title }}</h3>
          <p style="font-size: 12px; padding:10px; text-align: center;">{{ post.description }}</p>
          </div>
          <p v-show="post.promoCode && post.postType == 'Promotion'"  class="couponButton"><span style="font-weight: 700; margin-right: 10px;">Promo Code:</span>{{ post.promoCode }}</p>

          <p v-show="post.postType == 'Coupon'" class="couponButton" @click="useCoupon(post)">Save Coupon</p>
          <div v-show="post.postType !== 'Coupon'" class="testDiv" @click="addToFavorites(post)" title="Add to Favorites!">
              <font-awesome-icon icon="heart" class="fa favIcon"  />
            </div>
        </div>
        <div class="lastElement">
           <p style="font-weight: bold; font-size: 1.5rem; margin:100px auto;"></p>
          </div>
   </div>
   <div id="loader-container" v-show="loading">
    <div id="loader-bar"></div>
  </div>
    </body>

  </template>
  
  <script setup>
  import { ref, onMounted, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { db } from '../../firebase';
  import { collection, getDocs, doc, updateDoc, query, where, addDoc, orderBy, startAfter, limit, increment } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import { event, pageview } from 'vue-gtag'
  import Swal from 'sweetalert2';
  
  const route = useRoute();
  const router = useRouter()
  const companyName = ref(route.params.companyName);
  const companyList = ref([])
  const postReady = ref(false);
  const postTitle = ref('')
  const lastVisible = ref('');
  const userFilter = ref(false);
  const userFilterSelection = ref('');
  const companyHeadline = ref('')
  const loading = ref(false);
  const options = ref(['All', 'Promotion', 'Deal', 'Coupon']);


  const auth = getAuth();



  watch(
  () => route.params.companyName,
  (param) => {
    handleCompanySearch(param)
  }
)

const goHome = () => {
  router.push({
    'name' : 'home'
  })
}

const getAllPosts = async () => {
  loading.value = true;
  try {
      const emptyArray = []
    const promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    where('business', '==', route.params.companyName),
    orderBy('business'),
    limit(5));
    const snapShot = await getDocs(promoQuery)
    snapShot.forEach(doc => {
      emptyArray.push({id:doc.id, ...doc.data()})
    })
    if(emptyArray.length > 0) {
      companyList.value = emptyArray
      lastVisible.value = snapShot.docs[snapShot.docs.length - 1];

      if(emptyArray.length < 5) {
        lastVisible.value = null;
      }
    } else {
      toast("No Companies Found...", {
          "type": "info",
          "transition": "flip",
          autoClose: 3000
        })
    }
    loading.value = false;
} catch(e) {
  loading.value = false;
}
}

const filterBy = async (filterType) => {
  if(filterType === 'All') {
    userFilter.value = false;
    await getAllPosts();
    return;
  }

  userFilterSelection.value = filterType
  userFilter.value = true;
  loading.value = true;
  
try {
      // postType.value = userPostSelection.value
      const emptyArray = []
    const promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    where('business', '==', route.params.companyName),
    where('postType', '==', filterType),
    orderBy('business'),
    limit(5));
    const snapShot = await getDocs(promoQuery)
    snapShot.forEach(doc => {
      emptyArray.push({id:doc.id, ...doc.data()})
    })
    if(emptyArray.length > 0) {
      companyList.value = emptyArray
      lastVisible.value = snapShot.docs[snapShot.docs.length - 1];

      if(emptyArray.length < 5) {
        lastVisible.value = null;
      }
    } else {
      toast(`No ${filterType}'s found`, {
          "type": "info",
          "transition": "flip",
          autoClose: 2000
        })
    }
    loading.value = false;
} catch(e) {
  loading.value = false;
}
}

async function queryAndUpdateFavDoc(id) {
    try {
      // Define the query
      const q = query(collection(db, 'FavClicks'), where('postId', '==', id));

      // Execute the query
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (documentSnapshot) => {
          const docRef = doc(db, 'FavClicks', documentSnapshot.id);
          await updateDoc(docRef, {
           clicks: increment(1)
          });

        });
      } else {
      }
    } catch (error) {
    }
  }


const getExpireTime = (expireTime) => {
    const expireDate = new Date(expireTime);
    const today = new Date();

    if (expireDate < today) {
        return 'Expired';
    }

    const timeLeft = expireDate - today;
    const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

    if (hoursLeft < 24) {
        if (hoursLeft < 1) {
            return `Expires in ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
        } else {
            if (hoursLeft < 2) {
                return `Expires in ${hoursLeft} hour ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            } else {
                return `Expires in ${hoursLeft} hours ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            }
        }
    } else {
        return `Expires: ${expireTime}`;
    }
};

const deletePost = async  (post) => {
 const docRef = doc(db, 'CompanyPosts', post)
 await deleteDoc(docRef);
}

const checkExpire = (time, postId) => {
 
  const expireDate = new Date(time);
  const today = new Date();
  if(today > expireDate) {
    let difference = today - expireDate
    const hoursLeft = Math.floor(difference/ (1000 * 60 * 60));
   if(hoursLeft > 72) {
    const days = hoursLeft / 24
    if(days > 3) {
      deletePost(postId);
   }
  }
  if(expireDate < today) {
    return true;
  } else return false;
}
}



const useCoupon = async (coupon) => {
  if (!auth.currentUser) {
    toast("Login to save coupons.", {
      type: "info",
      transition: "zoom",
      autoClose: 1000
    });
    return false;
  }

  const uid = auth.currentUser.uid;
  const usedQuery = query(
    collection(db, 'Coupons'),
    where('postId', '==', coupon.id),
    where('useruid', '==', uid),
    where('hidden', '==', true)
  );
  const querySnapshotOne = await getDocs(usedQuery);

  if (!querySnapshotOne.empty) {
    toast("Coupon already used.", {
      type: "info",
      transition: "zoom",
      autoClose: 1000
    });
    return;
  }

  const q = query(
    collection(db, 'Coupons'),
    where('postId', '==', coupon.id),
    where('useruid', '==', uid)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    toast("You already have this coupon.", {
      type: "info",
      transition: "zoom",
      autoClose: 1000
    });
    return;
  }


  Swal.fire({
    text: `Save Coupon: ${coupon.title}?`,
    showDenyButton: true,
    confirmButtonText: "Save",
    denyButtonText: `Cancel`,
    showClass: {
    popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `
  },
  hideClass: {
    popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `
  },
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await queryAndUpdateFavDoc(coupon.id)
        await addDoc(collection(db, 'Coupons'), {
          approved: true,
          postId: coupon.id,
          useruid: uid,
          uid: coupon.uid,
          business: coupon.business,
          address: coupon.address,
          phone: coupon.phone,
          website: coupon.website,
          postType: coupon.postType,
          title: coupon.title,
          hidden: false,
          description: coupon.description,
          imageUrl: coupon.imageUrl,
          expiration: coupon.expireTime,
          couponRedemptionTime: coupon.couponRedemption,
          couponRedeemed: false,
          couponRedemptionDate: null,
          couponRedemptionExpired: false,
          category: coupon.category
        }).then(() => {
          toast("Coupon Saved!", {
            type: "success",
            transition: "zoom",
            autoClose: 1000
          });
        });

      } catch (e) {
        alert(e.message);
      }
    } else if (result.isDenied) {
    }
  });
}


const searchMorePosts = async () => {
  if(!postReady || !lastVisible.value) {
    return false;
  }

  loading.value = true;

  if(userFilter.value === true) {
    try {

    const promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    where('business', '==', companyName.value),
    where('postType', '==', userFilterSelection.value),
    orderBy('business'),
    startAfter(lastVisible.value),
    limit(5));
    const querySnapshot = await getDocs(promoQuery);
      const emptyArray = [];
      querySnapshot.forEach(doc => {
        emptyArray.push({ id: doc.id, ...doc.data() })
      });
      companyList.value = [...companyList.value, ...emptyArray]

      if(emptyArray.length > 0) {
          lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        if(emptyArray.length < 5) {
          lastVisible.value = null;
        }

        loading.value = false;
    } catch (error) {
      loading.value = false;
    }
  } else {
    try {

      const promoQuery = query(
      collection(db, 'CompanyPosts'), 
      where('approved', '==', true),
      where('business', '==', companyName.value),
      orderBy('business'),
      startAfter(lastVisible.value),
      limit(5));
      const querySnapshot = await getDocs(promoQuery);
        const emptyArray = [];
        querySnapshot.forEach(doc => {
          emptyArray.push({ id: doc.id, ...doc.data() })
        });
        companyList.value = [...companyList.value, ...emptyArray]

        if(emptyArray.length > 0) {
            lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
          }

          if(emptyArray.length < 5) {
            lastVisible.value = null;
          }
          loading.value = false;
      } catch (error) {
          loading.value = false;
      }
  }
 

 
}

const handleCompanySearch = async (param) => {
  userFilter.value = false;
  if(companyName.value == param) {
    return;
  }
  loading.value = true;
  const promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    where('business', '==', param),
    orderBy('business'),
    limit(5));
    const querySnapshot = await getDocs(promoQuery);
      const emptyArray = [];
      querySnapshot.forEach(doc => {
        emptyArray.push({ id: doc.id, ...doc.data() })
      });
     
  

      if(emptyArray.length > 0) {
        companyList.value = emptyArray
        companyHeadline.value = emptyArray[0].headline;
        postReady.value = true;
        companyName.value = param
          lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        if(emptyArray.length < 5) {
          lastVisible.value = null;
        }
      if(companyList.value.length < 1) {
        toast("Company not found, check spelling.", {
                                "type": "error",
                                "transition": "slide",
                                autoClose: 2000
                              })
                              setTimeout(() => {
                                router.push('/')
                              }, 2000);
      }
      loading.value = false;
  
    
}

const addToFavorites = async (post) => {

if(!auth.currentUser) {
  toast("Login to save favorites!", {
    "type": "info",
    "transition": "zoom",
    autoClose: 1000
  })
  return false;
}

// const favoritesRef = collection(db, `Favorites/${uid}/Docs`);
// const querySnapshot = await getDocs(query(favoritesRef, where('postId', '==', post.id)));
const uid = auth.currentUser.uid;
const q = query(collection(db, `Favorites`), where('postId', '==', post.id),where('useruid', '==', uid));
const querySnapshot = await getDocs(q);

if (!querySnapshot.empty) {
  // Post already exists in favorites
  toast("This is already in your favorites!", {
    "type": "info",
    "transition": "zoom",
    autoClose: 1000
  })
  return;
}

  Swal.fire({
  text: `Save ${post.title}?`,
  showDenyButton: true,
  confirmButtonText: "Save",
  showClass: {
  popup: `
    animate__animated
    animate__fadeInUp
    animate__faster
  `
},
hideClass: {
  popup: `
    animate__animated
    animate__fadeOutDown
    animate__faster
  `
},
}).then(async(result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
        
  try {
      postTitle.value = post.title;
      await queryAndUpdateFavDoc(post.id)
      const couponFav = {
          approved: true,
          postId: post.id,
          useruid: uid,
          uid: post.uid,
          business: post.business,
          address: post.address,
          phone: post.phone,
          website: post.website,
          postType: post.postType,
          title: post.title,
          hidden:false,
          description: post.description,
          imageUrl: post.imageUrl,
          expiration: post.expireTime,
          couponRedemptionTime: post.couponRedemption,
          couponRedeemed: false,
          couponRedemptionDate: null,
          couponRedemptionExpired: false,
          category: post.category
      }
      const regFav = {
          approved:true,
          postId: post.id,
          uid: post.uid,
          useruid: uid,
          business: post.business,
          address: post.address,
          phone: post.phone,
          website: post.website,
          postType: post.postType,
          title: post.title,
          description: post.description,
          imageUrl: post.imageUrl,
          expiration: post.expireTime,
          promoCode: post.promoCode ? post.promoCode : null,
          category: post.category
      }
      await addDoc(collection(db, `Favorites`), post.postType === 'Coupon' ? couponFav : regFav).then(() => {
     
                            toast(`Saved!`, {
                              "type": "success",
                              "transition": "zoom",
                              autoClose: 1000
                            })
        });

    } catch (e) {
 
      alert(e.message)
    }
  } else if (result.isDenied) {
  
  } else {
    
  }
});

}


  const openMap = (addy) => {
  window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addy)}`);
}


  const formattedPhoneNumber = (phone) => {
  const areaCode = phone.substring(0, 3);
  const firstPart = phone.substring(3, 6);
  const secondPart = phone.substring(6, 10);
  return `(${areaCode}) ${firstPart}-${secondPart}`;
};



  onMounted(async () => {
    pageview({ page_path: '/companySearch' })


    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'  // Optional: smooth scrolling behavior
    });

    const dropdown = document.querySelector(".dropdown");
    const select = dropdown.querySelector(".select");
    const caret = dropdown.querySelector(".caret");
    const menu = dropdown.querySelector(".menu");
    const options = dropdown.querySelectorAll(".menu li");
    const selected = dropdown.querySelector(".selected");
    select.addEventListener("click", () => {
        select.classList.toggle("select-clicked");
        caret.classList.toggle("caret-rotate");
        menu.classList.toggle("menu-open")
    })
    options.forEach(option => {
        option.addEventListener("click", () => {
            selected.innerText = option.innerText;
            select.classList.remove("select-clicked");
            caret.classList.remove("caret-rotate");
            menu.classList.remove("menu-open");
            options.forEach(option => {
                option.classList.remove("menuActive")
            })
            option.classList.add("menuActive")
        })
    })

    document.addEventListener("click", (event) => {
    if (!dropdown.contains(event.target)) {
        select.classList.remove("select-clicked");
        caret.classList.remove("caret-rotate");
        menu.classList.remove("menu-open");
    }
});
    
    const lastDiv = document.querySelectorAll('.lastElement')
    const observer = new IntersectionObserver((element) => {
      element.forEach((item) => {
        if(item.isIntersecting) {
          searchMorePosts();
        }
      })
    }, {
      rootMargin: '0px 50px 0px 50px',
      threshold: 0
    })
    lastDiv.forEach((element) => observer.observe(element))
   try {
    loading.value = true;
    const promoQuery = query(
    collection(db, 'CompanyPosts'), 
    where('approved', '==', true),
    where('business', '==', companyName.value),
    orderBy('business'),
    limit(5));
    const querySnapshot = await getDocs(promoQuery);
      const emptyArray = [];
      querySnapshot.forEach(doc => {
        emptyArray.push({ id: doc.id, ...doc.data() })
      });
      companyHeadline.value = emptyArray[0].headline;
      companyList.value = emptyArray
      postReady.value = true;
      loading.value = false;

      if(emptyArray.length > 0) {
          lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        if(emptyArray.length < 5) {
          lastVisible.value = null;
        }
      if(companyList.value.length < 1) {
        toast("Company not found, check spelling.", {
                                "type": "error",
                                "transition": "slide",
                                autoClose: 2000
                              })
                              setTimeout(() => {
                                router.push('/')
                              }, 2000);
      }
   } catch (e) {
                        toast("Something went wrong, try again.", {
                                "type": "error",
                                "transition": "slide",
                                autoClose: 2000
                              })
                              setTimeout(() => {
                                router.push('/')
                              }, 2000);
   }
  })

  </script>
  
  <style scoped>

  * {
    color: navy;
  }
  
  .companyInfo a {
  text-decoration: none;
}

h1 {
    text-align: left;
    color: navy;
    font-size: 3.5rem;
    font-weight: 900;
}


.promotionalPosts {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping */
  display: flex;
  flex-direction: row;
  margin: 25px auto;
  scroll-snap-type: x mandatory;
}

#loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust the height as needed */
  background-color: white;
  overflow: hidden;
  z-index: 1000; /* Ensure it's above other content */
}

#loader-bar {
  position: absolute;
  top: 0;
  left: -200px; /* Start the bar off-screen */
  width: 200px;
  border-radius: 15px;
  height: 5px; /* Same as loader container height */
  background-color: #11ff09; /* Bar color */
  animation: slide 750ms linear infinite;
}

@keyframes slide {
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
}

.promoPost {
  min-height:550px;
  min-width: 350px;
  max-height: 550px;
  max-width: 350px;
  background-color: rgb(255, 255, 255);
  margin:25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
 scroll-snap-align: center;
 box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
}

.promotionalPosts .promoPost img {
  height:200px;
  width:100%;
  object-fit: cover;
  object-position: center;
  border-bottom: 1px solid gainsboro;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.promotionalPosts .promoPost .testDiv {
  position: absolute;
  height:40px;
  width:30px;
  border-radius: 5px;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 400ms ease-in-out;
}



.promotionalPosts .promoPost .testDiv .favIcon {
  color: rgb(226, 226, 226);
  cursor: pointer;
  font-size: 20px;
  margin-right: 4px;
  transition: 400ms ease-in-out;
}

.promotionalPosts .promoPost .testDiv .favIcon:hover {
  color: rgb(199, 43, 43);
  
}



.promotionalPosts .promoPost  #timer {
                padding:5px;
                /* background-color: rgb(50, 112, 228); */
                background:rgb(89, 73, 194); 
                border-radius: 5px;
                min-width: 250px;
                margin: 0px auto;
                color: rgb(65, 65, 65);
                text-align: center;
                position: absolute;
                top:183px;
                left:50%;
                transform: translate(-50%, 0%);
                font-size: 0.8rem;
                font-weight: 500;
                box-shadow:  0 0 2px rgba(0, 0, 0, 0.3);
}


.promotionalPosts .promoPost .companyInfo {
  margin-top: 20px;
  text-align: left;
  padding:20px;
}



.promotionalPosts .promoPost .promoText {
  margin: 15px auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.promotionalPosts .promoPost .couponButton {
  outline: none;
      color: hsl(162, 54%, 53%);
      background: transparent;
      border: 1px solid hsl(162, 54%, 53%);
      min-width: 200px;
      max-width: 275px;
      padding-inline: 20px;
      padding-block: 7px;
      text-align: center;
      font-weight: 500;
      font-size: 0.8rem;
      border-radius: 2px;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
      cursor: pointer;
      transition: 400ms ease;
}

.promotionalPosts .promoPost .couponButton:hover {
  color: white;
  background: hsl(162, 54%, 53%);
}

   .loaderContainer {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        display: grid;
        place-content: center;
      }

      .custom-loader {
      width:40px;
      height:40px;
      border-radius:50%;
      background:conic-gradient(#0000 10%,#FFFFFF);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 0.5s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}

.opacity {
opacity: 0.5;
filter: brightness(0.5);
}



.headerContainer {
              padding-block: 25px; 
               padding-inline:28px; 
               background:white;
               max-width:1000px;
            }

            .filter {
              display: flex;
              flex-direction: row
            }

            .filter button{
              padding-inline: 30px;
              padding-block: 10px;
              border-radius: 5px;
              background:white;
              outline: 1px solid gainsboro;
              border: none;
              cursor: pointer;
              transition: 200ms ease-in-out;
            }

            .filter button:hover {
              outline: 1px solid navy;
            }

            .headerContainer p {
              color: rgb(32, 32, 32);
            }


            .headerMain {
                padding:20px 20px;
                width: 100%;
                background:white;
            }
            

            @media(max-width:500px) {
              h1 {
                font-size: 1.75rem;
              }
            }

            .chevron {
              margin-inline:5px;
              color: gainsboro;
            }
       
  </style>
  