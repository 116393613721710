<template>
    <div class="tableContainer">
        <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Business</th>
          <th>Category</th>
          <th>Approved</th>
          <th>Denied</th>
          <th>Preview</th>
          <th>Update</th>
          <th>Deny</th>
          <!-- Add more headers if needed -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(post, index) in posts" :key="index">
          <td>{{ post.title }}</td>
          <td>{{ post.business }}</td>
          <td>{{ post.category }}</td>
          <td>{{ post.approved }}</td>
          <td>{{ post.denied }}</td>
          <td><button class="previewBtn" @click="previewPost(post)">Preview</button></td>
          <td><button class="myBtn" @click="updatePosts(post)">Approve</button></td>
          <td><button class="denyBtn" @click="denyPost(post)">Deny</button></td>
          <!-- Add more columns if needed -->
        </tr>
      </tbody>
    </table>
    </div>

    <!------------------------------- Preview Container ----------------------------------->


      <div class="imagePreview" :class="{'visible' : previewForm}">
            <img :src="imageDownloadUrl" class="promoImg"  alt="photo">
                   <div id="timer" :title="date">
                    <font-awesome-icon icon="clock" class="fa" style="margin-right:5px" /> Expires:   <span style="font-weight: 700;"></span> {{ dateRef }}
                    </div>  
        
                <div style="text-align: left; padding-left: 25px; margin-top:25px;">
                    <h2>{{ businessRef }}</h2>
                <p id="title"><font-awesome-icon icon="location-pin" class="fa" style="margin-right:10px" />{{ addressRef }}</p>
                <p id="number"><font-awesome-icon icon="phone" class="fa" style="margin-right:10px" />{{ formattedPhoneNumber }}</p>
                </div>
          
                <hr>

                <h3 style="text-align: center;">{{ titleRef }}</h3>
                <!-- max 81 characters -->
                <p id="descriptionText" style="text-align: center; margin-left: 0px; font-weight: 500;">{{ descriptionRef }}</p>
                <p v-if="promotionCode" style="text-align: center; margin-top: 15px;">
                 <span style="font-weight: 700; margin-right: 10px;">Promo Code: </span>{{ promotionCode }}
                </p>
                <button v-if="postTypeRef == 'Coupon'" class="couponButton">Get Coupon</button>
                <div class="greenCheckMark" @click="closePost()" v-if="previewForm" title="close">
                    <font-awesome-icon icon="x" class="fa" />
                </div>
              
       </div>

     <div v-if="denyModalBool" class="denyModalContainer">
      <div class="denyModal">
        <h3 style="margin-bottom: 15px;">Reject Reason</h3>
        <input type="text" v-model="denyText" placeholder="Whats wrong?">
        <button @click="submitDenial">Submit</button>
        <div class="closeDenyModal" @click="denyModalBool = false;">
        <font-awesome-icon icon="x" class="fa" style="color:white;" />
       </div>
       </div>
      
     </div>

  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { getAuth } from 'firebase/auth';
  import { db } from '../../firebase';
  import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
  
  const posts = ref([]);
  let previewForm = ref(false);

    const businessRef = ref('')
    const phoneRef = ref('')
    const addressRef = ref('')
    const titleRef = ref('');
    const descriptionRef = ref('')
    const imageDownloadUrl = ref('');
    const dateRef = ref('')
    const postTypeRef = ref('')
    const promotionCode = ref('')
    const denyModalBool = ref(false)
    const denyText = ref('');
    const postId = ref('')
    const auth = getAuth();

  const submitDenial = async () => {

     try {
      const postRef = doc(db, 'CompanyPosts', postId.value);
      await updateDoc(postRef, {
          approved: false,
          denied: true,
          deniedMessage: denyText.value
      }).then(async () => {
        alert('Denial Submitted');
        await getNotApproved();
      })
     } catch (error) {
      alert('Something went wrong')
     }
  }

  const denyPost = (post) => {
    denyModalBool.value = true;
    postId.value = post.id;
  }

    const formattedPhoneNumber = computed(() => {
        if(phoneRef.value.length == 0) {
            return false;
        }
  const areaCode = phoneRef.value.substring(0, 3);
  const firstPart = phoneRef.value.substring(3, 6);
  const secondPart = phoneRef.value.substring(6, 10);
  return `(${areaCode}) ${firstPart}-${secondPart}`;
});


const closePost = () => {
    previewForm.value = false;
}


  const previewPost = async  ({title, description, business,  address, phone,  expireTime, imageUrl, postType, promoCode}) => {
    imageDownloadUrl.value = imageUrl;
    businessRef.value = business;
    addressRef.value = address,
    phoneRef.value = phone;
    titleRef.value = title;
    descriptionRef.value = description;
    dateRef.value = expireTime
    postTypeRef.value = postType
    promotionCode.value = promoCode
    previewForm.value = true;
  }


  const updateFavorites = async (item, favPost) => {
    favPost.approved = true;
    favPost['useruid'] = item.useruid;
    favPost['postId'] = item.postId
    const favRef = doc(db, 'Favorites', item.id);
   

    await updateDoc(favRef, favPost)
    .then(() => { return;})
    .catch((e) => { console.log(e.message)})
  }


  const findFavorites = async (post) => {
    const favoritesQuery = query(collection(db, 'Favorites'),
                                    where('postId', '==', post.id) ,
                                    );
        const querySnapshot = await getDocs(favoritesQuery);
        const emptyArray = [];
        querySnapshot.forEach(doc => {
            emptyArray.push({ ...doc.data(), id: doc.id });
        });
        if(emptyArray.length > 0) {
          await Promise.all(emptyArray.map(async (item) => {
          await updateFavorites(item, post);
        }));
        }
  }

 

  const updatePosts = async (post) => {
   
    const postRef = doc(db, 'CompanyPosts', post.id);
    await updateDoc(postRef, {
        approved: true,
        denied: false,
        deniedMessage: '',
    }).then(async () =>
    {
      // await findFavorites(post)
      getNotApproved()
    }
    )
  }


  
  const getPosts = async () => { // Get all CompanyPosts
    if (auth.currentUser) {
      const uid = auth.currentUser.uid;
      const querySnapshot = await getDocs(collection(db, 'CompanyPosts'));
      const tempPosts = [];
      querySnapshot.forEach(doc => {
        tempPosts.push({ id: doc.id, ...doc.data() })
      });
      posts.value = tempPosts;
    }
  };

  const getNotApproved = async () => {
    const auth = getAuth();
    if (auth.currentUser) {
    const q = query(collection(db, 'CompanyPosts'), where('approved', '==', false));
    const querySnapshot = await getDocs(q);

      const tempPosts = [];
      querySnapshot.forEach(doc => {
        tempPosts.push({ id: doc.id, ...doc.data() })
      });
      posts.value = tempPosts;
    }
  }

  
  onMounted(getNotApproved);
  </script>
  
  <style scoped>
  /* Add your styles here */

  .tableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ddd;
  }

  
  
  th, td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .myBtn {
    padding-inline: 20px;
    padding-block: 5px;
    background-color: lawngreen;
    color: white;
    font-weight: 700;
    outline:none;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.4);
    cursor: pointer;
  }


  .denyModalContainer {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .denyModal {
    width: 400px;
    height: 300px;
    background-color: ghostwhite;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
 }

 .closeDenyModal {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:10px;
  right: 15px;
  cursor: pointer;
 }

 .denyModal button {
  margin-top: 15px;
  width: 90%;
  padding-inline: 20px;
    padding-block: 10px;
    background-color: rgb(252, 17, 0);
    color: white;
    font-weight: 700;
    outline:none;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.4);
    cursor: pointer;
 }

 .denyModal input {
  width: 90%;
 }
  .denyBtn {
    padding-inline: 20px;
    padding-block: 5px;
    background-color: rgb(252, 17, 0);
    color: white;
    font-weight: 700;
    outline:none;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.4);
    cursor: pointer;
  }

  .previewBtn {
    padding-inline: 20px;
    padding-block: 5px;
    background-color: rgb(0, 118, 252);
    color: white;
    font-weight: 700;
    outline:none;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.4);
    cursor: pointer;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .imagePreview {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            height:650px;
            width: 400px;
            background-color: white;
            border-radius: 3px;
            box-shadow: 0 0 10px rgba(0,0,0,0.6);
            margin:20px auto;
            visibility: hidden;
        }

        .visible {
            visibility: visible;
        }
        .greenCheckMark {
            position: absolute;
            right:-20px;
            top:-20px;
            height:40px;
            width:40px;
            border-radius: 50%;
            background-color: rgb(190, 78, 87);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            transition: 0.3s ease-in-out;
            cursor: pointer;
        }
        .greenCheckMark:hover {
            background-color: rgb(59, 236, 59);
        }

        .imagePreview .promoImg {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            height:35%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-bottom: 3px solid black;
        }
        
  
        .imagePreview h3 {
            font-size: 1.4em;
            padding-block: 10px;
        }
        .imagePreview h2 {
            font-size: 1.6em;
            padding-block: 10px;
        }

        .imagePreview span {
            margin: 5px auto;
        }

        .imagePreview .timerContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap:20px;
          position:absolute;
          top:30%;
          left:50%;
          min-width: 300px;
          max-width: 300px;
          transform: translate(-50%, 0%);
          padding:3px;
          background-color: rgb(158, 52, 52);
          border-radius: 5px;
          color: white;
          box-shadow: 0 0 20px rgba(0,0,0,0.6);
        }

        .imagePreview .timerContainer .microContainer {
          display: flex;
          flex-direction:column;
        }
        .imagePreview .timerContainer .microContainer p:nth-child(1) {
          padding-left: 15px;
          color:rgb(255, 255, 255);
          letter-spacing: 1px;
        }
        .imagePreview .timerContainer .microContainer p:nth-child(2) {
         font-weight: bold;
        }

            .imagePreview #timer {
                padding:5px;
                background-color: rgb(202, 105, 105);
                border-radius: 5px;
                min-width: 300px;
                box-shadow: 0 0 5px rgba(0,0,0,0.6);
                margin: 0px auto;
                color: white;
                text-align: center;
                position: absolute;
                top:32.5%;
                left:50%;
                transform: translate(-50%, 0%)
            }
            .imagePreview p {
                color: rgb(43, 43, 43);
                font-size: 13px;
                padding:5px;
            }
            .imagePreview div:nth-child(1) {
                width: 200px;
                margin: 0px auto;
            }

            .imagePreview hr {
                width: 75%;
                margin: 10px auto;
          
            }

          

            .imagePreview .couponButton{
               
                background: #1670e6;
                border-radius: 20px;
                outline: none;
                border: none;
                color: white;
                padding-block: 12px;
                padding-inline: 100px;
                width: 300px;
                text-align: center;
                font-weight: 600;
                text-transform: uppercase;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
                justify-content: center;
                align-items: center;
                margin: 10px auto;
                transition: 0.3s ease-in-out;
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translate(-50%, 0%);
            }

            .imagePreview .couponButton:hover {
                background:#104d9c;
                box-shadow: 0 0 5px rgba(0,0,0,0.9);
                cursor: pointer;
            }

            #title, #number {
                color: rgb(46, 46, 46);
            }

            #descriptionText {
                padding-inline: 25px;
                color: rgb(46, 46, 46);
             
            }
  
  </style>
  