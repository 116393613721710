<template>
  <body>
    <div class="container">
      <form @submit.prevent="firstNext" v-show="firstForm" class="registration-form-one">
        <h1>Business</h1>       
        <div class="form-group">
          <label for="businessName">Business Name</label>
          <div class="inputHolder">
                <font-awesome-icon icon="id-card" class="fa" />
              <input type="text" id="businessName" v-model="businessName" required />
              </div>
        
        </div>

        <label for="businessCategory">Choose Category</label>
           <select id="businessCategory" v-model="selectedCategory" class="custom-select">
            <option v-for="category in businessCategories" :key="category" :value="category">{{ category }}</option>
          </select>
        
          <div>
            <button type="submit" style="width: 100%;">Next</button>
          </div>
      </form>

      <form @submit.prevent="secondNext" v-show="secondForm" class="registration-form-two">
        <h1>Account Details</h1>
        <div class="form-group">
          <label for="address">Complete Address</label>
          <div class="inputHolder">
            <font-awesome-icon icon="location-pin" class="fa" />
          <input type="text" id="address" v-model="address" required placeholder="1234 Commerce St, Olive Branch, MS 38654" />
          </div>
          <p v-if="addressHelperText" style="font-size: 10px; color: red; margin-top: 10px;">Address is badly formatted.</p>

         
        </div>
        <div class="form-group">
          <label for="phoneNumber">Phone Number</label>

         <div class="inputHolder">
          <font-awesome-icon icon="phone" class="fa" />
          <input type="text" id="phoneNumber" placeholder="9015550123" v-model="phoneNumber"   title="Enter a 10 digit phone number" required />
         </div>
          <p v-if="helperText" style="font-size: 10px; color: red; margin-top: 10px;">Enter a 10 digit phone number. Example: 9015550123</p>
        </div>
        <div class="form-group">
          <label for="website">Website</label>

          <div class="inputHolder">
            <font-awesome-icon icon="globe" class="fa" />
          <input type="text" id="website" placeholder="www.yourwebsite.com" v-model="website" />
          </div>

        </div>
        <div class="form-group">
          <label for="description">Company Headline <span id="co-description">(This will be public facing on company search.)</span></label>

          <div class="inputHolder">
            <font-awesome-icon icon="pen-fancy" class="fa" />
          <input type="text" id="description" placeholder="Voted #1 Best Gym in Desoto County MS!" v-model="companyDescription" required />
          </div>

        </div>
        <div >
          <button style="width:100%;" type="submit">Next</button>
        </div>
      </form>

      <form @submit.prevent="register" v-show="thirdForm" class="registration-form-three">
        <h1>Create Account</h1>
        <div class="form-group">
          <label for="email">Email</label>

          <div class="inputHolder">
            <font-awesome-icon icon="envelope" class="fa" />
          <input type="email" id="email" v-model="email" required />
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>

          <div class="inputHolder">
            <font-awesome-icon icon="lock" class="fa" />
          <input type="password" id="password" v-model="password" required />
          </div>
        </div>
        <div>
          <button style="width:100%;" type="submit">Register</button>
        </div>
        <p class="legal">By Registering, you agree to our Terms and Conditions & Privacy Policy.</p>
      </form>
    </div>
    <div class="loadingHolder" v-show="loading || registerCompleted">
        <div class="custom-loader" v-show="loading">
                </div>
      </div>

      
  </body>
  <div class="loadingHolder" v-show="stripeLoading">
        <div class="stripeContainer">
          <img src="../assets/icons8-stripe-240.png" alt="stripe_image">
       
       
          <div class="stripe-loader-container">
            <span class="loader"></span>
          </div>
          
          <div class="sub-plan">
              <div class="account-created">
                ✓
                </div>  
                <p class="account-created-text">Account Created </p>
                <div class="redirecting">
                  S
                </div>
                <p class="redirect-text">Redirecting to Stripe</p>
          </div>
        </div>
      </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getAuth, createUserWithEmailAndPassword,  } from 'firebase/auth'; // Import Firebase authentication method
import { collection, addDoc, doc, onSnapshot } from "firebase/firestore"; 
import { db } from '../../firebase'
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios'
import Swal from 'sweetalert2';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import CheckmarkAnimation from '../assets/animations/checkmark_animation.json';
import 'animate.css';
const router = useRouter();
const route = useRoute();


// Define reactive variables for form inputs

const businessName = ref('');
const address = ref('');
const phoneNumber = ref();
const website = ref('');
const email = ref('');
const password = ref('');
const companyDescription = ref('');
const coordObject = ref({});
const helperText = ref(false);
const addressHelperText = ref(false);
const firstForm = ref(true);
const secondForm = ref(false)
const thirdForm = ref(false);
let loading = ref(false)
let registerCompleted = ref(false);
let stripeLoading = ref(false);
const enrollmentPlan = ref(route.params.plan)
const auth = getAuth();
const businessCategories = ref([
  'Animal Services',
  'Art',
  'Beauty',
  'Boutique',
  'Educational',
  'Entertainment',
  'Electronics',
  'Food & Beverage',
  'General',
  'Health & Fitness',
  'Hobbies & Recreation',
  'Home Services',
  'Hospitality',
  'Legal Services',
  'Marketing & Advertising',
  'Other',
  'Outdoors',
  'Professional Services',
  'Photo & Video',
  'Real Estate',
  'Recreation',
  'Retail',
  'Sports',
  'STEM',
  'Street Repair',
  'Tattoo',
  'Technology & IT',
  'Transportation & Logistics',
  'Vehicle Services',
  'Yard Services'
]);
const selectedCategory = ref('Art')



const goBack = () => {
  router.push('/userRegister')
}


const firstNext = () => {
 if(businessName.value == '') {
  return;
 } else {
    firstForm.value = false;
    secondForm.value = true;
 }
}


const secondNext = async () => {
    if (address.value == '' || phoneNumber.value == '') {
        return;
    }

    try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address.value)}&key=AIzaSyChlmPj8_vsGHbVHzzHhBF1fimdALRPcsY`);
        
        if (response.data.status == 'ZERO_RESULTS') {
            addressHelperText.value = true;
            return;
        } else {
            addressHelperText.value = false;
            coordObject.value = response.data.results[0].geometry.location;
            console.log(coordObject.value);

            if (phoneNumber.value.length !== 10) {
                helperText.value = true;
                return;
            } else {
                helperText.value = false;
                secondForm.value = false;
                thirdForm.value = true;
            }
        }
    } catch (error) {
        console.log(error);
        return;
    }
};

const goToPreviousForm = () => {
  if (secondForm.value) {
    secondForm.value = false;
    firstForm.value = true;
  } else if (thirdForm.value) {
    thirdForm.value = false;
    secondForm.value = true;
  }
};

const addExtraDocument = async (uid) => {
  try {
    const docRef = await addDoc(collection(db, `Users/${uid}/Docs`), {
      userType: 'Company',
      name: businessName.value,
      subscription: enrollmentPlan.value
    });
    return docRef;
  } catch (e) {
    console.error('Error adding document: ', e);
    throw e;
  }
};

const getCoords = async () => {

}





const addDocument = async (uid) => {
  try {

    let site = website.value;
    if(site !== '') {
      if (!site.startsWith('http://') || !site.startsWith('https://')) {
        site = 'https://' + website.value;
        website.value = site;
      }
    }
   
     await addDoc(collection(db, `Company/${uid}/Docs`), {
      business: businessName.value,
      category: selectedCategory.value,
      address: address.value,
      phone: phoneNumber.value,
      website: website.value === '' ? '' : website.value,
      email: email.value,
      headline: companyDescription.value,
      coordinates: coordObject.value,
      subscription: enrollmentPlan.value
    });
  } catch (e) {
    console.error('Error adding document: ', e);
    throw e; // rethrow error to handle it in the calling function
  }
};

const loadSubscriptionPlan = async (plan, useruid) => {
        plan = plan.toLowerCase();
        let pro = 'price_1PVz5zC4JepTyKEwx9X7XN0S'
        let basic = 'price_1PVz3RC4JepTyKEwHrhx8gnE'
        let standard = 'price_1PVz57C4JepTyKEwuR4ocbrq'

        if(plan === 'pro') {
            plan = pro;
        } else if (plan === 'basic') {
            plan = basic
        } else {
            plan = standard
        }

        try {
          const docRef = await addDoc(collection(doc(collection(db, "customers"), useruid), "checkout_sessions"), {
            price: plan,
            success_url: 'https://www.desotodealsforyou.com/dashboard', 
            cancel_url: 'https://www.desotodealsforyou.com/subscription', 
            trial_period_days: 30,
            });

            // Wait for the CheckoutSession to get attached by the extension
            onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
              stripeLoading.value = false;
              Swal.fire({
                    icon: 'info',
                    title: "Stripe checkout Failed. Redirecting to Plan Page.",
                        showClass: {
                          popup: `
                            animate__animated
                            animate__fadeInUp
                            animate__faster
                          `
                        },
                        hideClass: {
                          popup: `
                            animate__animated
                            animate__fadeOutDown
                            animate__faster
                          `
                        }
                });
          router.push('/subscription')
            }
            if (url) {
                stripeLoading.value = false;
                window.location.assign(url);
            }
            });
        } catch (error) {
          stripeLoading.value = false;
                  Swal.fire({
                    icon: 'info',
                    title: "Stripe checkout Failed. Redirecting to Plan Page.",
                        showClass: {
                          popup: `
                            animate__animated
                            animate__fadeInUp
                            animate__faster
                          `
                        },
                        hideClass: {
                          popup: `
                            animate__animated
                            animate__fadeOutDown
                            animate__faster
                          `
                        }
                });
          router.push('/subscription')
        }
        }

const register = async () => {
  loading.value = true;
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
    const user = userCredential.user;
    const uid = user.uid;
    await addExtraDocument(uid)
    await addDocument(uid)
    .then(() => {
      registerCompleted.value = true;
      loading.value = false;
      stripeLoading.value = true
      let plan = enrollmentPlan.value
      loadSubscriptionPlan(plan, uid)
    })
  } catch (error) {
    loading.value = false;
    toast("Registration Failed.", {
              "type": "error",
              "transition": "bounce",
              autoClose: 2000
            })
  }
};

onMounted(() => {
  // alert(enrollmentPlan.value)

  window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'  // Optional: smooth scrolling behavior
    });
})

</script>

<style scoped>

body {
  margin: 0px;
  padding: 0px;
  background: rgb(255, 255, 255);
}

.blur {
  filter:blur(5px);
}

.loadingHolder {
    inset: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stripeContainer {
    width:350px;
    height:500px;
    background: ghostwhite;
    box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
    border-radius: 15px;
    position: relative;
    opacity: 0;
    animation: fade-in 200ms linear forwards;
  }

  @keyframes fade-in {
      from {
        opacity: 0;
      } to {
        opacity: 1;
      }
  }

  .stripeContainer img {
    position: absolute;
    top:20%;
    left:50%;
    transform: translate(-50%, -50%);
  }

  .sub-plan {
    height:150px;
    width:250px;
    border-left:1px solid rgb(34, 34, 34);
    position: absolute;
    top:60%;
    left:55%;
    transform: translate(-50%, -50%);
    position:relative;
  }

  .account-created {
    position: absolute;
    left:-8%;
    top:10%;
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-weight: 900;
    background: rgb(72, 255, 16);
    display: grid;
    place-content: center;
    height:40px;
    width:40px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  

  .account-created-text{
    position: absolute;
    top:23%;
    left:40%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem;
    color: #202020;
  }

  .redirecting {
    position: absolute;
    left:-8%;
    top:60%;
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-weight: 900;
    background: rgb(69, 151, 245);
    display: grid;
    place-content: center;
    height:40px;
    width:40px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .redirect-text {
    position: absolute;
    top:73%;
    left:41%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    color: #202020;
  }

    .stripe-loader-container {
      position: absolute;
      top:80%;
      left:48%;
      transform: translate(-50%, -50%);
    }

    .loader {
      width: 50px;
      aspect-ratio: 1;
      display: grid;
      border: 4px solid #0000;
      border-radius: 50%;
      border-right-color: #000000;
      animation: l15 1s infinite linear;
      opacity: 0.1;
    }
    .loader::before,
    .loader::after {    
      content: "";
      grid-area: 1/1;
      margin: 2px;
      border: inherit;
      border-radius: 50%;
      animation: l15 2s infinite;
    }
    .loader::after {
      margin: 8px;
      animation-duration: 3s;
    }
    @keyframes l15{ 
      100%{transform: rotate(1turn)}
    }



  .custom-loader {
      width:40px;
      height:40px;
      border-radius:50%;
      background:conic-gradient(#0000 10%,#FFFFFF);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 0.5s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}

.lottieContainer {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1  {
  padding:50px;
  font-weight: 500;
  text-align: center;
  color: navy;
}

.currentlyLoading {
  opacity: 0.5;
  filter: brightness(0.5);
  }

.loaderIcon {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.registration-form-one {
 border-radius: 8px;
  margin-top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width:500px;
  max-width: 500px;
  animation: left 300ms;
}

.registration-form-two {
  margin-top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width:500px;
  max-width: 500px;
  animation: left 300ms;
}

.registration-form-three {
  margin-top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width:500px;
  max-width: 500px;
  animation: left 300ms;
}

@keyframes left {
    from {
      transform: translateX(-500px);
    }
    to {
      transform:translateX(0px);
    }
  }

.custom-select {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
  appearance: none;
  -webkit-appearance: none;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}



@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}



label {
  display: block;
  text-align: left;
  padding-bottom: 15px;
  margin-left: 5px;
  color: navy;
}

input[type="text"],
input[type="email"],
input[type="password"] {
       width: 90%;
        padding: 15px;
        border:none;
        outline: none;
        border-bottom: 2px solid #d4d4d4;
        box-sizing: border-box;
        background-color: #f7f7f7;
}





button {
  background-color: rgb(89, 73, 194); 
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: 500ms ease;
}

button:hover {
  transform: scale(0.95);
}

.inputHolder {
        display: flex;
        flex-direction: row;
      }

      .container .form-group .inputHolder .fa {
        color: white;
        padding:16px;
        background-color: #d4d4d4;
      }



      
  @media(max-width: 700px) {
        .registration-form-one, .registration-form-two, .registration-form-three {
        background-color: transparent;
        box-shadow: 0px 0px;

        }
        h1 {
          padding: 0px;
          text-align: left;
          font-weight: 600;
          color: navy;
        }
        .container {
          margin-top: -20px;
        }

        hr {
          margin:15px;
          margin-left: 2px;
        }

      }

      .legal {
  font-size: 0.7rem;
  margin-top: 15px;
  text-align: center;
}

#co-description {
  font-size: 0.7rem;
}

</style>
