import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { db } from '../../firebase'
import { collection, getDocs } from "firebase/firestore"; 
import HomeView from '../views/HomeView.vue'
import RegisterView from '@/views/RegisterView.vue'
import LoginView from '@/views/LoginView.vue'
import SuccessView from '@/views/SuccessView.vue'
import DashView from '@/views/DashView.vue'
import AboutView  from '../views/AboutView.vue'
import UserRegister from '../views/UserRegisterView.vue'
import TestView from '../views/TestView.vue';
import AdminView from '../views/AdminView.vue';
import FavoritesView from '@/views/FavoritesView.vue';
import SearchView from '@/views/SearchView.vue';
import CategoryView from '@/views/CategoryView.vue';
import CouponView from '@/views/CouponView.vue';
import 'vue3-toastify/dist/index.css';




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/test',
    name:'test',
    component: TestView
  },
  {
    path:'/favorites',
    name:'favorites',
    component: FavoritesView,
    meta: {
      requiresAuth:true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path:'/dashboard',
    name: 'dashboard',
    component:DashView,
    meta: {
      requiresBusinessRole: true,
      requiresAuth: true,
      hideFooter: true
    }
  },
  {
    path:'/adminDash',
    name: 'adminDash',
    component:AdminView,
    meta: {
      requiresAdminRole: true,
      requiresAuth: true
    }
  },
  {
    path: '/userRegister',
    name:'userRegister',
    component: UserRegister,
    meta: {
      guestOnly: true
    }
  },
  {
    path: '/register/:plan',
    name: 'register',
    component: RegisterView,
    meta: {
      guestOnly: true
    }
  },
  {
    path: '/login',
    name:'login',
    component: LoginView,
    meta: {
      guestOnly: true}
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView,
    meta: {
      requiresAuth: true}
  },
  {
    path:'/restricted',
    name: 'restricted',
    component: () => import('../views/UnauthView.vue')
  },
  {
    path:'/companySearch/:companyName',
    name:'CompanySearch',
    component: SearchView,
  },
  {
    path:'/category/:categoryName',
    name:'categorySearch',
    component: CategoryView
  },
  {
    path:'/coupons',
    name:'coupons',
    component: CouponView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path:'/advertise',
    name:'advertise',
    component: () => import('../views/AdvertiseView.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path:'/subscription',
    name:'subscription',
    component: () => import('../views/SubscriptionView.vue'),
    meta: {
      requiresBusinessRole: true,
      requiresAuth: true,
      
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

let userType

const getUserRole = async () => {
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  const querySnapshot = await getDocs(collection(db, `Users/${uid}/Docs`));
  querySnapshot.forEach((doc) => {
   const data = doc.data();
  userType = data.userType
 });
}



const checkAuthStatus = () => {
  return new Promise((resolve) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      resolve(!!user); // Resolve with a boolean indicating authentication status
    });
  });
}



router.beforeEach(async (to, from, next) => {
  const authenticatedUser = await checkAuthStatus();

  
  // If the route requires authentication and the user is not authenticated, redirect to login
  if (to.meta.requiresAuth && !authenticatedUser) {
    next('/login');
  } else if (to.meta.guestOnly && authenticatedUser) {
    // If the route is for guest only and the user is authenticated, prevent navigation
    next(false);
  } else if (to.meta.requiresBusinessRole) {
    // If the route requires a business role
    if (!authenticatedUser) {
      // If user is not authenticated, redirect to login
      next('/login');
    }
    
    else {
      // Check if userType is 'Company'
      await getUserRole();
      if (userType !== 'Company') {
        // If userType is not 'Company', redirect to home or some other route
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Access Denied',
        //   text: 'You do not have permission to access this page'
        // });
            next('/')
      } else {
        // If userType is 'Company', allow access to the route
        next();
      }
    }
  }
  else if (to.meta.requiresAdminRole) {
    // If the route requires a business role
    if (!authenticatedUser) {
      // If user is not authenticated, redirect to login
      next('/login');
    }
    
    else {
      // Check if userType is 'Company'
      await getUserRole();
      if (userType !== 'Admin') {
        // If userType is not 'Company', redirect to home or some other route
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Access Denied',
        //   text: 'You do not have permission to access this page'
        // });
        next('/restricted');
      } else {
        // If userType is 'Company', allow access to the route
        next();
      }
    }
  }
  
  else {
    // For all other cases, allow navigation
    next();
  }
});



export default router
