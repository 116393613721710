<template>

    <h1>Hello from test!</h1>

</template>

<style>

</style>

<script setup>

import {onMounted} from 'vue';
import axios from 'axios'

onMounted(() => {
   const address = '5770 lees xing'

   axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyChlmPj8_vsGHbVHzzHhBF1fimdALRPcsY`)
   .then((response) => {
    console.log(response.data.status)
    if(response.data.status == 'ZERO_RESULTS') {
        alert('No results!')
        return;
    }
   }).catch((error) => {
    console.log(error);
   })
})
</script>