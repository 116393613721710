import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vue3Lottie from 'vue3-lottie'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faPaperclip, faCloudArrowUp, faLocationPin, faPhone, faXmark, faUser, faTag, 
    faTicket, faRibbon, faIdCard, faEnvelope, faLock, faGlobe, faArrowLeftLong, faClock, faX,
     faMobileScreenButton, faLocationDot, faBars, faArrowRight, faMagnifyingGlass,
        faPalette, faFire, faHeart, faTrash, faEllipsisV, faArrowDown, faBarsStaggered, faPaperPlane,
         faPlus, faGear, faGauge, faArrowRightFromBracket, faChevronDown, faUserPlus, faChartSimple,
         faPencil, faBullhorn, faHouse, faPen, faFacebook, faInstagram, faComment, faListUl, faRocket, faPlane, 
         faDollarSign, faMessage, faStar, faImage, fa1, fa2, fa3, faRobot,faTriangleExclamation, faBell, faCircleInfo, faInfinity, faHeadphones, faDiamondTurnRight,
         faCalendarCheck, faAnglesUp, faFootball, faCar, faCamera, faChevronRight, faPenFancy,
         faCalendarDays,  faLightbulb, faUsers, faComputerMouse, faEye, faPallet, faPersonWalking, faUtensils, faFaceSmileBeam
    } from "@fortawesome/free-solid-svg-icons";


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vueDebounce from 'vue-debounce'
import VueGtag from "vue-gtag";
import VueApexCharts from "vue3-apexcharts";
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';




library.add(faCheck, faPaperclip, faCloudArrowUp, faLocationPin, faPhone, faXmark, faUser, faTag, 
    faTicket, faRibbon, faIdCard, faEnvelope, faLock, faGlobe, faArrowLeftLong, faClock, faX, 
    faMobileScreenButton, faLocationDot, faHeart, faBars, faArrowRight, faMagnifyingGlass,  faPalette,
     faFire, faTrash, faEllipsisV, faArrowDown, faBarsStaggered,faPaperPlane, faPersonWalking, faFootball, faPenFancy,
    faPlus,faGear, faGauge, faArrowRightFromBracket, faChevronDown, faUserPlus, faChartSimple, faPallet, faChevronRight,
    faPencil, faBullhorn, faHouse, faPen, faComment, faListUl, faRocket, faPlane, faDollarSign, faUtensils, faFaceSmileBeam, faCar, faCamera,
     faMessage, faStar, faImage, fa1, fa2, fa3, faRobot, faTriangleExclamation, faBell, faCircleInfo, faInfinity, faHeadphones, faDiamondTurnRight, faCalendarCheck, faAnglesUp, faCalendarDays,  faLightbulb, faUsers, faComputerMouse, faEye);


createApp(App)
.use(router)
.use(Vue3Lottie)
// .use(VueGtag, {
//   config: { id: "G-YYW7LN56BD" }
// })
.use(VueGtag, {
  config: {
    id: "G-YYW7LN56BD",
    params: {
      anonymize_ip: true,
      cookie_flags: 'SameSite=None;Secure'
    }
  }
})
.use(VueApexCharts)
.use(SimpleTypeahead)
.component("font-awesome-icon", FontAwesomeIcon)
.component('VueDatePicker', VueDatePicker)
.directive('debounce', vueDebounce({ lock: true }))
.mount('#app')


