<template>
    <body>
        
      <div class="headerMain">
        <div class="headerContainer">
          <p style=" margin-block: 20px; font-size: 0.8rem; color:gainsboro;"> <span style="cursor: pointer; color:gainsboro;" @click="goHome"><font-awesome-icon  icon="house" class="chevron"  /></span>  <font-awesome-icon  icon="chevron-right" class="chevron"  /> {{ auth.currentUser.email }} <font-awesome-icon  icon="chevron-right" class="chevron" /><span style="color: slategray;"> Coupons </span></p>
          <h1  style=" color:navy; padding-block: 5px;">Coupons </h1>
          <p style="font-size: 0.8rem; color:slategray; max-width:500px;">Redeem your coupons and save!</p>
       
            <div style="height:1px; background: gainsboro; width: 100%; margin-block: 25px;"></div>
          </div>
      </div>

    <div v-if="CouponPosts" class="promotionalPosts" id="promotions" :class="{'opacity' : loading}">
        
        <div class="promoPost" v-for="post in CouponPosts" :class="{'activeCoupon' : post.couponRedeemed == true && post.couponRedemptionExpired == false}">
          <img :src="post.imageUrl" alt="company Image" class="companyImg">
          <div id="timer" :title="post.expiration" :style="{ backgroundColor : checkExpire(post.expiration, post.id) ? 'rgb(212, 62, 62)' : 'gainsboro'}" >
            <font-awesome-icon  icon="clock" style="margin-right:5px;" />{{ getExpireTime(post.expiration) }}
            </div>  

          <div class="companyInfo">
            <h4>{{ post.business }}</h4>
            <p class="address" @click="openMap(post.address)" style="font-size: 12px; margin-bottom: 5px; margin-top: 5px; cursor: pointer;"><font-awesome-icon icon="location-pin" class="fa" style="margin-right:10px; color: gainsboro;" />{{ post.address }}</p>
            <a :href="'tel:' +1+post.phone"><p class="phoneNumber" style="font-size: 12px; margin-bottom:5px;"><font-awesome-icon icon="phone" class="fa" style="margin-right:10px; color: gainsboro;" />{{ formattedPhoneNumber(post.phone) }}</p>
            </a>
            <a v-show="post.website !== ''" :href="post.website" target="_blank" ><p class="phoneNumber" style="font-size: 12px;"><font-awesome-icon icon="globe" class="fa" style="margin-right:10px; color: gainsboro;" />{{ post.website.substring(0,45) }}</p>
            </a>
          </div>
          <hr style="width: 75%; margin: 0 auto;">

  
          <div style="white-space: wrap;">
            <h3 style="text-align: center; margin-top: 5px;">{{ post.title }}</h3>
          <p v-if="!post.couponRedemptionExpired" style="font-size: 12px; padding:10px; text-align: center;">{{ post.description }}</p>
     
          </div>
          
          <button v-if="post.couponRedeemed == false" class="couponButton" @click="redeemCoupon(post)">Use Coupon <font-awesome-icon icon="check" style="margin-left: 10px;"  /></button>
          <div v-if="post.couponRedeemed == true && post.couponRedemptionExpired == false">
            <p style="text-align: center;"><span class="couponRedeemed">Coupon Active</span> <img src="../assets/checkmark_green.png" height="25" width="25"  alt="green checkmark" style="margin-left: 10px;"></p>
            <p class="timeRemaining">Valid Until {{ timeRemaining(post) }}</p>
          </div>
          <div v-if="post.couponRedemptionExpired == true">
          <p style="text-align: center; font-size: 0.7rem; margin-top: 20px;">Coupon Expired {{ post.couponRedemptionDateExpired }}</p>
          <Vue3Lottie :animationData="expireAnimation" :height="100" :width="100" loop="2" />
          
          
        </div>

        <div @click="removeCoupon(post)" v-if="post.couponRedeemed === false" class="testDiv" title="Delete">
            <font-awesome-icon icon="trash" class="fa favIcon"  />
          </div>

        </div>
        <div class="lastElement">
           <p style="font-weight: bold; font-size: 1.5rem; margin:100px auto;"></p>
          </div>

   </div>
   <div v-if="noPostsFound">
    <Vue3Lottie :animationData="nothingFoundAnimation" :height="250" :width="250" loop="1" />
   </div>


   <div id="loader-container" v-show="loading">
    <div id="loader-bar"></div>
  </div>
        
    </body>
</template>

<script setup>
import { onMounted, ref  } from 'vue';
import { db } from '../../firebase';
import { collection, getDocs, doc, deleteDoc, orderBy, startAfter, limit, query, updateDoc, where, increment } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Swal from 'sweetalert2';
import 'animate.css';
import nothingFoundAnimation from '../assets/animations/nothing_found_lottie_animation.json';
import couponAnimation from '../assets/animations/happy_face_animation.json';
import expireAnimation from '../assets/animations/lottie_expire_animation.json';
import { pageview } from 'vue-gtag'

const CouponPosts = ref([])
const loading = ref(false)
const noPostsFound = ref(false);
const lastVisible = ref('')
const postReady = ref(false);
const individual = ref(true);
const userFilter = ref(false);
const userFilterSelection = ref('');
const userName = ref('');
const couponModal = ref(false)
const couponObject = ref(null);
const options = ref(['All', 'Promotion', 'Deal', 'Coupon']);
let cssShowClass = {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          }
let cssHideClass = {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }

const auth = getAuth()
const uid = auth.currentUser.uid

const timeRemaining = (post) => {
 const today = new Date();
 const expirationTime = new Date(post.couponRedemptionDateExpired)
 return post.couponRedemptionDateExpired
}

const userRedeemedCoupon = async () => {
  loading.value = true;
  couponModal.value = false;
      try {
        const currentDateAndTime = new Date();
        const hoursString = couponObject.value.couponRedemptionTime.split(' ')[0]; // Extract numeric part from "2 hours"
        const hours = parseInt(hoursString); // Convert the numeric part to an integer
        const expiration = new Date(currentDateAndTime.getTime() + hours * 3600000); 
        const docRef = doc(db, 'Coupons', couponObject.value.id)
        updateDoc(docRef, {
          couponRedeemed: true,
          couponRedemptionDate: currentDateAndTime.toLocaleString(),
          couponRedemptionDateExpired: expiration.toLocaleString(),
          couponRedemptionExpired: false
        }).then(() => {
          loading.value = false
          toast("Coupon Redeemed", {
                "type" : "success",
                "transition": "flip",
                autoClose: 1000
                })
          getCoupons();
        })
       } catch (error) {
        toast("Something went wrong, try again.", {
                "type" : "error",
                "transition": "bounce",
                autoClose: 2000
                })
        loading.value = false
        console.log(error.message)
       }
}


async function updateRedeemedCoupons({postId}) {

    try {
 
      const q = query(collection(db, 'RedeemedCoupons'), where('postId', '==', postId));


      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (documentSnapshot) => {
          const docRef = doc(db, 'RedeemedCoupons', documentSnapshot.id);
          await updateDoc(docRef, {
           redemptions: increment(1)
          });

          console.log('Coupon redemption count successfully updated!');
        });
      } else {
        console.log('No matching documents.');
      }
    } catch (error) {
      console.error('Error querying or updating documents: ', error);
    }
  }

const redeemCoupon = async (post) => {
  // couponModal.value = true;

  couponObject.value = post;
  Swal.fire({
  title: `Redeem ${post.title}`,
  text: `You have ${post.couponRedemptionTime} to use this coupon!`,
  showCancelButton: true,
  confirmButtonText: "Redeem",
  showClass: cssShowClass,
  hideClass: cssHideClass,
}).then(async (result) => {
  if (result.isConfirmed) {
    await userRedeemedCoupon()
    await updateRedeemedCoupons(post)
  } else if (result.isDenied) {
  }
});
}


const getExpireTime = (expireTime) => {
    const expireDate = new Date(expireTime);
    const today = new Date();

    if (expireDate < today) {
        return 'Expired';
    }

    const timeLeft = expireDate - today;
    const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

    if (hoursLeft < 24) {
        if (hoursLeft < 1) {
            return `Expires in ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
        } else {
            if (hoursLeft < 2) {
                return `Expires in ${hoursLeft} hour ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            } else {
                return `Expires in ${hoursLeft} hours ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''}`;
            }
        }
    } else {
        return `Expires: ${expireTime}`;
    }
};

const deletePost = async  (post) => {
 const docRef = doc(db, 'Favorites', post)
 await deleteDoc(docRef);
}

const checkExpire = (time, postId) => {
 
  const expireDate = new Date(time);
  const today = new Date();
  if(today > expireDate) {
    let difference = today - expireDate
    const hoursLeft = Math.floor(difference/ (1000 * 60 * 60));
   if(hoursLeft > 72) {
    const days = hoursLeft / 24
    if(days > 3) {
      deletePost(postId);
   }
  }
  if(expireDate < today) {
    return true;
  } else return false;
}
}

const openMap = (addy) => {
  window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addy)}`);
}

const removeCoupon = async (post) => {

  Swal.fire({
  text: `Remove ${post.title}`,
  showCancelButton: true,
  confirmButtonText: "Delete Coupon",
  showClass: cssShowClass,
  hideClass: cssHideClass,
}).then(async (result) => {
  if (result.isConfirmed) {
    await deleteDoc(doc(db, 'Coupons', post.id))
        .then(() => {
            const updatedCoupons = CouponPosts.value.filter(item => item.id !== post.id)
            CouponPosts.value = updatedCoupons;
            toast("Successfully Deleted.", {
                "type" : "success",
                "transition": "flip",
                autoClose: 2000
                })
        })
        .catch((e) => {
            toast("Something went wrong, try again.", {
                    "type": "error",
                    "transition": "flip",
                    autoClose: 3000
                    })
        })
  } else if (result.isDenied) {
  }
});
}
 

const formattedPhoneNumber = (phone) => {
  const areaCode = phone.substring(0, 3);
  const firstPart = phone.substring(3, 6);
  const secondPart = phone.substring(6, 10);
  return `(${areaCode}) ${firstPart}-${secondPart}`;
};

const getMoreCoupons = async () => {
  if(!postReady || !lastVisible.value) {
    return;
  }

  try {

    const CouponsQuery = query(collection(db, 'Coupons'), 
                                    where('useruid', '==', uid),
                                    where('hidden', '==', false),
                                    orderBy('business'), 
                                    startAfter(lastVisible.value),
                                    limit(5));
        const querySnapshot = await getDocs(CouponsQuery);
        
        const emptyArray = [];
        querySnapshot.forEach(doc => {
            emptyArray.push({ ...doc.data(), id: doc.id });
        });
        
        
        if (emptyArray.length > 0) {
            lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
            CouponPosts.value = [...CouponPosts.value, ...emptyArray]
        } 
        
        if(emptyArray.length < 5) {
          lastVisible.value = null;
        }
    
  } catch (error) {
    console.log(error.message)
  }
  
}

const getCoupons = async () => {
    try {
      const CouponsQuery = query(collection(db, 'Coupons'), 
                                  where('useruid', '==', uid),
                                  where('hidden', '==', false),
                                    orderBy('business'), 
                                    limit(5));
        const querySnapshot = await getDocs(CouponsQuery);
        
        const emptyArray = [];
        querySnapshot.forEach(doc => {
            emptyArray.push({ ...doc.data(), id: doc.id });
        });
        CouponPosts.value = emptyArray;
        
        if (emptyArray.length > 0) {
            lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        } 
        
        if(emptyArray.length < 5) {
          lastVisible.value = null;
        }
        
        if(emptyArray.length == 0) {
          noPostsFound.value = true;
            toast("No posts found.", {
                "type": "info",
                "transition": "zoom",
                autoClose: 1000
            });
        }
    } catch (e) {
        console.error(e.message);
        // Handle error appropriately
    }
};

const getUserName = async () => {
  const querySnapshot = await getDocs(collection(db, `Users/${uid}/Docs`));
  querySnapshot.forEach((doc) => {
   const data = doc.data();
   
    if(data.userType == 'Individual') {
      let name = data.name + "'s"
      userName.value = name;
      individual.value = true
    } else individual.value = false;
  
 });
}

const checkCouponRedeemedAndExpired = async () => {
  const today = new Date()
  const CouponsQuery = query(collection(db, 'Coupons'), 
                                    where('useruid', '==', uid),
                                    where('couponRedemptionExpired', '==' , true));
        const querySnapshot = await getDocs(CouponsQuery);
        
        const emptyArray = [];
        querySnapshot.forEach(doc => {
            emptyArray.push({ ...doc.data(), id: doc.id });
        });
        emptyArray.map(async (coupon) => {
          const expiration = new Date(coupon.expiration)
          if(today > expiration) {
            await deleteDoc(doc(db, 'Coupons', coupon.id))
          } else {
            const docRef = doc(db, 'Coupons', coupon.id)
            updateDoc(docRef, {
              hidden:true,
            })
          }
        })
}

const checkCoupons = async () => {
 const anyRedeemedCoupons =  CouponPosts.value.filter((item) => item.couponRedeemed == true && item.couponRedemptionExpired == false)
 if(anyRedeemedCoupons.length == 0) {
  console.log('Nothing to check!')
  return;
 }
 if(anyRedeemedCoupons.length > 0) {
  console.log('length is great than 0, so we are checking database!')

    const now = new Date()
    CouponPosts.value.forEach((coupon) => {

      const expirationDate = new Date(coupon.couponRedemptionDateExpired)
      if(now > expirationDate) {
        try {
          const docRef = doc(db, 'Coupons', coupon.id)
        updateDoc(docRef, {
          couponRedemptionExpired: true
        })
       
        } catch (error) {
          console.log(error.message)
        }
      }
    })
    await getCoupons();

 } else return;

    }



onMounted(async () => {
  pageview({ page_path: '/Coupons' })

  const lastDiv = document.querySelectorAll('.lastElement')
    const observer = new IntersectionObserver((element) => {
      element.forEach((item) => {
        if(item.isIntersecting) {
         getMoreCoupons()
        }
      })
    }, {
      rootMargin: '0px 50px 0px 50px',
      threshold: 0
    })
    lastDiv.forEach((element) => observer.observe(element))
    loading.value = true;
    await getUserName()
    await getCoupons()
    await checkCoupons()
    await checkCouponRedeemedAndExpired()
    .finally(() => {
        loading.value = false;
        postReady.value = true;
    })

   

    // checkCoupons()

    //  interval = setInterval(checkCoupons, 30000)
 
    })

    // onUnmounted(() => {
    //   clearInterval(interval)
  
    // })

</script>

<style scoped>

* {
  color: navy;
}

h1 {
    text-align: left;
    color: navy;
    font-size: 3.5rem;
    font-weight: 900;
}


.fa{
    color: rgb(212, 62, 62);
    margin-left: 5px;
}

#loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust the height as needed */
  background-color: white;
  overflow: hidden;
  z-index: 1000; /* Ensure it's above other content */
}

#loader-bar {
  position: absolute;
  top: 0;
  left: -200px; /* Start the bar off-screen */
  width: 200px;
  border-radius: 15px;
  height: 5px; /* Same as loader container height */
  background-color: #11ff09; /* Bar color */
  animation: slide 750ms linear infinite;
}

@keyframes slide {
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
}

.promotionalPosts {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping */
  display: flex;
  flex-direction: row;
  margin: 25px auto;
}

.promoPost {
  min-height:550px;
  min-width: 350px;
  max-height: 550px;
  max-width: 350px;
  background-color: rgb(255, 255, 255);
  margin:25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
 
}

.promotionalPosts .promoPost .companyImg {
  height:200px;
  width:100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid gainsboro;
}

.promotionalPosts .promoPost .testDiv {
  position: absolute;
  height:40px;
  width:30px;
  border-radius: 5px;
  bottom: 5px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 400ms ease-in-out;
}



.promotionalPosts .promoPost .testDiv .favIcon {
  color: rgb(226,226,226);
  cursor: pointer;
  font-size: 20px;
  margin-right: 4px;
  transition: 400ms ease-in-out;

}

.promotionalPosts .promoPost .testDiv .favIcon:hover {
  color: rgb(199,43,43);
}

.expired {
  color:rgb(189, 189, 189);
}



.promotionalPosts .promoPost  #timer {
  padding:5px;
                /* background-color: rgb(50, 112, 228); */
                background:rgb(89, 73, 194); 
                border-radius: 5px;
                min-width: 250px;
                margin: 0px auto;
                color: rgb(39, 39, 39);
                text-align: center;
                position: absolute;
                top:183px;
                left:50%;
                transform: translate(-50%, 0%);
                font-size: 0.8rem;
                font-weight: 500;
                box-shadow:  0 0 2px rgba(0, 0, 0, 0.3);
           
}

.promotionalPosts .promoPost .companyInfo {
  margin-top: 20px;
  text-align: left;
  padding:20px;
}



.promotionalPosts .promoPost .promoText {
  margin: 15px auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.promotionalPosts .promoPost .couponButton {
  outline: none;
      color: hsl(162, 54%, 53%);
      background: transparent;
      border: 1px solid hsl(162, 54%, 53%);
      width: 150px;
      padding-inline: 20px;
      padding-block: 7px;
      text-align: center;
      font-weight: 700;
      border-radius: 2px;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
      cursor: pointer;
      transition: 400ms ease;
}

.promotionalPosts .promoPost .couponButton:hover {
  color: white;
  background: hsl(162, 54%, 53%);
}

      .loaderContainer {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        display: grid;
        place-content: center;
      }

      .custom-loader {
      width:40px;
      height:40px;
      border-radius:50%;
      background:conic-gradient(#0000 10%,#FFFFFF);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 0.5s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}

.opacity {
opacity: 0.5;
filter: brightness(0.5);
}

.couponRedeemed {
  font-weight: 700;
  font-size: 1rem;
  animation: customAni 3s ease 0s infinite normal none;
}

.timeRemaining {
  text-align: center;
  font-size: 0.75rem;
}

@keyframes customAni {
  0% {
	opacity: 1;
  }

  50% {
	opacity: 0.2;
  }

  100% {
	opacity: 1;
  }
}



.couponModalContainer {
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  background-color: rgba(0,0,0,0.5);
}


.couponModal {
  position: relative;
  height:400px;
  width:400px;
  background: ghostwhite;
  border-radius: 25px;
  padding:25px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
  animation-name: fade-in;
  animation-duration: 500ms;
  box-shadow: 0 0 25px rgba(0,0,0,0.5);
}

.activeCoupon {
  border-bottom:5px solid limegreen;
  border-left:1px solid limegreen;
  border-right: 1px solid limegreen;
  border-top: 1px solid limegreen;

}

.couponModal p {
  margin-top: -25px;
  padding-bottom: 50px;
}

.couponModal .couponButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:50px;
}

.couponModal .couponButtons button:nth-child(1)
{
  background-color: #135ec0;
  padding-inline: 25px;
  padding-block: 10px;
  border-radius: 5px;
  color:white;
  outline:none;
  border:none;
  font-size: 1rem;
  width:150px;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  cursor: pointer;
  transition: 200ms ease-in-out;

}

.couponModal .couponButtons button:nth-child(2)
{
  background-color: #7c0e0e;
  padding-inline: 25px;
  padding-block: 10px;
  border-radius: 5px;
  width:150px;
  color:white;
  outline:none;
  border:none;
  font-size: 1rem;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  cursor:pointer;
  transition: 200ms ease-in-out;
}

.couponModal .couponButtons button:nth-child(2):hover {
  background-color: #b11414;
}

.couponModal .couponButtons button:nth-child(1):hover {
  background-color: #1670e6;
}

.headerContainer {
              padding-block: 25px; 
               padding-inline:28px; 
               background:white;
               max-width:1000px;
            }

            .filter {
              display: flex;
              flex-direction: row
            }

            .filter button{
              padding-inline: 30px;
              padding-block: 10px;
              border-radius: 5px;
              background:white;
              outline: 1px solid gainsboro;
              border: none;
              cursor: pointer;
              transition: 200ms ease-in-out;
            }

            .filter button:hover {
              outline: 1px solid navy;
            }

            .headerContainer p {
              color: rgb(32, 32, 32);
            }


            .headerMain {
                padding:20px 20px;
                width: 100%;
                background:white;
            }
            

            @media(max-width:500px) {
              h1 {
                font-size: 1.75rem;
              }
            }

            .chevron {
              margin-inline:5px;
              color: gainsboro;
            }

</style>