
<template>
  <!-------------------------------------------Nav Header ------------------------------------------------------------->
  
  <div class="mainHeader" id="top-header" :class="{ 'body-opacity': isExpanded  }" v-if="!$route.meta.hideFooter" >
      
      <!-- <div  class="hamburgerIcon" style="z-index: 100;"   @click="toggleSidebar">  
        <font-awesome-icon icon="bars-staggered"  class="fa" />
        </div> -->
        <!-- <div  class="navLogo">
          
        </div> -->
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
          
        <router-link to="/">
          <font-awesome-icon icon="house"  class="fa headerIcon" style="padding:10px; margin-right:5px; color: white;" />
          </router-link>
        <span v-show="!userSignedIn" class="loginTxt underline-hover-effect" @click="loginUser">
          Sign In
        </span>
        <span v-show="userSignedIn" class="loginTxt underline-hover-effect" @click="logout">
          Sign Off
        </span>
        </div>
       <div class="iconHolders">
      
          <div  @click="checkCoupons" style="cursor: pointer;">
            <div style="position: relative;">
              <font-awesome-icon icon="ticket" id="coupon" class="fa headerIcon" style="padding:10px; margin-right:5px; color: gainsboro;" />
            <div class="tooltip">Coupons</div>
            </div>
            
          </div>
        <div style="cursor: pointer; margin-right: 10px; "  @click="goToFavorites">
          <div style="position: relative;">
            <font-awesome-icon icon="heart" id="favHeart" class="fa headerIcon" style="padding:10px; margin-right:5px; color: red; " />
            <div class="favtooltip">
              Favorites
            </div>
          </div>
          </div>
      
        
        <div v-if="!userSignedIn"  style="cursor: pointer;"  @click="showLoginBox = true">
              <font-awesome-icon  icon="user"  class="fa headerIcon" style="margin-right:5px; padding:10px; color: white; border:1px solid gray; border-radius: 50%; " />
              <div id="logInBox" v-if="showLoginBox" @mouseleave="showLoginBox = false">
                <img src="./assets/icons8-account-96.png" height="50" width="50" alt="user">
                <div style="height: 1px; background-color: gray; width: 75%; margin-left: 10px; margin-block: 7px;"></div>
  
                <p @click.stop="signUpAndClose" style="font-size: 0.75rem;"><font-awesome-icon  icon="user-plus"  class="fa" style="margin-right: 10px;" />Sign Up  </p>
                <p style="color:white;" @click.stop="loginAndClose" class="userBtn"><font-awesome-icon  icon="user"  class="fa" style="margin-right: 15px;" />Login </p>
  
                <div class="closeLogIn" @click.stop="showLoginBox = false"><font-awesome-icon  icon="x"  class="fa" /></div>
              </div>     
          </div>
  
  
       
        <div v-if="userSignedIn" id="userButton"  @click="showBox = true">
              <font-awesome-icon   icon="user"  class="fa faSignedIn" style="font-size: 1.2rem; color: white" /> 
              <div id="logOut" v-if="showBox" @mouseleave="showBox = false">
           
                  <img src="./assets/icons8-account-96.png" height="50" width="50" alt="user">
         
                <p>{{ userEmail }}</p>
                <div style="height: 1px; background-color: gray; width: 75%; margin-left: 10px;"></div>
                <p @click.stop="goHome" style="font-size: 0.75rem;"><font-awesome-icon icon="house"  class="fa" style="margin-right:10px;  " />Home</p>
                <p @click.stop="goToFavorites" style="font-size: 0.75rem;"><font-awesome-icon icon="heart"  class="fa" style="margin-right:10px; color: red; " />Favorites</p>
                <p @click.stop="checkCoupons" style="font-size: 0.75rem;"><font-awesome-icon icon="ticket"  class="fa" style="margin-right:10px;  " />Coupons</p>
                <div style="height: 1px; background-color: gray; width: 75%; margin-left: 10px;"></div>
                <p @click.stop="goToDash" v-if="userType == 'Company'" style="font-size: 0.75rem;"><font-awesome-icon  icon="chart-simple"  class="fa" style="margin-right: 15px;" />Dashboard </p>
                <p @click.stop="goToAdminDash" v-if="userType == 'Admin'" style="font-size: 0.75rem;"><font-awesome-icon  icon="chart-simple"  class="fa" style="margin-right: 15px; " />Admin Dashboard </p>
                <p @click.stop="goToSubscription" v-if="userType == 'Company'" style="font-size: 0.75rem;"><font-awesome-icon  icon="calendar-check"  class="fa" style="margin-right: 15px;" />Subscription </p>
                <p @click.stop="confirmedDeleteAccount" v-if="userType == 'Individual' || userType == 'Company'" style="font-size: 0.75rem; "><font-awesome-icon  icon="trash"  class="fa" style="margin-right: 15px;" />Remove Account </p>
                <p style="color:white;" @click.stop="signOutAndClose" class="userBtn"><font-awesome-icon  icon="arrow-right"  class="fa" style="margin-right: 15px;" />Log Out  </p>
                <div class="closeLogOut" @click.stop="showBox = false"><font-awesome-icon  icon="x"  class="fa" /></div>
              </div>  
          </div>
     
         
        </div> 
    
    </div>
  <!-------------------------------------------Nav Header ------------------------------------------------------------->
  
  
  <!--------------------------------------Categories and Company List ------------------------------------------------->
  
     <div  v-if="!$route.meta.hideFooter" :class="{ 'body-opacity': isExpanded }" style=" background-color: rgb(89, 73, 194); display: flex; justify-content: center; align-items: center; flex-direction: column;">
          
      
      <div  style=" width: 100%; margin:0 auto; padding-bottom: 15px;" v-show='mobilePlatform'>
          <vue-horizontal  responsive snap="center" :displacement="0.8" :button="false" >
            <!-- <section class="catButtons" v-for="item in businessCategories" :key="item" :title="item" :class="{'active' : item === activeItem}"> -->
              <section class="catButtons" v-for="item in businessCategories" :key="item" :title="item" style="margin-inline: 15px;">

              <h5 @click="checkCategory(item)">{{ item }}</h5>
            </section>
          </vue-horizontal>
          </div>

          <!-- <div  style=" width: 92%; margin:0 auto;  padding-bottom: 15px;" v-show='!mobilePlatform'>
          <vue-horizontal  responsive snap="center" :displacement="0.8">
              <section class="catButtons" v-for="item in businessCategories" :key="item" :title="item" style="margin-inline: 15px;">
              <h5 @click="checkCategory(item)">{{ item }}</h5>
            </section>
          </vue-horizontal>
          </div> -->

          <div style="width: 92%; margin: 0 auto; padding-bottom: 15px;" v-show='!mobilePlatform'>
        <vue-horizontal responsive snap="center" :displacement="0.8" class="custom-scroll">
          <section class="catButtons" v-for="item in businessCategories" :key="item" :title="item" style="margin-inline: 15px;">
            <h5 @click="checkCategory(item)">{{ item }}</h5>
          </section>
        </vue-horizontal>
      </div>


         
         
      </div>

      <div style="width: 100%; display: flex; justify-content: center; align-items: center; position: relative; background-color: rgb(89, 73, 194); padding-bottom: 20px;" v-if="!$route.meta.hideFooter" >
  <div style="width: 95%;  position: relative;">
    <font-awesome-icon icon="magnifying-glass" class="fa" id="comp-search-btn" @click="keyup" style="position: absolute; left: 10px; top: 50%; transform: translateY(-50%);" />
    <font-awesome-icon icon="x" class="fa" id="comp-clear-btn" @click="clearInput" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);" v-if="userBusinessInput" />

    <vue3-simple-typeahead
      id="type-ahead-search"
      placeholder="Search Company"
      :items="companyList"
      :minInputLength="0"
      @selectItem="handleCompanySearch"
      @onInput="onInputEventHandler"
      @keyup.enter="keyup"
      ref="inputRef"
    >
    </vue3-simple-typeahead>
  </div>
</div>

  
  
     <body :class="{ 'body-opacity': isExpanded || signOutBool || userAccountBool || accountRemovalBool }" @click="closeSideBar">
      <router-view/>
  
  
  
     </body>
  
     <div class="userAccountModal" v-if="userAccountBool">
      <div @click="userAccountBool = false" class="closeAccount"><font-awesome-icon icon="x"/></div>
      <img src="./assets/icons8-account-96.png" alt="user">
      <p style="padding:25px; font-weight: 500;">{{ userEmail }}</p>
      <div style="display: flex; justify-content: space-between; flex-direction: row; align-items: center; border-bottom: 1px thin gray;">
        <div>
          <font-awesome-icon icon="lock" class="fa" style="margin-right: 15px;"/>
          <span>Update Password</span>
        </div>
      </div>
      <div>
        <div>
          <font-awesome-icon icon="trash" class="fa" style="margin-right: 15px;"/>
          <span>Remove Account</span>
        </div>
      </div>
      <!-- <button>  <font-awesome-icon icon="lock" class="fa" style="margin-right: 15px;"/>Update Password</button>
      <button>  <font-awesome-icon icon="trash" class="fa" style="margin-right: 15px;"/>Delete</button> -->
     </div>
  
     <div class="signOutAccountModal" v-if="signOutBool" @mouseenter="showBox = false">
        <Vue3Lottie :animationData="exitAnimation" :height="150" :width="150"  />
        <h1 style="padding:25px;">Log Out</h1>
        <div class="signOutButtonContainer">
        <button @click="logout">Submit</button>
        <button @click="signOutBool = false">Cancel</button>
        </div>
        </div>
  
        <div class="loginContainer" v-show="loginAccountBool">
       
          <form @submit.prevent="login" class="registration-form">
         <div class="closeFa" @click="loginAccountBool = false">
          <font-awesome-icon icon="x" class="fa" />
         </div>
         <p style="font-size: 2rem; padding-bottom: 25px; color: navy; text-align: left; font-weight: 700;">Login</p>
        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
      
      <!-- <div class="googleSignUp" @click="googleSignIn"> <img src="./assets/icons8-google-48.png" height="30" width="30" style="margin-right: 10px;" />Google</div>
      <div class="google">
                      <div class="line">
                          <div class="firstLine"></div>
                          <div class="or">OR</div>
                          <div class="secondLine"></div>
                      </div>
                  </div> -->
      </div>
        <div class="form-group">
          <label for="email">Email</label>
          <div class="inputHolder">
        
          <input type="email" id="email" v-model="email" required />
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
         <div class="inputHolder">
     
          <input type="password" id="password" v-model="password" required />
         </div>
         
          <div style="display: flex; justify-content: space-between;">
            <label class="forgotPasswordText" for="show-password-checkbox" >
            <input type="checkbox" id="show-password-checkbox" v-model="isChecked" @change="handleCheckBox">
            <span style="margin-left: 10px; color:#2c97df;">Show Password</span>
          </label>
          <p @click="forgotPassword" class="forgotPasswordText" style="">Forgot <span style="font-weight: 600;">Password</span></p>
          </div>
          <p style="text-align: left; font-weight: 400; margin-left: 10px; font-size: 12px; color: red" v-if="errorMsg"> {{ errorMsg }}</p>
        </div>
        <button type="submit" class="loginBtn"><span v-if="!loading">Login</span>
        <div class="customLoader" v-if="loading">
        </div>
        </button>
        <p @click="pushToRegister" style="text-align: center; margin-left: 10px; padding:20px; font-size: 13px;" class="registerText">Not a Member? <span style="color: #2c97df">Register</span></p>
      </form>
        </div>

        <button id="back-to-top-btn"><font-awesome-icon  icon="angles-up" class="fa" /></button>
  
        <!-- <FooterComponent /> -->
        <footer  v-if="!$route.meta.hideFooter" >
            <div id="divOne">
              <h2>Links </h2>
                <div class="linkContainer">
                  <p @click="advertise"><font-awesome-icon icon="bullhorn" class="fa" style="font-size: 1.2rem; padding-right: 15px;"/>Advertise With Us</p>
                  <p @click="home"><img src="./assets/icons8-home-30.png" alt="home">Home</p>
                  <p @click="goToFavorites"><img src="./assets/icons8-heart-48.png" alt="heart">Favorites</p>
                  <p @click="loginAndClose"><img src="./assets/icons8-login-50.png" alt="login">Login</p>
                  <p @click="signUpAndClose"><img src="./assets/icons8-create-50.png"  alt="register">Sign up</p>
                  <!-- <p @click="about"><img src="./assets/icons8-about-50.png" alt="about">About</p> -->
                </div>
            </div>
            <div id="divTwo">
              <h2>Business</h2>
               <div class="linkContainer">
                <p><font-awesome-icon icon="calendar-days"  class="fa" style="margin-right:15px;" />Monday - Friday 8am-5pm</p>
                <p><a href="tel:6624695894"><font-awesome-icon icon="phone"  class="fa" />(662) 469-5894</a></p>
                <p><a href="mailto:admin@desotodealsforyou.com"><font-awesome-icon icon="envelope"  class="fa" />Email</a></p>
               </div>
            </div>
          
          </footer> 
          <div class="socialContainer">
              <div>
               <a href="https://app.websitepolicies.com/policies/view/gp05z9pt" target="_blank">Terms & Conditions</a> | <a href="
            https://www.termsfeed.com/live/4e1dd77e-2058-40f2-be13-b1fc9b2e609d" target="_blank"> Privacy Policy</a>
              </div>
            </div>

  
  </template>
  
  
  
  <script setup>
  import { ref, onBeforeMount, onMounted} from 'vue'
  import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail,
     GoogleAuthProvider, signInWithPopup,  onAuthStateChanged, signOut,
      deleteUser, fetchSignInMethodsForEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'; // Import Firebase authentication method
  import { db } from '../firebase';
  import { collection, getDocs, query, where, doc, deleteDoc } from 'firebase/firestore';
  import { useRouter } from 'vue-router';
  import exitAnimation from './assets/animations/exit_lottie_animation.json';
  import { event } from 'vue-gtag'
  
  const router = useRouter();
  import Swal from 'sweetalert2';
  
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import VueHorizontal from 'vue-horizontal';
  
  const provider = new GoogleAuthProvider();
  
  
  const userSignedIn = ref(false)
  const isExpanded = ref(false);
  const signOutBool = ref(false);
  const activeItem = ref('')
  const userType = ref('')
  const companyList = ref([])
  const companyPosts = ref([])
  const userBusinessInput = ref('')
  const categoryArray = ref([]);
  const userName = ref('');
  const showBox = ref(false)
  const showLoginBox = ref(false);
  const loginAccountBool = ref(false);
  const loading = ref(false)
  const userAccountBool = ref(false)
  const accountRemovalBool = ref(false);
  const mobilePlatform = ref(false);
  const isChecked = ref(false);
  const inputRef = ref(null);
  
  const businessCategories = ref([
    'Animal Services',
    'Art',
    'Beauty',
    'Boutique',
    'Educational',
    'Entertainment',
    'Electronics',
    'Food & Beverage',
    'General',
    'Health & Fitness',
    'Hobbies & Recreation',
    'Home Services',
    'Hospitality',
    'Legal Services',
    'Marketing & Advertising',
    'Other',
    'Outdoors',
    'Professional Services',
    'Photo & Video',
    'Real Estate',
    'Recreation',
    'Retail',
    'Sports',
    'STEM',
    'Street Repair',
    'Tattoo',
    'Technology & IT',
    'Transportation & Logistics',
    'Vehicle Services',
    'Yard Services'
  ]);
  
  const userEmail = ref('')
  const email = ref('');
  const password = ref('');
  let errorMsg = ref('');
  
  const auth = getAuth();

  const clearInput = () => {
    userBusinessInput.value = ''
    inputRef.value.clearInput();

  }

  const handleCheckBox = () => {
    let passwordInput = document.getElementById('password');
    if(isChecked.value === true) {
      passwordInput.type = 'text'
    } else {
      passwordInput.type = 'password'
    }
  }

  const keyup = () => {
    if(userBusinessInput.value === '') {
        inputRef.value.focusInput();
            return;
          }
        const comp = userBusinessInput.value
        const lowerCaseCo = comp.toLowerCase();
        const companyObject = companyList.value.filter(item => item.toLowerCase() === lowerCaseCo);

        if (companyObject.length > 0) {
          router.push({ name: 'CompanySearch', params: { companyName: companyObject[0] } }); // Pass the first match with original case
        } else {
          toast(`${comp} not found, check spelling.`, {
            type: "info",
            transition: "flip",
            autoClose: 1000
          });
        }
  }

  const onInputEventHandler = (item) => {
    userBusinessInput.value = item.input;
  }

  const loginUser = () => {
    loginAccountBool.value = true;
  }
  
  const goToSubscription = () => {
    showBox.value = false;
    router.push('/subscription')
  }
  
  const deleteCoupons = async (post) => {
    try {
      const docRef = doc(db, 'Coupons', post);
      await deleteDoc(docRef);
    
    } catch (error) {
      
    }
  }
  
  
  const deleteFavorites = async (post) => {
    try {
      const docRef = doc(db, 'Favorites', post);
      await deleteDoc(docRef);
    
    } catch (error) {
      
    }
  }
  
  const getCoupons = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    try {
      const couponQuery = query(collection(db, 'Coupons'),
                                    where('useruid', '==', user.uid ));
      const querySnapshot = await getDocs(couponQuery);
   
      
      const couponArray = [];
    
      querySnapshot.forEach(doc => {
        couponArray.push({ ...doc.data(), id: doc.id });
      });
  
      if(couponArray.length > 0) {
        await Promise.all(couponArray.map(async (item) => {
        await deleteCoupons(item.id);
      }));
  
      } else {
        return;
      }
  
    
  
     
    } catch (error) {
      console.error('Failed to get or delete favorites', error);
    }
  }
  
  const getFavorites = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    try {
      const favoritesQuery = query(collection(db, 'Favorites'),
                                    where('useruid', '==', user.uid ));
      const querySnapshot = await getDocs(favoritesQuery);
   
      
      const favoritesArray = [];
    
      querySnapshot.forEach(doc => {
        favoritesArray.push({ ...doc.data(), id: doc.id });
      });
  
      if(favoritesArray.length > 0) {
        await Promise.all(favoritesArray.map(async (item) => {
        await deleteFavorites(item.id);
      }));
      console.log('All favorites deleted');
      } else {
        return;
      }
  
    
  
     
    } catch (error) {
      console.error('Failed to get or delete favorites', error);
    }
  }

  const deleteRedeemedCoupons = async (click) => {
    try {
      const docRef = doc(db, 'RedeemedCoupons', click);
      await deleteDoc(docRef);
    
    } catch (error) {
      
    }
  }
  
  const getRedeemedCoupons = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    try {
      const favClickQuery = query(collection(db, 'RedeemedCoupons'),
                                    where('useruid', '==', user.uid ));
      const querySnapshot = await getDocs(favClickQuery);
   
      
      const favClickArray = [];
    
      querySnapshot.forEach(doc => {
        favClickArray.push({ ...doc.data(), id: doc.id });
      });
  
      if(favClickArray.length > 0) {
        await Promise.all(favClickArray.map(async (item) => {
        await deleteRedeemedCoupons(item.id);
      }));
  
      } else {
        return;
      } 
    } catch (error) {
      console.error('Failed to get or delete favorites', error);
    }
  }
  
  const deleteFavClicks = async (click) => {
    try {
      const docRef = doc(db, 'FavClicks', click);
      await deleteDoc(docRef);
    
    } catch (error) {
      
    }
  }
  
  const getFavClicks = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    try {
      const favClickQuery = query(collection(db, 'FavClicks'),
                                    where('useruid', '==', user.uid ));
      const querySnapshot = await getDocs(favClickQuery);
   
      
      const favClickArray = [];
    
      querySnapshot.forEach(doc => {
        favClickArray.push({ ...doc.data(), id: doc.id });
      });
  
      if(favClickArray.length > 0) {
        await Promise.all(favClickArray.map(async (item) => {
        await deleteFavClicks(item.id);
      }));
  
      } else {
        return;
      } 
    } catch (error) {
      console.error('Failed to get or delete favorites', error);
    }
  }
  
  const confirmedDeleteAccount = async () => {
    Swal.fire({
          text: userType.value === 'Individual' ? 'All saved posts will be removed.' : 'Subscription will be canceled and all posts removed.',
          input: "password",
          inputLabel: "Enter Password",
          showClass: {
              popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
              `
          },
          hideClass: {
              popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
              `
          },
          inputAttributes: {
              autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Delete Account",
          showLoaderOnConfirm: true,
          preConfirm: async (password) => {
          const credential = EmailAuthProvider.credential(auth.currentUser.email, password );
          await reauthenticateWithCredential(auth.currentUser, credential)
          .then(async () => {
            await getRedeemedCoupons();
            await getFavClicks();
            await getFavorites();
            await getCoupons();
            await deleteUser(auth.currentUser);
            Swal.fire("Account Removed!");
          })
          .catch((error) => {

            
             
              Swal.fire({
                  icon: "error",
                  text: "Invalid Password.",
                  showClass: {
                  popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                  `
              },
              hideClass: {
                  popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                  `
              }
                  });
                  })
          },
          allowOutsideClick: () => !Swal.isLoading()
          })
  }
  
  function goHome() {
    showBox.value = false;
    router.push({name:'home'})
  }
  
  function goToAdminDash () {
    showBox.value = false;
    router.push({name: 'adminDash'})
  }
  
  function home () {
    router.push('/')
  }
  
  function advertise () {
    router.push('/advertise')
  }
  
  function goToDash () {
    showBox.value = false;
    router.push({name: 'dashboard'})
  }
  
  function signUpAndClose () {
    showLoginBox.value = false;
    router.push({name: 'userRegister'})
  }
  
  function loginAndClose () {
   showLoginBox.value = false;
   loginAccountBool.value = true;
   errorMsg.value = '';
  }
  
  const signOutAndClose = () => {
    showBox.value = false;
    logout()
  }

  const testEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  
  const forgotPassword = async () => {
    if(email.value === '') {
      document.getElementById('email').focus();
      return;
    }

    if(testEmail(email.value) === false) {
      Swal.fire({
        text: 'Email improperly formatted.',
        showClass: {
                  popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                  `
              },
              hideClass: {
                  popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                  `
              }
      })
      return;
    }

    
    
          Swal.fire({
          text: "Reset Password?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          showClass: {
                  popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                  `
              },
              hideClass: {
                  popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                  `
              }
        }).then((result) => {
          if (result.isConfirmed) {
            sendPasswordResetEmail(auth, email.value)
            .then(() => {
              Swal.fire("An email has been sent to reset your password.", "", "success");
            }).catch((error) => {
              Swal.fire({
                icon: "error",
                text: "Oops...",
                text: "Something went wrong! Check Email is formatted properly.",
              });
            })
           
          } 
    });
        }
    
        const pushToRegister = () => {
          loginAccountBool.value = false;
          router.push('/userRegister')
        }
    
    
        const readDocument = async (userId) => {
        const querySnapshot = await getDocs(collection(db, `Users/${userId}/Docs`));
         querySnapshot.forEach((doc) => {
          const data = doc.data();
          let typeUser = data.userType
          let theUser = auth.currentUser.email;
          let parts = theUser.split('@');
          theUser = parts[0];
          if(typeUser == 'Company') {
            toast(`Hi, ${theUser}`, {
                      "type": "success",
                      "transition": "flip",
                      autoClose: 1000
                    })
          }
          else {
            toast(`Hi, ${data.name}`, {
                      "type": "success",
                      "transition": "flip",
                      autoClose: 1000
                    })
          }
          loginAccountBool.value = false;
      
        });
      }
    
        
        // Function to handle registration
        const login = async () => {
          loading.value = true;
          try {
            await signInWithEmailAndPassword(auth, email.value, password.value)
            .then(async(userCredential) => {
              event('login', {event_category: 'login',
                              event_label : 'email login'
              })
              loading.value = false;
              errorMsg.value = null;
              var user = userCredential.user;
              if(user) {
                password.value = '';
                await readDocument(user.uid)
              // router.push('/dashboard');
              } else {
                
              }
            })
          } catch (error) {
            loading.value = false;
            errorMsg.value = "Email or Password is incorrect"
          }
        };
  
  
  
        const handleCompanySearch = (item) => {
          if(item === '') {
            return;
          }
        userBusinessInput.value = item;
        const comp = item
        const lowerCaseCo = comp.toLowerCase();
        const companyObject = companyList.value.filter(item => item.toLowerCase() === lowerCaseCo);

        if (companyObject.length > 0) {
          router.push({ name: 'CompanySearch', params: { companyName: companyObject[0] } }); // Pass the first match with original case
        } else {
          toast(`${comp} not found, check spelling.`, {
            type: "info",
            transition: "flip",
            autoClose: 1000
          });
        }
};

  
  
  
  const checkCoupons = () => {
    showBox.value = false;
    if(!auth.currentUser) {
      toast("Login to view your coupons.", {
          "type": "info",
          "transition": "flip",
          autoClose: 1000
        })
        setTimeout(() => {
          loginAccountBool.value = true;
        }, 2000);
    }
     else {
      router.push('/coupons')
     }
    
  }
  
  
  const goToFavorites = () => {
    showBox.value = false;
    if(!auth.currentUser) {
      toast("Login to view your favorites.", {
          "type": "info",
          "transition": "flip",
          autoClose: 1000
        })
        setTimeout(() => {
          loginAccountBool.value = true;
        }, 2000);
    }
     else {
      router.push('/favorites')
     }
    
  }
  
  
  
  const closeSideBar = () => {
    isExpanded.value = false;
  }
  
  
  
  
  
  const logout = () => {
        signOut(auth)
        .then(() => {
          signOutBool.value = false;
           router.replace('/')
           userType.value = ''
           
        })
        .catch((error) => {
          signOutBool.value = false;
          console.log(error)
        })
  }
  
  const checkCategory = (item) => {
    let selectedCategory = item;
    const existingCategories = categoryArray.value.filter(category => category == selectedCategory)
    if(existingCategories.length > 0) {
      activeItem.value = item
      router.push({name:'categorySearch', params: {categoryName: selectedCategory}})
    }
    else {
      toast("No companies found.", {
            "type": "info",
            "transition": "flip",
            autoClose: 1000
          })
    }
  }
  
  
  const getPromos = async () => {
    const Query = query(
      collection(db, 'CompanyPosts'), 
      where('approved', '==', true)
  );
     
     
      // const queryAll = query(collection(db, 'CompanyPosts'), where('approved', '==', true));
  
      const querySnapshot = await getDocs(Query);
  
  
  
        const tempPosts = [];
        const companyArray = []
        const newCategoryArray = []
        querySnapshot.forEach(doc => {
          tempPosts.push({ id: doc.id, ...doc.data() })
          companyArray.push(doc.data().business)
          newCategoryArray.push(doc.data().category)
        });
  
        const uniqueCompanyList = [...new Set(companyArray)]; //Removes Duplicates in the array
        const uniqueCategoryList = [...new Set(newCategoryArray)]
        companyList.value = uniqueCompanyList
        companyPosts.value = tempPosts;
        categoryArray.value = uniqueCategoryList

       
  }
  
  
  const checkUserType = async (uid) => {
   
   try{
              let data;
            const querySnapshot = await getDocs(collection(db, `Users/${uid}/Docs`));
            querySnapshot.forEach((doc) => {
              data = doc.data();
  
              switch(data.userType) {
              case 'Individual':
                userType.value = 'Individual'
                userName.value = data.name
                break;
              case 'Company':
                userType.value = 'Company'
                userName.value = data.name
                break;
              case 'Admin':
                userType.value = 'Admin'
                break;
              default:
              userType.value = ''
            }
  
            })
  
   } catch(error) {
      console.log(error);
   }
  }
  
  
  
  const googleSignIn = async () => {
          loading.value = true;
  
          const auth = getAuth();
          signInWithPopup(auth, provider)
            .then((result) => {
              const credential = GoogleAuthProvider.credentialFromResult(result);
              const token = credential.accessToken;
              const user = result.user;
              fetchSignInMethodsForEmail(auth, user.email)
              .then((SignInMethods) => {
                console.log(`Sign In methods length: ${SignInMethods.length}`)
              })
  
              // if(user) {
              //    saveUser(user.uid, user.email, user.displayName)
              //   loading.value = false;
              //   loginAccountBool.value = false
              // }
              
            
            }).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              const email = error.customData.email;
              const credential = GoogleAuthProvider.credentialFromError(error);
              loading.value = false;
            Swal.fire({
              icon: 'error',
              title: 'Login Failed',
              text: 'Something went wrong, try again.'
            });
              
            });
        }
  
  
  onMounted(() => {
 
    function isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
      }

      if (isTouchDevice()) {
          mobilePlatform.value = true;
          console.log('User on mobile.')
      } else {
          mobilePlatform.value = false;
          console.log('User on desktop.')
      }

    const backToTopBtn = document.getElementById('back-to-top-btn')

  window.addEventListener("scroll", () => {
   if(window.scrollY > 300) {
    if(!backToTopBtn.classList.contains('btnEntrance')) {
      backToTopBtn.classList.remove('btnExit');
      backToTopBtn.classList.add('btnEntrance')
      backToTopBtn.style.display = 'block';
    }
   } else {
    if(backToTopBtn.classList.contains('btnEntrance')) {
      backToTopBtn.classList.remove('btnEntrance');
      backToTopBtn.classList.add('btnExit')
      setTimeout(() => {
        backToTopBtn.style.display = 'none';
      }, 250);
    }
   }
  })

  backToTopBtn.addEventListener('click', (event) => {
    // window.scrollTo({
    //   top:0,
    //   left: 0,
    //   behavior: 'smooth'
    // })

    smoothScroll(event)
  })

  function smoothScroll(event) {
  event.preventDefault();
  const targetPosition = 0; // Scroll to the very top
  const startPosition = window.scrollY;
  const distance = targetPosition - startPosition;
  const duration = 1500;
  let start = null;
  
  window.requestAnimationFrame(step);

  function step(timestamp) {
    if (!start) start = timestamp;
    const progress = timestamp - start;
    // window.scrollTo(0, distance*(progress/duration) + startPosition);
    window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
    if (progress < duration) window.requestAnimationFrame(step);
  }
}

// Easing Functions

function linear(t, b, c, d) {
  return c*t/d + b;
};

function easeInOutQuad(t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

function easeInOutCubic(t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t*t + b;
  t -= 2;
  return c/2*(t*t*t + 2) + b;
};


     document.getElementById('coupon').addEventListener('mouseover', (event) => {
      document.querySelector('.tooltip').style.display = 'block';
     })
  
     document.getElementById('coupon').addEventListener('mouseleave', (event) => {
      document.querySelector('.tooltip').style.display = 'none';
     })
  
     document.getElementById('favHeart').addEventListener('mouseover', (event) => {
      document.querySelector('.favtooltip').style.display = 'block';
     })
  
     document.getElementById('favHeart').addEventListener('mouseleave', (event) => {
      document.querySelector('.favtooltip').style.display = 'none';
     })
  })

  
  onBeforeMount(() => {
  
    
    onAuthStateChanged(auth, (user) => {
      if (user) {
        userEmail.value = user.email
        userSignedIn.value = true;
        checkUserType(user.uid)
      } else {
        userSignedIn.value = false;
      }
    });
    getPromos();
  
  })
  </script>
  
  
  <style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    scrollbar-width: thin;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a {
    text-decoration: none;
  
  }
  body {
    background-color: ghostwhite;
    /* background-color: rgb(89, 73, 194); */
    /* background-color: slateblue; */
    background-repeat: no-repeat;
    min-height: 100vh;
    font-family: "Poppins", sans-serif;
    transition: 0.2s ease-in-out;
    overflow: auto!important;
  }
  
  body, html {
    overflow-x: hidden;
  }
  
  hr {
      margin-top: 15px;
      width: 75%;
      margin-left: auto;
    }
  


 
  .catButtons {
    padding: 15px 20px;
    background: #f3f3f3;
    color:#16076d;
    cursor: pointer;
    margin:5px;
    border-radius: 20px;
    transition:200ms ease-in-out;
  }
  
  .catButtons:hover {
    color:white;
    background: linear-gradient(to bottom right, #1670e6, rgb(184, 85, 241));
    box-shadow: 0 0 3px rgba(0,0,0,0.8);
  }
  
  .active {
    color:white;
    background: linear-gradient(to bottom right, #1670e6, rgb(184, 85, 241));
    box-shadow: 0 0 3px rgba(0,0,0,0.8);
  }
  
  .body-opacity {
    filter:brightness(0.3);
  }

  
  .mainHeader {
    /* background-color: rgb(255, 255, 255); */
      background-color: rgb(89, 73, 194); 
    padding:15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:20px;
  }
  
  .mainHeader .hamburgerIcon {
    margin-left: 10px;
    margin-top: 5px;
  }
  
  .mainHeader .hamburgerIcon:hover {
    cursor:pointer;
  }
  
  .mainHeader h1 {
    font-weight: 500;
    color: #494949;
    font-size: 19px;
  }
  
  .mainHeader h2 {
    font-weight: 400;
    color: #2c97df;
  }
  .mainHeader h2:hover {
    cursor: pointer;
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  
  @keyframes fade-in {
    from {
      opacity:  0;
    }
    to {
      opacity:  1;
    }
  }
  .userAccountModal {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    height:400px;
    width:400px;
    background: ghostwhite;
    border-radius: 25px;
    padding:25px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
    animation-name: fade-in;
    animation-duration: 500ms;
    box-shadow: 0 0 25px rgba(0,0,0,0.5);
  }
  
  .userAccountModal button {
    width: 100%;
    padding:15px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .userAccountModal button:last-child {
    background-color: rgb(211, 67, 67);
  }
  
  .signOutAccountModal {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    height:400px;
    width:400px;
    background: ghostwhite;
    border-radius: 25px;
    padding:25px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
    animation-name: fade-in;
    animation-duration: 500ms;
    box-shadow: 0 0 25px rgba(0,0,0,0.5);
  }
  
  .signOutAccountModal .signOutButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:100px;
  }
  
  .signOutAccountModal .signOutButtonContainer button:nth-child(1)
  {
    background-color: slateblue;
    padding-inline: 25px;
    padding-block: 10px;
    border-radius: 5px;
    color:white;
    outline:none;
    border:none;
    font-size: 1.4rem;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    cursor: pointer;
    transition: 200ms ease-in-out;
  
  }
  
  .signOutAccountModal .signOutButtonContainer button:nth-child(2)
  {
    background-color: #7c0e0e;
    padding-inline: 25px;
    padding-block: 10px;
    border-radius: 5px;
    color:white;
    outline:none;
    border:none;
    font-size: 1.4rem;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    cursor:pointer;
    transition: 200ms ease-in-out;
  }
  
  .signOutAccountModal .signOutButtonContainer button:nth-child(2):hover {
    background-color: #b11414;
  }
  
  .signOutAccountModal .signOutButtonContainer button:nth-child(1):hover {
    background-color: #1670e6;
  }
  
  .mainHeader .userSignOut h1 {
    color: #000000;
    transition: 0.3s ease;
  }
  
  .mainHeader .userSignOut h1:hover {
    color: rgb(218, 119, 28);
  }
  
  
  
  
  
  
  .linkContainer {
    position: absolute;
    top:65px;
  }
  
  .linkContainer p {
    color: rgb(255, 255, 255);
    transition: 200ms ease-in-out;
  }
  
  .linkContainer p:hover {
    color: white;
  }
  
  #logInBox {
    background-color: ghostwhite;
    padding:20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    position: absolute;
    top:65px;
    right:10px;
    z-index: 1000;
    cursor: pointer;
    min-width: 200px;
    animation: slide-from-right 400ms linear;
  }
  
  
  
  
  #logInBox p {
    color: rgb(65, 65, 65);
    font-size: 0.9rem;
    font-weight: 600;
    transition: 500ms ease;
    text-align: left;
    margin-block: 5px;
    padding:10px;
  }
  
  
  @keyframes slide-from-right {
    from {
        transform: translateX(+100%);
      }
      to {
        transform: translateX(0);
      }
  }
  
  
  
  #logInBox p:hover {
    transform: scale(1.05);
  }
  
  .closeLogIn {
    background-color: rgb(226, 226, 226);
      height:15px;
      width: 15px;
      padding:13px;
      border-radius: 50%;
      position: absolute;
      top:10px;
      right:10px;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: rgb(255, 255, 255);
      transition: 200ms ease;
      font-size: 0.6rem;
  }
  
  
  #logOut {
    background-color: ghostwhite;
    padding:20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    position: absolute;
    top:65px;
    right:10px;
    z-index: 1000;
    cursor: pointer;
    animation: slide-from-right 400ms linear;
  }
  
  
  #logOut p {
    color: rgb(65, 65, 65);
    font-size: 0.9rem;
    font-weight: 600;
    transition: 250ms ease;
    text-align: left;
    margin-block: 5px;
    padding:10px;
    transition: 400ms ease-in-out;
  }
  
  
  
  
  
  #logOut p:hover {
    color: #8a8a8a;
  }
  
  
  .loginContainer {
      inset: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.5);
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index:100;
        }

      @keyframes scaleup {
        from {
          transform:scale(0)
        } to {
          transform: scale(1)
        }
      }
    
  
        .registration-form {
          z-index: 1000;
          background-color: rgb(255, 255, 255);
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          width:500px;
          max-width: 500px;
          transform: scale(0);
          animation: scaleup 300ms linear forwards;
            min-height: 450px;
            position: relative;
        }
  
        .welcomeBack {
         position: absolute;
         top:20%;
         left:90%;
         transform: translate(-50%, -50%);
        }
  
        @keyframes fade-out {
      from {
          opacity: 1;
      }
      to {
          opacity: 0;
      }
  }
  
        @keyframes slide-in-top {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
  
    /* Define animation keyframes for sliding in from the right */
    @keyframes slide-in-right {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
    
        .currentlyLoading {
          opacity: 0.5;
          filter: brightness(0.5);
        }
    
        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        
        .form-group {
          margin-bottom: 20px;
          position: relative;
        }
    
        .form-group .padlock {
        position: absolute;
        right:20px;
        top:53%;
        transform: translateY(-50%);
        }
    
        .form-group .email {
          position: absolute;
        right:16px;
        top:72%;
        transform: translateY(-50%);
        }
        
        label {
          display: block;
          text-align: left;
          padding-bottom: 15px;
          margin-left: 5px;
          color: navy;
        }
        
        /* input[type="text"],
        input[type="email"],
        input[type="password"] {
          width: 100%;
          padding: 15px;
          border:none;
          outline: none;
          background-color: #fafafa;
          border-bottom: 2px solid #d4d4d4;
          box-sizing: border-box;
        } */
  
        #email, #password {
          width: 100%;
          padding: 15px;
          border:none;
          outline: none;
          background-color: #fafafa;
          border-bottom: 2px solid #d4d4d4;
          box-sizing: border-box;
        }
  
    
        
        
        .loginBtn {
          background-color: hsl(162, 54%, 53%);
          border: none;
          color: hsl(0, 0%, 100%);
          padding: 10px 20px;
          border-radius: 4px;
          cursor: pointer;
          width: 100%;
          font-size: 13px;
          transition: 0.5s ease-in-out;
          text-transform: uppercase;
          transition: 500ms ease;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 50px;
          font-weight:700;
          box-shadow: 0 10px 10px rgba(23, 79, 138, 0.3);

              }
  
        .loginBtn:hover {
          transform: scale(0.95);
        }
        
       
    
        .registerText:hover {
          cursor: pointer;
        }

        .forgotPasswordText {
          font-size: 12px;
          margin-block:10px;
        }
    
        .forgotPasswordText:hover {
          cursor: pointer;
        }
    
        .loaderIcon {
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
          
        }
  
        .inputHolder {
          display: flex;
          flex-direction: row;
        }
  
        .container .form-group .inputHolder .fa {
          color: white;
          padding:16px;
          background-color: #d4d4d4;
        }
  
        .googleSignUp {
      width:100%;
      padding:10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin: 10px auto;
      cursor: pointer;
      font-weight: 500;
  
    }
  
    .google {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
     .line {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .google .firstLine {
      border-top: 1px solid rgb(0, 0, 0);
      flex-grow: 1;
    }
  
    .google .secondLine {
      border-top: 1px solid rgb(0, 0, 0);
      flex-grow: 1;
    }
  
    .google .or {
      padding:20px;
    }
  
  
    .closeFa {
      background-color: rgb(226, 226, 226);
      height:15px;
      width: 15px;
      padding:15px;
      border-radius: 50%;
      position: absolute;
      top:15px;
      right:15px;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: white;
      transition: 200ms ease;
    }
  
    .closeFa:hover {
      cursor: pointer;
      color: black;
      box-shadow: 0 0 10px rgba(0,0,0,0.4);
    }
  
    .closeLogOut {
      background-color: rgb(226, 226, 226);
      height:10px;
      width: 10px;
      padding:13px;
      border-radius: 50%;
      position: absolute;
      top:10px;
      right:10px;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: rgb(255, 255, 255);
      transition: 200ms ease;
      font-size: 0.6rem;
    }
  
    .faSignedIn {
      margin-right:10px; 
      padding:10px; color: black; 
        border-radius: 50%; 
        cursor: pointer;
        border: 3px solid #65fe08;;
    }
  
  
   
        @media(max-width: 700px) {
          .registration-form {
            max-width: 400px;
          }
  
      
        }
  
        @media(max-width:400px) {
          .registration-form {
            width: 330px;
            max-width: 330px;
          }
          .navLogo img {
          margin-left: -35px;
        }   
        }
  
        .iconHolders {
        display: flex; 
        flex-direction: row; 
      }
  
      .headerIcon {
        font-size: 1rem;
      }
  
      .customLoader {
        width:20px;
        height:20px;
        border-radius:50%;
        background:conic-gradient(#0000 10%,#FFFFFF);
        -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
        mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
        animation:s3 0.5s infinite linear;
      }
  
        .custom-loader {
        width:20px;
        height:20px;
        border-radius:50%;
        background:conic-gradient(#0000 10%,#FFFFFF);
        -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
        mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
        animation:s3 0.5s infinite linear;
      }
      @keyframes s3 {to{transform: rotate(1turn)}}
  
      .navLogo {
      display: flex;
      align-items: center;
      width: 100%;
  }
  
  .navLogo img {
    height: 100px;
    width: 250px;
    margin-top: -20px;
  }
  
  .closeAccount {
    background-color: rgb(226, 226, 226);
      height:10px;
      width: 10px;
      padding:15px;
      border-radius: 50%;
      position: absolute;
      top:10px;
      right:15px;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: rgb(119, 119, 119);
      transition: 200ms ease;
      font-size: 0.6rem;
  }
  
  .faAccount {
    color: #494949;
    background-color: #c5c5c5;
    padding:25px;
    border-radius: 50%;
  }
  
  
  
  #coupon:hover {
    background-color: rgba(0, 11, 61, 0.7);
    padding:15px;
    border-radius: 50%;
  }
  
  #favHeart:hover {
    background-color: rgb(0, 11, 61, 0.7);
    padding:15px;
    border-radius: 50%;
  }
  
  
  
  .tooltip {
    color: #fff;
    font-size: 13px;
    max-width: 28ch;
    text-align: center;
    position: absolute;
    top:55px;
    left:-20px;
    z-index: 100;
  }
  .tooltip {
    /* triangle dimension */
    
    --b: 2em; /* base */
    --h: 1em; /* height */
  
    --p: 45%; /* triangle position (0%:left 100%:right) */
    --r: 1.2em; /* the radius */
    --c: #464646;
  
    padding: 1em;
    border-radius: min(var(--r),var(--p) - var(--b)/2) min(var(--r),100% - var(--p) - var(--b)/2) var(--r) var(--r)/var(--r);
    clip-path: polygon(0 0,0 100%,100% 100%,100% 0,
      min(100%,var(--p) + var(--b)/2) 0,
      var(--p) calc(-1*var(--h)),
      max(0%  ,var(--p) - var(--b)/2) 0);
    background: var(--c);
    border-image: conic-gradient(var(--c) 0 0) fill 0/
      0 calc(100% - var(--p) - var(--b)/2) var(--r) calc(var(--p) - var(--b)/2)/
      var(--h) 0 0 0;
      animation: fade-in 200ms linear;
      display: none;
  }
  
  .favtooltip {
    color: #fff;
    font-size: 13px;
    max-width: 28ch;
    text-align: center;
    position: absolute;
    top:55px;
    left:-20px;
    z-index: 100;
  }
  
  .favtooltip {
    --b: 2em; /* base */
    --h: 1em; /* height */
  
    --p: 45%; /* triangle position (0%:left 100%:right) */
    --r: 1.2em; /* the radius */
    --c: #464646;
  
    padding: 1em;
    border-radius: min(var(--r),var(--p) - var(--b)/2) min(var(--r),100% - var(--p) - var(--b)/2) var(--r) var(--r)/var(--r);
    clip-path: polygon(0 0,0 100%,100% 100%,100% 0,
      min(100%,var(--p) + var(--b)/2) 0,
      var(--p) calc(-1*var(--h)),
      max(0%  ,var(--p) - var(--b)/2) 0);
    background: var(--c);
    border-image: conic-gradient(var(--c) 0 0) fill 0/
      0 calc(100% - var(--p) - var(--b)/2) var(--r) calc(var(--p) - var(--b)/2)/
      var(--h) 0 0 0;
      animation: fade-in 200ms linear;
      display: none;
  }
  
  .accountRemoval {
    top:50%;
    left:50%;
    min-width: 300px;
    transform: translate(-50%, -50%);
    position: fixed;
    padding:25px;
    background-color: ghostwhite;
    border-radius: 15px;
    box-shadow: 0 0 25px rgba(0,0,0,0.5);
    animation: fade 300ms linear;
  }
  
  @keyframes fade {
    from {
        opacity:0;
      }
      to {
        opacity:1;
      }
  }
  
  
  
  
  
  
  .accountRemoval button:first-child {
    padding-inline: 25px;
    padding-block: 10px;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    transition: 200ms ease-in-out;
    background-color: rgb(155, 49, 49);
    outline: none;
    border: none;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
  }
  
  .accountRemoval button:first-child:hover {
    background-color: #b11414;
  }
  
  .accountRemoval .accountCancelBtn {
      background-color: rgb(226, 226, 226);
      height:15px;
      width: 15px;
      padding:15px;
      border-radius: 50%;
      position: absolute;
      top:15px;
      right:15px;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: white;
      transition: 200ms ease;
      border: none;
      outline: none;
  }
  
  .accountCancelBtn:hover {
    cursor: pointer;
      color: black;
      box-shadow: 0 0 10px rgba(0,0,0,0.4);
  }
  
  
  
  @media(max-width:450px) {
    .accountRemoval button {
      width: 100px;
    }
  }
  
  .socialContainer {
    background:  rgb(75, 62, 163);
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-size: 0.8rem;
  }

  .socialContainer a {
    text-decoration: none;
    color: white;
  }

  
  .socialContainer > div {

    max-width: 300px;
  }
  
  .socialContainer > div > img {
    cursor: pointer;
  }
  
  footer {
         width: 100%;
         height:350px;
         padding:40px;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         background:  rgb(89, 73, 194);
         z-index: 1;
      }
      
       #divOne {
        width:50%;
        height: 100%;
        max-width: 50%;
        display: flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
        text-align: left;
        position: relative;
      }
      
      footer #divTwo {
        position: relative;
        height: 100%;
        padding-top: 50px;
        width: 50%;
        max-width: 50%;
        display: flex;
        justify-content: center;
        align-items:center;
        flex-direction: column;
      }
      
      #divOne .linkContainer {
       margin-top: 35px;
      }
      
      #divTwo .linkContainer {
        margin-top: 33px;
      }
      
      .linkContainer a {
        text-decoration: none;
        color:white;
        cursor: pointer;
        transition: 200ms ease;
      }
      
      .linkContainer a .fa {
        margin-right: 15px;
        font-size: 15px;
      }
      
      .linkContainer a:hover {
        color: white;
      }
      
      
      .linkContainer img {
        height: 18px;
        width: 18px;
        margin-right: 15px;
      
      }
      
      footer #divTwo h2 {
        color: white;
        font-size: 1.2rem;
        position: absolute;
        top:35px;
      
      }
      
      footer > div h2 {
        color: white;
        font-size: 1.2rem;
        position: absolute;
        top:35px;
      
      }
      
      footer > div p {
        color: rgb(255, 255, 255);
        font-size: 0.7rem;
        cursor: pointer;
        transition: 200ms ease;
        margin:5px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        padding:5px;
      
      }
      
      footer > div p:hover {
        color: white;
      }
  
      .catButtons {
        background-color: rgba(10, 0, 102, 0.7);
        color: white;
      }

      #back-to-top-btn {
  display:none;
  position: fixed;
  bottom:20px;
  right:10px;
  font-size: 20px;
  width:40px;
  height:40px;
  background: #fff;
  color: hsl(162, 54%, 53%);
  cursor: pointer;
  outline: none;
  border: 2px solid hsl(162, 54%, 53%);
  border-radius: 50%;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  transition-property: background-color, color;
}

#back-to-top-btn:hover, #back-to-top-btn:focus {
  background:hsl(162, 54%, 53%);
  color:white;
} 

.btnEntrance {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: btnEntrance
}

@keyframes btnEntrance {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  } to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.btnExit {
  animation-duration: 250ms;
  animation-fill-mode: both;
  animation-name: btnExit;
}

@keyframes btnExit {
  from {
    opacity: 1;
  } to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}


.dropdown {
    width:200px;
    position: relative;
    top: 0;
    font-size: 0.8rem;
}
.select {
    background: white;
    color: #2a2f3b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px gainsboro solid;
    border-radius: 0.5em;
    padding: 0.75em;
    cursor: pointer;
    transition: 0.3s;
}
.select-clicked {
    border: 1px navy solid;
    box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
  }
.caret {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #030303;
    transition: 0.3s;
}
.caret-rotate {
    transform: rotate(180deg);
}
.menu {
    list-style: none;
    padding: 0.2em 0.5em;
    border: 1px gainsboro solid;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
    color: #0e2669;
    position: absolute;
    background:white;
    top: 4em;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 1;
}
.menu li {
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
}
.menu li:hover {
    background: #f3f3f3;
}
.menuActive {
    background: #f1f1f1;
}
.menu-open {
    display: block;
    opacity: 1;
}


#loginBtn{
  background:transparent;
  color: hsl(162, 54%, 53%);
  border: 1px solid   hsl(162, 54%, 53%);
  padding-inline: 20px;
  padding-block: 8px;
  border-radius: 5px;
  margin-top:10px;
  transition: 300ms ease-in-out;
  cursor: pointer;
  font-weight: 600;
}

#loginBtn:hover {
  background:hsl(162, 54%, 53%);
  color: white;

}

.userBtn {
  background:hsl(162, 54%, 53%);
  padding-inline: 20px;
  padding-block: 8px;
  border-radius: 5px;
  margin-top:10px;
  transition: 300ms ease-in-out;
  cursor: pointer;
  font-weight: 600;
}


.underline-hover-effect {
  text-decoration: none;
  cursor: pointer;
  color:white;
  margin-left:10px;
}

.underline-hover-effect {
  display: inline-block;
  padding-bottom: 0.25rem; /* defines the space between text and underline */
  position: relative;
}

.underline-hover-effect::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: hsl(162, 54%, 53%);
  transition: width 0.25s ease-out;
}
.underline-hover-effect:hover::before {
  width: 100%;
}

#type-ahead-search {
  min-width: 100%;
   padding: 15px 15px 15px 80px; 
   outline: none; 
   border: 1px solid gainsboro; 
   font-size: 16px; 
   border-radius:30px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
   transition:100ms ease-in-out;
}

#comp-search-btn {
  position:absolute; left:10%; top:50%; transform: translate(-50%, -50%); font-size: 15px; color:white; padding-block: 10px; padding-inline: 20px; border-radius: 30px; background: rgb(100, 73, 194);
  cursor: pointer;; z-index: 100;
}
#comp-clear-btn {
  position:absolute; right:10%; top:50%; transform: translate(-50%, -50%); font-size: 15px; color:rgb(0, 0, 0); padding-block: 10px; padding-inline: 20px;
  cursor: pointer;; z-index: 100;
}


  
  </style>