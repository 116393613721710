<template>

    <body>

        <div class="theSideBar">  
          <div @click="viewAnalytics">
          <font-awesome-icon icon="chart-simple" class="faIcon"  title="Analytics" style="font-size: 1rem;" />
          <span>Analytics</span>
        </div>
        <div @click="openChatBox">
          <font-awesome-icon icon="robot" class="faIcon"  title="Analytics" style=" font-size: 1rem;" />
          <span style="margin-left: -5px;">ChatBot</span>
        </div>
        <div @click="viewForm('Promotion')">
          <font-awesome-icon icon="bullhorn" class="faIcon"  title="Edit Posts" style=" font-size: 1rem; " />
          <span>Promotion</span>
        </div>
        <div @click="viewForm('Deal')">
          <font-awesome-icon icon="star" class="faIcon"  title="Edit Posts" style="font-size: 1rem; " />
          <span>Deal</span>
        </div>
        <div @click="viewForm('Coupon')">
          <font-awesome-icon icon="ticket" class="faIcon"  title="Edit Posts" style="font-size: 1rem; " />
          <span>Coupon</span>
        </div>
        <div @click="viewForm('Events')">
          <font-awesome-icon icon="calendar-check" class="faIcon"  title="Edit Posts" style="font-size: 1rem; " />
          <span>Events</span>
        </div>
        <div @click="viewCurrentPosts">
          <font-awesome-icon icon="list-ul" class="faIcon"  title="Edit Posts" style=" font-size: 1rem; " />
          <span>Posts</span>
        </div>
        <div @click="spotlight()">
          <font-awesome-icon icon="lightbulb" class="faIcon"  title="Spotlight" style=" font-size: 1rem;" />
          <span>Spotlight</span>
        </div>
        <div @click="logOut">
          <font-awesome-icon icon="arrow-right" class="faIcon"  title="Pending Posts" style=" font-size: 1rem;" />
          <span>Logout</span>
        </div>
    </div>

    <div class="dashNav">
      <div class="dashContain">
        <p style=" color: #5c5c5c; font-weight:600; font-size: 1.5rem; " class="dash">Dashboard 
        </p>
   
        <div style="display:flex;">
          <font-awesome-icon @click="goHome" icon="home" class="faIcon"  title="Home" style="color: hsl(223, 71%, 50%); font-size: 1rem; margin-right:10px; cursor:pointer; padding:10px; border-radius: 5px;  border-radius: 5px; background:hsl(223, 50%, 90%);"/>
          <div class="notifyContainer">
            <font-awesome-icon @click="showNotificationFunc" icon="bell" class="faIcon"  title="Notifications" style="color: hsl(0, 71%, 50%); font-size: 1rem; margin-right:10px; cursor:pointer; padding:10px; border-radius: 5px; background:hsl(0, 50%, 90%);" />
            <div v-show="showNotifications" class="notificationsBox">
              <p>Notifications</p>
              <p v-if="notifications.length === 0" style="margin-top: 25px;">Nothing to show at the moment...</p>
              <div class="closeNotification">
                <font-awesome-icon @click="showNotificationFunc" icon="x" class="faIcon"   style="cursor:pointer; color:gainsboro;" />
              </div>
            </div>
            <div class="notifyAlert"></div>
          </div>
          <font-awesome-icon @click="viewBusinessDetails" icon="user" class="faIcon"  title="Account Management" style="color:hsl(116, 71%, 50%); font-size: 1rem; margin-right:15px; cursor:pointer; padding:10px; border-radius: 5px; background:hsl(116, 50%, 90%);" />
        </div>
      </div>
    </div>

    <div class="analytics" v-show="analytics">
      <div class="mainAnalytics">
        <div>
          <p class="analyticTitle"> 🟢 Daily Users</p>
          <h1 id="userAnalytics"></h1>
          <font-awesome-icon icon="users" class="faIcon users" />
        </div>
        <div>
          <p class="analyticTitle">Daily Page Views</p>
          <font-awesome-icon icon="eye" class="faIcon users" />
          <h1 id="engagementAnalytics"></h1>
        </div>
        <div>
          <p class="analyticTitle">Daily Events <span style="font-size: 0.7rem;">Scrolls | Clicks</span></p>
          <font-awesome-icon icon="computer-mouse" class="faIcon users" />
          <h1 id="eventAnalytics"> </h1>
        </div>
        <div>
          <p class="analyticTitle">Active Posts</p>
          <font-awesome-icon icon="list-ul" class="faIcon users" />
          <h1 id="postAnalytics"></h1>
        </div>
      </div>
      <div class="firstAnalytics">
   
        <div class="userLastThrity" style="position:relative; overflow: hidden; box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);" >
          <p class="analyticTitle">Redeemed Coupons</p>
          <font-awesome-icon icon="ticket" class="faIcon users" />
          <section style=" position:absolute; top:55%; left:50%; transform: translate(-50%, -50%);">
            <canvas id="couponChart"></canvas>
          </section>
        </div>


        <div class="appStability" style="overflow:hidden; box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);">
          <p class="analyticTitle">Favorites</p>
          <font-awesome-icon icon="heart" class="faIcon users" />
          <section >
            <canvas id="myChart" ></canvas>
          </section>
 
        </div>
      </div>

      <div class="secondAnalytics">
        <div style="overflow:hidden; position:relative;  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);">
          <p class="analyticTitle">Monthly Page Views</p>
          <font-awesome-icon icon="calendar-check" class="faIcon users" />

          <section>
            <div id="monthlyLineChart" height="300px;"></div>
          </section>
        </div>
        
        <div style="position:relative;  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);">
          <p class="analyticTitle">Demographics</p>
        
        </div>
      </div>

    </div>

    <div class="gridContainer" v-show="PostForm" >
   

      <div class="grid">
        <font-awesome-icon icon="1" class="faIcon step-one"  style="font-size: 1rem;" />
        <form class="imageUpload">
        <p>Upload Your Image <font-awesome-icon icon="image" class="faIcon"  style="margin-left:15px; font-size: 1rem; " /></p>
        <div @dragenter.prevent="toggleActive"
            @dragleave.prevent="toggleActive"
            @dragover.prevent
            @drop.prevent="imageDrop"
          class="drag-drop-zone"
          :class="{'active-dropzone' : dzActive, 'hasImage' : selectedImage}"
          >
          <font-awesome-icon icon="cloud-arrow-up" class="fa cloudUp" />
          <p>Drag & Drop file here</p>
      
        
        </div>
        <div class="google">
                    <div class="line">
                        <div class="firstLine"></div>
                        <div class="or">or</div>
                        <div class="secondLine"></div>
                    </div>
                </div>
        <div  style="display:flex; justify-content: center; align-items: center;">
          <input type="file" id="dropzoneFile" accept="image/*" @change="handleFileInputChange" class="file">
          <input  type="text" name="file-name" id="file-name" class="file-name" readonly="readonly" :value="imageFile ? imageFile.name.substring(0,50) : ''">
          <input  type="button" class="fileBtn" value="Select" @click="getImage">
        </div>
      </form>
      </div>

      <div class="grid">
        <font-awesome-icon icon="2" class="faIcon step-two"  style="font-size: 1rem;" />
          <form>
          <p>Create {{ selectedOption }} <font-awesome-icon v-show="selectedOption === 'Promotion'" icon="bullhorn" class="faIcon"  title="Edit Posts" style="font-size: 1rem; margin-left:15px; " />
                                          <font-awesome-icon v-show="selectedOption === 'Deal'" icon="star" class="faIcon"  title="Edit Posts" style="font-size: 1rem; margin-left:15px;"/>
                                          <font-awesome-icon v-show="selectedOption === 'Coupon'" icon="ticket" class="faIcon"  title="Edit Posts" style="font-size: 1rem; margin-left:15px; "/>
            </p>

          <label for="title">Title</label>
            <input v-model="title" type="text" maxlength="25" placeholder="Special Offer, Discount, Deal of the Day" required>

            <label for="description">Description</label>
            <input type="text" v-model="description"  maxlength="225" placeholder="Provide details about your offer" required>
                </input>

                <div v-show="selectedOption == 'Promotion'" style="display: flex; flex-direction: row; gap:15px;">
                  <label  for="promoCode">Include Promo Code</label>
                  <input  type="checkbox" id="promoCode" v-model="promoCheckboxClicked">
                </div>

                <div v-show="promoCheckboxClicked && selectedOption == 'Promotion'">
                  <label for="promotionCode">Create Code</label>
                  <input type="text" v-model="promoCode" maxlength="10">
                </div>

                  <label v-if="selectedOption == 'Coupon'">Coupon Redemption Time</label>
                  <select v-if="selectedOption == 'Coupon'" v-model="couponRedemptionTime" class="custom-select">
            
                  <option selected value="1 Hour" title="1 Hour">01:00</option>
                  <option value="2 Hours" title="2 Hours">02:00</option>
                  <option value="3 Hours" title="3 Hours">03:00</option>
                  <option value="4 Hours" title="4 Hours">04:00</option>
                  <option value="5 Hours" title="5 Hours">05:00</option>
                  <option value="6 Hours" title="6 Hours">06:00</option>
                  <option value="7 Hours" title="7 Hours">07:00</option>
                  <option value="8 Hours" title="8 Hours">08:00</option>
                  <option value="9 Hours" title="9 Hours">09:00</option>
                  <option value="10 Hours" title="10 Hours">10:00</option>
                  <option value="11 Hours" title="11 Hours">11:00</option>
                  <option value="12 Hours" title="12 Hours">12:00</option>
                  <option value="13 Hours" title="13 Hours">13:00</option>
                  <option value="14 Hours" title="14 Hours">14:00</option>
                  <option value="15 Hours" title="15 Hours">15:00</option>
                  <option value="16 Hours" title="16 Hours">16:00</option>
                  <option value="17 Hours" title="17 Hours">17:00</option>
                  <option value="18 Hours" title="18 Hours">18:00</option>
                  <option value="19 Hours" title="19 Hours">19:00</option>
                  <option value="20 Hours" title="20 Hours">20:00</option>
                  <option value="21 Hours" title="21 Hours">21:00</option>
                  <option value="22 Hours" title="22 Hours">22:00</option>
                  <option value="23 Hours" title="23 Hours">23:00</option>
                  <option value="24 Hours" title="24 Hours">24:00</option>  
                  </select>
          
            <div style=" margin-top: 25px; width:100%; display: flex; justify-content: center; align-items: center;  flex-direction:column;border:1px solid gainsboro; padding:50px; position: relative;">
              <font-awesome-icon icon="3" class="faIcon step-three"  style="font-size: 1rem;" />

              <label for="expiration" style="  color: rgb(207, 207, 207); font-size: 1rem;">Post Expiration Date</label>
              <div>
                <VueDatePicker height="500" v-model="date" inline auto-apply  menu-class-name="dp-custom-menu"></VueDatePicker>
              </div>
            </div>
          </form>
      
      </div>

 
     
    
    <div class="grid" >
      
      <p style="margin:20px;">Post Preview </p>
      <div class="imagePreview"  :class="{'blur' : !selectedImage || !title || !description}" draggable="true">
            <img v-if="selectedImage" :src="selectedImage" class="promoImg"  alt="photo">
                   <div id="timer" :title="date">
                    <font-awesome-icon icon="clock" class="fa" style="margin-right:5px" /> Expires:   <span style="font-weight: 700;"></span> {{ countdown }}
                    </div>  
                    <div id="timer" :title="date">
                <font-awesome-icon icon="clock" class="fa" style="margin-right:5px; color:navy;" /> Expires:   <span style="font-weight: 700;"></span> {{ date.toLocaleString() }}
                </div>  
        
                <div style="text-align: left; padding-left: 25px; margin-top:25px; color:navy;">
                    <h4>{{ business }}</h4>
                <p id="title" style="color:navy; font-size: 12px;"><font-awesome-icon icon="location-pin" class="fa" style="margin-right:10px; color: #8a8a8a; cursor: pointer;" />{{ address }}</p>
                <p id="number" style="color:navy; font-size: 12px;"><font-awesome-icon icon="phone" class="fa" style="margin-right:10px; color: #8a8a8a; cursor: pointer;" />{{ formattedPhoneNumber }}</p>
                <p v-if="website" id="number" style="color:navy; font-size: 12px;"><font-awesome-icon icon="globe" class="fa" style="margin-right:10px; color: #8a8a8a; cursor: pointer;" />{{ website.substring(0, 45) }}</p>
                </div>
          
                <hr>

                <h3 style="text-align: center; color:navy;">{{ title }}</h3>
              
                <p id="descriptionText" style="text-align: center; margin-left: 0px; font-weight: 400; color:navy;">{{ description }}</p>
                <p v-if="promoCheckboxClicked" class="promoButton"><span style="font-weight: 700; margin-right: 10px; color:navy;">Promo Code:</span>{{ promoCode }}</p>
                <button v-if="selectedOption == 'Coupon'" class="promoButton">Get Coupon</button>
       </div>
       <div @click="checkSubmitPost" class="createPost fileBtn" :class="{'hideButton' : selectedImage && title && description}">
        <span>Submit</span><font-awesome-icon icon="check" class="faIcon"  style=" font-size: 1rem;" />
                </div>
      </div>

    
    </div>

    <div class="promoContainer" v-show="tableData">
      <div class="gridPromo">
        <p>🟢 Active Posts</p>
        <p>{{ currentPromos.length }}</p>
      </div>
      <div class="gridPromo">
        <p>Approved Posts <font-awesome-icon icon="check" class="fa" style="margin-left:10px" /></p>
        <div class="editPosts" v-if="currentPromos.length > 0">
          <div style="width: 100%;">
            <ul class="edit-ul" style="margin-bottom: 15px; width: 100%; padding-block: 20px; font-weight: bold; border-bottom:1px solid gainsboro;">
              <li class="flex-item">Number</li>
              <li class="flex-item">Title</li>
              <li class="flex-item">Edit</li>
              <li class="flex-item">Delete</li>
            </ul>
            <ul v-for="(post, index) in currentPromos" :key="index" class="edit-ul approved-ul">
              <li class="flex-item">{{ index + 1 }}</li>
              <li class="flex-item">{{ post.title }}</li>
              <li @click="editPost(post)" class="flex-item"><font-awesome-icon icon="pen" class="fa" style=" cursor: pointer;" id="pen" /></li>
              <li @click="deletePost(post)" class="flex-item"><font-awesome-icon icon="trash" class="fa"  style=" cursor: pointer;" id="trash"/></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="gridPromo">
        <p>Pending Posts <font-awesome-icon icon="clock" class="fa" style="margin-left:10px" /></p>
        <div class="editPosts" v-if="pendingPromos.length > 0">
              <div style="width: 100%;">
                <ul class="edit-ul" style="margin-bottom: 15px; width: 100%; padding-block: 20px; font-weight: bold; border-bottom:1px solid gainsboro;">
                  <li class="flex-item">Number</li>
                  <li class="flex-item">Title</li>
                  <li class="flex-item" >Awaiting</li>
                  <li class="flex-item">Delete</li>

          
                </ul>
                <ul v-for="(post, index) in pendingPromos" :key="index" class="edit-ul approved-ul">
                  <li class="flex-item">{{ index  + 1 }}</li>
                  <li class="flex-item">{{ post.title }}</li>
                  <li class="flex-item"><font-awesome-icon icon="check" class="fa"  style=" color:limegreen"/></li>
                  <li @click="deletePost(post)" class="flex-item"><font-awesome-icon icon="trash" class="fa"  style=" cursor: pointer;" id="trash"/></li>

                  <!-- <li class="flex-item" v-if="!post.approved && post.denied"><button @click="checkMessage(post.deniedMessage)" id="messageButton"><font-awesome-icon icon="comment" class="fa" style="font-size: 1.5rem; color: gainsboro;"></font-awesome-icon></button></li>
                  <li class="flex-item" v-if="!post.approved && post.denied"><button @click="editPost(post)" id="editButton">Edit Post</button></li> -->

                </ul>
              </div>
            </div>
   
      </div>
      <div class="gridPromo" >
        <p>Denied Posts <font-awesome-icon icon="triangle-exclamation" class="fa" style="margin-left:10px" /></p>
        <div class="editPosts" v-if="deniedPromos.length > 0">
              <div style="width: 100%;">
                <ul class="edit-ul" style="margin-bottom: 15px; width: 100%; padding-block: 20px; font-weight: bold; border-bottom:1px solid gainsboro;">
        
                  <li class="flex-item">Title</li>
                  <li class="flex-item" >Denied</li>
                  <li class="flex-item" >Message</li>
                  <li class="flex-item" >Edit</li>
                </ul>
                <ul v-for="(post, index) in deniedPromos" :key="index" class="edit-ul approved-ul">
        
                  <li class="flex-item" >{{ post.title }}</li>
                  <li class="flex-item" v-if="!post.approved && post.denied"><font-awesome-icon icon="check" class="fa" style="color: red;"></font-awesome-icon></li>
                 <li class="flex-item" v-if="!post.approved && post.denied"><button @click="checkMessage(post.deniedMessage)" id="messageButton"><font-awesome-icon icon="comment" class="fa" style=" color: gainsboro;"></font-awesome-icon></button></li>
                  <li class="flex-item" v-if="!post.approved && post.denied"><button @click="editPost(post)" id="editButton">Edit</button></li> 

                </ul>
              </div>
            </div>
      </div>
    </div>

    <div class="ai-container" v-show="aiSession">

      <div class="ai-chat-box">

        <label for="filter" class="switch" aria-label="Toggle Filter" >
        <input type="checkbox" id="filter" v-model="aiTypeChecked">
        <span>ChatBot</span>
        <span>Image Gen</span>
      </label>

       <div class="chat-box-header">
        <p v-if="aiTypeChecked === false"> <span><img src="../assets/icons8-gpt.svg" height="20px" width="20px" style="margin-right:10px; margin-top:5px;"/></span> GPT-4o ChatBot</p>
        <p v-if="aiTypeChecked === true"> <span><img src="../assets/icons8-gpt.svg" height="20px" width="20px" style="margin-right:10px; margin-top:5px;"/></span> Dall-e-3 Image Generator</p>

       </div>
      <!-- <div class="chat-message ai-message"><img src="../assets/icons8-chatgpt.svg" height="20" width=20 alt="" style="margin-right:10px;"></div> -->
  
      </div>
      <div class="chat-input-container">
      <div class="input-holder">
        <form id="chat-box-form" @submit.prevent="userQuestion">
        <input type="text" name="user-prompt" id="user-prompt" placeholder="Enter prompt">
        <button type="submit" id="user-prompt-btn"><font-awesome-icon icon="paper-plane" class="fa" style="font-size: 1rem; color: white; margin-right:20px;" />Send</button>
        </form>
      </div>
      </div>

        
      <div v-show="aiThinking" class="open-ai-loader-container">
        <div class="open-ai-loader">
      </div>

      </div>



    </div>
  


          
     <div class="settingsContainer" v-show="viewSettings">
      <div class="biz-grid">
        <p>Business Name</p>
        <form @submit.prevent="updateBusinessDetails">
          <label for="businessName"><font-awesome-icon icon="id-card" class="faIcon"  style="font-size: 1rem; margin-right:20px;" />Business</label>
          <input type="text" name="businessName" id="biz-grid-business-name" v-model="business">
          <button type="submit">Update</button>
        </form>
      </div>
      <div class="biz-grid">
        <p>Change Password</p>
        <form @submit.prevent="updateUserPassword">
          <label for="password"><font-awesome-icon icon="lock" class="faIcon"  style="font-size: 1rem; margin-right:20px;" />Password</label>
          <input type="password" name="password" id="biz-grid-business-password" value="random">
          <button type="submit">Update</button>
        </form>
      </div>
      <div class="biz-grid">
        <p>Contact Details</p>
        <form @submit.prevent="updateBusinessDetails">
          <label for="address"> <font-awesome-icon icon="home" class="faIcon"  title="Analytics" style="margin-right:20px; font-size: 1rem;" />Complete Address</label>
          <input type="text" name="address" id="biz-grid-business-address" v-model="address">
          <label for="phone"> <font-awesome-icon icon="phone" class="faIcon"  title="Analytics" style="margin-right:20px; font-size: 1rem;" />Phone Number</label>
          <input type="text" name="phone" id="biz-grid-business-phone" v-model="phone">
          <label for="website"><font-awesome-icon icon="globe" class="faIcon"  title="Analytics" style="margin-right:20px; font-size: 1rem;" />Website</label>
          <input type="text" name="website" id="biz-grid-business-website" v-model="website">
          <button type="submit">Update</button>
        </form>
      </div>
      </div> 
    </body>

                

                   <div v-if="viewCreatePostContainer"  class="loadingContainer">
                    <div id="uploadChart">
                      <apexchart type="radialBar" :options="uploadChartOptions" :series="uploadChartSeries"></apexchart>
                    </div>
                    <font-awesome-icon  icon="x" class="faIcon closeButton"  title="close" style="color:rgb(194,194,194)" @click="viewCreatePostContainer = false" />
               
                 <div  style="display: flex; justify-content: center; align-items: center; flex-direction:column;">
                  <p style="font-size: 1.5rem; ">Post Uploaded</p>
                  <p style="text-align: center; color:slategrey; padding:20px; font-size: 0.8rem;">Your post will go live when it is approved by an Administrator, typically within a few hours.</p>
                 </div>
                   </div>

      <div v-show="loading" class="loadingHolder">
        <div id="loading-container"></div>
        <div id="loader-bar"></div>
      </div>

      <div v-show="deleteLoading" class="loadingHolder">
        <div class="custom-loader"></div>
      </div>
       
</template>




<script setup>

import { collection, getDocs, updateDoc, doc, addDoc, query, where, deleteDoc, getDoc, serverTimestamp, Timestamp } from "firebase/firestore"; 
import { getAuth, updatePassword, signOut} from "firebase/auth";
import { getStorage, ref as storageRef, getDownloadURL, uploadBytes, uploadBytesResumable  } from "firebase/storage";
import { db } from '../../firebase'
import { ref, onBeforeMount, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Chart from 'chart.js/auto';
import 'animate.css';
import axios from 'axios';
import ApexCharts from 'apexcharts'
import 'animate.css';

const router = useRouter();
const auth = getAuth();
const user = auth.currentUser;
const business = ref('')
const email = ref('')
const phone = ref('')
const address = ref('')
const category = ref('')
const imageFile =  ref(null)
const title = ref('');
const description = ref('')
const website = ref('');
const headline = ref('');
const selectedOption = ref('Deal')
const userIdentify = ref('');
const countdown = ref('')
const couponRedemptionTime = ref('1 Hour')
const coords = ref({})
const promoCheckboxClicked = ref(false);
const promoCode = ref('')
const currentPromos = ref([]);
const pendingPromos = ref([]);
const deniedPromos = ref([]);
const redeemedCoupons = ref([]);
const notifications = ref([]);
const subStatus = ref('');
const updatePost = ref(false);
const progress = ref(0)
let promoContainer = ref(false);
let viewFormType = ref(false)
let postCreated = ref(false)
let viewFormTwo = ref(false);
let viewUploadContainer = ref(false);
let viewCreatePostContainer = ref(false)
let progressComplete = ref(false);
let formThree = ref(false);
let firstForm = ref(false);
const selectedImage = ref(null);
let date = ref(new Date());
let dzActive = ref(false);
let previewForm = ref(false);
let loading = ref(false);
let deleteLoading = ref(false);
let submitPost = ref(false);
let tableData = ref(false);
let newImage = ref(false);
let pendingPost = ref(false);
let analytics = ref(false);
let PostForm = ref(false);
let aiSession = ref(false);
let aiThinking = ref(false);
let viewSettings = ref(false);
let showNotifications = ref(false);
let aiTypeChecked = ref(false)
let freeUser = ref(false);
let favItems = ref([]);
let docId;
let updateDocId;
let originalImgUrl;
let count = 0;
let cssShowClass = {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          }
let cssHideClass = {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          }

const uploadChartSeries = [100]
const uploadChartOptions = {
            chart: {
              type: 'radialBar',
              offsetY: -20,
              sparkline: {
                enabled: true
              }
            },
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: "#e7e7e7",
                  strokeWidth: '97%',
                  margin: 5, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    color: '#999',
                    opacity: 1,
                    blur: 2
                  }
                },
                dataLabels: {
                  name: {
                    show: false
                  },
                  value: {
                    offsetY: -2,
                    fontSize: '22px'
                  }
                }
              }
            },
            grid: {
              padding: {
                top: -10
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91]
              },
            },
            labels: ['Average Results'],
          }

         

const showNotificationFunc = () => {
  showNotifications.value = !showNotifications.value
}

const logOut = () => {
  signOut(auth).then(() => 
router.replace('/')).catch((e) => console.log(e))
}

const goHome = () => {
  router.replace('/')
}

const updateEachPost = async (postId) => {
await updateDoc(doc(db, `CompanyPosts`, postId), {
  address: address.value,
  business: business.value,
  phone: phone.value,
  website: website.value
});
};

const updateCompanyPosts = async () => {
  const companyQuery = query(collection(db, `CompanyPosts`), where('uid', '==', userIdentify.value));
  const querySnapshot = await getDocs(companyQuery);
  
  const updatePromises = querySnapshot.docs.map(doc => updateEachPost(doc.id));

  await Promise.all(updatePromises);
};

const showAlert = (text) => {
  Swal.fire({
    text: text,
    showClass: cssShowClass,
    hideClass: cssHideClass
  });
};

const updateBusinessDetails = async () => {
  if (phone.value.length !== 10) {
    showAlert("Phone number must be 10 characters.");
    return;
  }

  loading.value = true;

  try {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address.value)}&key=AIzaSyChlmPj8_vsGHbVHzzHhBF1fimdALRPcsY`);
    if (response.data.status === 'ZERO_RESULTS') {
      loading.value = false;
      showAlert("Address is not properly configured.");
      return;
    }

    let site = website.value;
    if (!site.startsWith('http://') && !site.startsWith('https://')) {
      site = 'https://' + website.value;
      website.value = site;
    }

    await updateCompanyPosts();
    await updateDoc(doc(db, `Company/${user.uid}/Docs`, docId), {
      address: address.value,
      business: business.value,
      email: email.value,
      phone: phone.value,
      website: website.value
    });

    loading.value = false;
    readDocument(user.uid);
    showAlert("Credentials Updated!");

  } catch (error) {
    loading.value = false;
    showAlert("Something went wrong, try again.");
    console.error(error);
  }
};


const updateUserPassword = async () => {
  const password = document.getElementById('biz-grid-business-password').value;
  if(password.length < 8) {
    Swal.fire({
    title: "Authentication",
    text: "Password must be at least 8 characters.",
    showClass: cssShowClass,
      hideClass: cssHideClass
  });
  return;
  }

  loading.value = true;

  Swal.fire({
  title: "Authentication",
  text: "Are you sure you want to change your password?",
  icon: 'info',
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, update my password!"
}).then((result) => {
  if (result.isConfirmed) {
    updatePassword(user, password)
    .then(() => {
      loading.value = false;
      Swal.fire({
    title: "Authentication",
    icon: 'success',
    text: "Password Updated.",
    showClass: cssShowClass,
    hideClass: cssHideClass
  });
    }).catch((error) => {
      loading.value = false;
      Swal.fire({
    title: "Authentication",
    icon: 'error',
    text: "You must be recently logged in to change your password, please log out and try again.",
    showClass: cssShowClass,
    hideClass: cssHideClass
      });
    })
  } else {
    loading.value = false;
  }
});
}

const viewBusinessDetails = () => {
  analytics.value = false;
  PostForm.value = false;
  tableData.value = false;
  aiSession.value = false;
  viewSettings.value = true
}

const appendMessage = (sender, message) => {
  const msgElement = document.createElement('div');
  const chatBox = document.querySelector('.ai-chat-box')
  if(aiTypeChecked.value === true && sender === 'ai') {
    const imgLink = document.createElement('a');
    imgLink.href = message;
    imgLink.target = '_blank';
    imgLink.textContent = 'Click to view & download image.'
    imgLink.style.display = 'inline-block';
    imgLink.style.padding = '10px 15px';
    imgLink.style.margin = '20px';
    imgLink.style.borderRadius = '15px';
    imgLink.style.color = 'black';
    imgLink.style.maxWidth = '70%';
    imgLink.style.alignSelf = 'flex-start';
    imgLink.style.backgroundColor = '#E0E0E0';
    imgLink.style.borderTopLeftRadius = '0px'

    chatBox.appendChild(imgLink)
    chatBox.scrollTop = chatBox.scrollHeight;

    return;
  }
  msgElement.textContent = message;
  msgElement.style.display = 'inline-block';
  msgElement.style.padding = '10px 15px';
  msgElement.style.margin = '20px';
  msgElement.style.borderRadius = '15px';
  msgElement.style.color = 'black';
  msgElement.style.maxWidth = '70%';

  if (sender === 'user') {
    
    msgElement.style.alignSelf = 'flex-end';
    msgElement.style.backgroundColor = '#DCF8C6';
      msgElement.style.borderTopRightRadius = '0px'
  } else {
    msgElement.style.alignSelf = 'flex-start';
    msgElement.style.backgroundColor = '#E0E0E0';
      msgElement.style.borderTopLeftRadius = '0px'
  }
  chatBox.appendChild(msgElement);
  chatBox.scrollTop = chatBox.scrollHeight;
  // msgElement.scrollIntoView({'behavior': 'smooth'});
}

const userQuestion = async () => {
  let msg = document.getElementById('user-prompt').value;
  if(msg === '') {
    return;
  }

  appendMessage('user', msg)
  
  try {
    if(aiTypeChecked.value === false) {
      await getCompletion(msg)
  } else {
    generateAiImage(msg)
  }
    document.getElementById('user-prompt').value = ''
  } catch (error){
  }

}

const generateAiImage = async (userPrompt) => {
  aiThinking.value = true;
  const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`
      }
    };

    const data = {
      model: "dall-e-3",
      prompt: userPrompt,
      n: 1,
      size: "1024x1024"
    };
    try {
      const result = await axios.post('https://api.openai.com/v1/images/generations', data, config);
        let ai_response = result.data.data[0].url;
        appendMessage('ai', ai_response)
        aiThinking.value = false;
      } catch (error) {
        Swal.fire("Something went wrong, prompts should be a single sentence.");
        aiThinking.value = false;
      }
}

// const getCompletion = async (userPrompt) => {
//     aiThinking.value = true;
//       try {
//         const result = await axios.post('https://api.openai.com/v1/chat/completions', {
//           model: "gpt-4o-mini",
//           messages: [
//             { role: "system", content: "You are a helpful assistant that is clear and concise with your words, dont be superfluous with your response." },
//             { role: "user", content: userPrompt },
//           ],
//         }, {
//           headers: {
//             'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
//             'Content-Type': 'application/json'
//           }
//         });

//         // Set the response data
//         let ai_response = result.data.choices[0].message.content;
//         aiThinking.value = false;
//         appendMessage('ai',ai_response)
        
//       } catch (error) {
//         Swal.fire("Something went wrong, try again later.");
//         aiThinking.value = false;
//       }
//     };

const getCompletion = async (userPrompt) => {
  aiThinking.value = true;

  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a helpful assistant with a great positive attitude." },
          { role: "user", content: userPrompt },
        ],
        stream: true,
      })
    });

    if (!response.body) {
      throw new Error('ReadableStream not supported in this browser.');
    }

    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    if (!reader) return false; // Return false or another appropriate value if reader is falsy

    const msgElement = document.createElement('div');
    const chatBox = document.querySelector('.ai-chat-box');
    msgElement.style.display = 'inline-block';
    msgElement.style.padding = '10px 15px';
    msgElement.style.margin = '20px';
    msgElement.style.borderRadius = '15px';
    msgElement.style.maxWidth = '70%';
    msgElement.style.alignSelf = 'flex-start';
    msgElement.style.backgroundColor = '#E0E0E0';
    chatBox.appendChild(msgElement);

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      let dataDone = false;
      const arr = value.split('\n');
      arr.forEach((data) => {
        if (data.length === 0) return; 
        if (data.startsWith(':')) return; 
        if (data === 'data: [DONE]') {
          dataDone = true;
          return;
        }
        const json = JSON.parse(data.substring(6));
        if(json.choices[0].delta.content) {
          msgElement.textContent += json.choices[0].delta.content;
        } else {
          return false;
        }
        msgElement.scrollIntoView({ behavior: 'smooth' });
      });
      if (dataDone) break;
    }

    aiThinking.value = false;
    return true; 

  } catch (error) {
    console.error('Error fetching data from OpenAI:', error);
    aiThinking.value = false;
    return false; 
  }
};



const upgradePlan = (msg) => {
  Swal.fire({
      text: msg,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Upgrade My Account",
      denyButtonText: `No thanks`,
      customClass: {
        title: 'swalTitle'
      },
      showClass: cssShowClass,
      hideClass: cssHideClass,
    }).then((result) => {
      if (result.isConfirmed) {
        router.push('/subscription')
      } else if (result.isDenied) {
       
      }
    });
}


const openChatBox = () => {

  if(subStatus.value === 'Pro Plan') {
    analytics.value = false;
    PostForm.value = false;
    tableData.value = false;
    viewSettings.value = false;
    aiSession.value = true;

    count += 1;
    if(count > 1) {
      return 
    } else {
      getCompletion('Hello there!')
    }
  } else {
    upgradePlan('AI ChatBot is for Pro Plans only. Would you like to upgrade your account?')
  }

}


const getImage = () => {
  document.querySelector('#dropzoneFile').click();
}

const viewCurrentPosts = () => {
  analytics.value = false;
  PostForm.value = false;
  aiSession.value = false;
  viewSettings.value = false;
  tableData.value = true;

}

const viewAnalytics = () => {
  if(subStatus.value === 'Pro Plan' || subStatus.value === 'Standard Plan') {
    aiSession.value = false;
    viewSettings.value = false;
    PostForm.value = false;
    tableData.value = false;
    analytics.value = true;
  } else {
    upgradePlan('Analytics is for Standard & Pro Plans. Would you like to upgrade your account?')
  }

  
}

const spotlight = () => {
  Swal.fire({
  text: "Spotlight feature is coming soon!",
  showClass: cssShowClass,
    hideClass: cssHideClass
});
}

const viewForm = (formType) => {
  if(formType === 'Events') {
    Swal.fire({
  text: "Event feature is coming soon!",
  showClass: cssShowClass,
    hideClass: cssHideClass
});
return false;
  }
  promoCheckboxClicked.value = false;
  updatePost.value = false;
  analytics.value = false;
  aiSession.value = false;
  viewSettings.value = false;
  tableData.value = false;
  selectedOption.value = formType
  PostForm.value = true;
}


const checkMessage = (msg) => {
  Swal.fire({
  title: "From Admin",
  text: msg,
  showClass: cssShowClass,
  hideClass: cssHideClass
});
}

const editPost = (post) => {
updateDocId = post.id
updatePost.value = true;
title.value = post.title;
description.value = post.description;
selectedOption.value = post.postType;
date.value = new Date(post.expireTime);
post.promoCode ? promoCheckboxClicked.value = true : promoCheckboxClicked.value = false;
post.promoCode ? promoCode.value = post.promoCode : null;
post.postType == 'Coupon' ? couponRedemptionTime.value = post.couponRedemption : null;
selectedImage.value = post.imageUrl;
originalImgUrl = post.imageUrl;
imageFile.value = null;
tableData.value = false;
PostForm.value = true;
}










const deleteRedeemedCouponstwo = async (post) => {
  try {
    const docRef = doc(db, 'RedeemedCoupons', post);
    await deleteDoc(docRef);

  } catch (error) {

  }
}

const deleteRedeemedCoupons = async (post) => {
  try {
    const favoritesQuery = query(collection(db, 'RedeemedCoupons'),
                                  where('postId', '==', post ),
                                  where('uid', '==', userIdentify.value));
    const querySnapshot = await getDocs(favoritesQuery);
    const favoritesArray = [];
  
    querySnapshot.forEach(doc => {
      favoritesArray.push({ ...doc.data(), id: doc.id });
    });

    if(favoritesArray.length > 0) {
      await Promise.all(favoritesArray.map(async (item) => {
      await deleteRedeemedCouponstwo(item.id);
    }));
    } else {
      return;
    }
  } catch (error) {

  }
}

const deleteFavClickstwo = async (post) => {
  try {
    const docRef = doc(db, 'FavClicks', post);
    await deleteDoc(docRef);

  } catch (error) {

  }
}

const deleteFavClicks = async (post) => {
  try {
    const favoritesQuery = query(collection(db, 'FavClicks'),
                                  where('postId', '==', post ),
                                  where('uid', '==', userIdentify.value));
    const querySnapshot = await getDocs(favoritesQuery);
    const favoritesArray = [];
  
    querySnapshot.forEach(doc => {
      favoritesArray.push({ ...doc.data(), id: doc.id });
    });

    if(favoritesArray.length > 0) {
      await Promise.all(favoritesArray.map(async (item) => {
      await deleteFavClickstwo(item.id);
    }));
    } else {
      return;
    }
  } catch (error) {

  }
}


const deleteCouponsTwo = async (coupon) => {
  try {
    const docRef = doc(db, 'Coupons', coupon);
    await deleteDoc(docRef);

  } catch (error) {
    console.error(`Failed to delete favorite with id: ${coupon}`, error);
  }
}


const deleteFavoritesTwo = async (post) => {
  try {
    const docRef = doc(db, 'Favorites', post);
    await deleteDoc(docRef);

  } catch (error) {
  }
}

const deleteCoupons = async (docId) => {
  try {
    const favoritesQuery = query(collection(db, 'Coupons'),
                                  where('postId', '==', docId ),
                                  where('uid', '==', userIdentify.value));
    const querySnapshot = await getDocs(favoritesQuery);
    const favoritesArray = [];
  
    querySnapshot.forEach(doc => {
      favoritesArray.push({ ...doc.data(), id: doc.id });
    });

    if(favoritesArray.length > 0) {
      await Promise.all(favoritesArray.map(async (item) => {
      await deleteCouponsTwo(item.id);
    }));
    } else {
      return;
    }
  } catch (error) {
  }
}

const deleteFavoritesOne = async (docId) => {
  try {
    const favoritesQuery = query(collection(db, 'Favorites'),
                                  where('postId', '==', docId ),
                                  where('uid', '==', userIdentify.value));
    const querySnapshot = await getDocs(favoritesQuery);
    const favoritesArray = [];
  
    querySnapshot.forEach(doc => {
      favoritesArray.push({ ...doc.data(), id: doc.id });
    });


    if(favoritesArray.length > 0) {
      await Promise.all(favoritesArray.map(async (item) => {
      await deleteFavoritesTwo(item.id);
    }));
    } else {
      return;
    }
  } catch (error) {
  }
}




const deletePost = async(post) => {

  Swal.fire({
  title: `Delete ${post.title}`,
  text: "You won't be able to revert this.",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Delete",
  showClass: cssShowClass,
  hideClass: cssHideClass
}).then(async (result) => {
  if (result.isConfirmed) {
    deleteLoading.value = true;
    let docId = post.id;
    try {
      await deleteRedeemedCoupons(post.id)
      await deleteFavClicks(post.id)
      await deleteFavoritesOne(post.id);
      await deleteCoupons(post.id);
      await deleteDoc(doc(db, `CompanyPosts/${docId}`)).then(() => {
        const promos = currentPromos.value.filter(item => item.id !== docId)
            currentPromos.value = promos
            deleteLoading.value = false;
            toast("Post Removed.", {
                "type" : "success",
                "transition": "zoom",
                autoClose: 2000
                })
      })
    } catch {
      deleteLoading.value = false;
      toast("Error. Try again.", {
                "type" : "error",
                "transition": "bounce",
                autoClose: 2000
                })
    }
  }
});
}





const progressWidth = computed(() => {
  `${progress.value}%`
  if(progress.value == 100) {
    progressComplete.value = true;
  }
});




const imageDrop = async (event) => {
  newImage.value = true;
toggleActive();
let file = event.dataTransfer.files[0]
if(!file.type.startsWith('image/')) {
  Swal.fire({
  title: "Oops",
  text: "Please select an image file.",
  icon: "info"
});
  return;
}
imageFile.value = event.dataTransfer.files[0]
if(file) {
  const reader = new FileReader();
    reader.onload = (e) => {
      selectedImage.value = e.target.result;
    };
    reader.readAsDataURL(file);
}

} 


const toggleActive = () => {
  dzActive.value = !dzActive.value
}


const handleFileInputChange = async (event) => {
  newImage.value = true;
  imageFile.value = event.target.files[0];

 
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      selectedImage.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const formattedPhoneNumber = computed(() => {
  const areaCode = phone.value.substring(0, 3);
  const firstPart = phone.value.substring(3, 6);
  const secondPart = phone.value.substring(6, 10);
  return `(${areaCode}) ${firstPart}-${secondPart}`;
});



const createFavClick = async(postId, title) => {
  await addDoc(collection(db, 'FavClicks',), {
    title: title,
    postId: postId,
    uid: auth.currentUser.uid,
    clicks: 0,
  })
}

const createRedeemedCoupons = async(postId, title) => {
  await addDoc(collection(db, 'RedeemedCoupons',), {
    title: title,
    postId: postId,
    uid: auth.currentUser.uid,
    redemptions: 0,
  })
}

const sendEmail = async () => {
  const response = await axios.post('https://nodemailer-gold.vercel.app/sendEmail', {
    data: 'Some json data...'
  });
}


const createNewPost = async (downloadURL) => {
  if(updatePost.value == true) {
    try{
    await updateDoc(doc(db, `CompanyPosts`, updateDocId ), {
      business: business.value,
      category: category.value,
      address: address.value,
      coordinates: coords.value,
      phone: phone.value,
      headline: headline.value,
      website: website.value,
      title: title.value,
      description: description.value,
      imageUrl: downloadURL,
      postType: selectedOption.value,
      promoCode: promoCheckboxClicked ? promoCode.value : null,
      couponRedemption: selectedOption.value == 'Coupon' ? couponRedemptionTime.value : null,
      expireTime: date.value.toLocaleString(),
      approved: false,
      uid: userIdentify.value,
      updated: true,
      denied: false,
      deniedMessage: '',
      date: serverTimestamp(),
    }).then(async () => {
      loading.value = false;
      postCreated.value = true;
      viewCreatePostContainer.value = true;
      title.value = '';
      description.value = '';
      selectedImage.value = null;
      imageFile.value = null;
      couponRedemptionTime.value = null;
      promoCheckboxClicked.value = false;
      selectedOption.value = ''
      promoCode.value = null;
      await sendEmail().catch((error) => console.log('email failed.'))
      await getPendingPromos();
      pendingPost.value = true;
    });
  } catch (error){
    loading.value = false;
    viewCreatePostContainer.value = false;
    Swal.fire("Something went wrong, try again.")
  }
  }
  else {
    try{
    await addDoc(collection(db, `CompanyPosts`), {
      business: business.value,
      category: category.value,
      address: address.value,
      coordinates: coords.value,
      phone: phone.value,
      headline: headline.value,
      website: website.value,
      title: title.value,
      description: description.value,
      imageUrl: downloadURL,
      postType: selectedOption.value,
      promoCode: promoCheckboxClicked ? promoCode.value : null,
      couponRedemption: selectedOption.value == 'Coupon' ? couponRedemptionTime.value : null,
      expireTime: date.value.toLocaleString(),
      approved: false,
      uid: userIdentify.value,
      denied: false,
      deniedMessage: '',
      date: serverTimestamp(),
    }).then(async(post) => {
      
      await sendEmail().catch((error) => console.log('email failed.'))
      await createFavClick(post.id, title.value)
      if(selectedOption.value === 'Coupon') {
        await createRedeemedCoupons(post.id, title.value)
      }
      loading.value = false;
      postCreated.value = true;
      viewCreatePostContainer.value = true;
      title.value = '';
      description.value = '';
      selectedImage.value = null;
      imageFile.value = null;
      couponRedemptionTime.value = null;
      promoCheckboxClicked.value = false;
      selectedOption.value = ''
      promoCode.value = null;
      getPendingPromos();
      viewPromoContainer()
      
    });
  } catch (error){
    loading.value = false;
    viewCreatePostContainer.value = false;
    Swal.fire("Something went wrong, try again.")
  }
  }
}


const storeTheImage = async () => {
    loading.value = true;
    if(newImage.value == false) {
      createNewPost(originalImgUrl);
      return;
    }
    viewUploadContainer.value = true;

    const file = imageFile.value
    const storage = getStorage();
    const storeRef = storageRef(storage, `promoImages/${business.value}/${file.name}`);
    const uploadTask = uploadBytesResumable(storeRef, file);
    uploadTask.on('state_changed', 
      (snapshot) => {
        //  Observe state change events such as progress, pause, and resume
        //  Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // imageLoading.value = true;
        // let uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // uploadProgress = uploadProgress.toPrecision(3)
        // progress.value = uploadProgress
        // /console.log('Upload is ' + progress.toPrecision(3) + '% done');
        // switch (snapshot.state) {
        //   case 'paused':
        //     break;
        //   case 'running':
        //     break;
        // }
      }, 
      (error) => {
        // Handle unsuccessful uploads
        loading.value = false;

      }, 
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log('File available at', downloadURL);
          // imageDownloadUrl.value = downloadURL;
          createNewPost(downloadURL);
        });

      
      
      }
    );

    ////////////////////////////////////////////////////////////////

  //   await getDownloadURL(storageRef(storage, `promoImages/${business.value}/${file.name}`))
  //  .then((url) => {
  //   console.log(`Download url: ${url}`)
  //  })
  //  .catch((error) => {
  //   console.log(error.code)
  //  })
}

const cancelPost = () => {
  submitPost.value = false;
  previewForm.value = true;
}

const checkSubmitPost = () => {
  previewForm.value = false;
  promoContainer.value = false;

  Swal.fire({
  title: "Upload Post?",
  showCancelButton: true,
  confirmButtonText: "Submit",
  showClass: cssShowClass,
  hideClass: cssHideClass,
}).then((result) => {
  if (result.isConfirmed) {
    confirmPost()
  } else if (result.isDenied) {
    cancelPost()
  }
});

}



const confirmPost = async () => {
    submitPost.value = false;
    previewForm.value = false;
    closePromoContainer();
    await storeTheImage().catch(() => {
      Swal.fire({
        text: 'Something went wrong, try again.'
      })
    })
}



const viewPromoContainer = () => {
  if(loading === true) {
    return false;
  }
  pendingPost.value = false;
  updatePost.value = false;
  firstForm.value = false;
  viewFormTwo.value = false;
  formThree.value = false;
  previewForm.value = false;
  promoContainer.value = true;
  viewFormType.value = true;
  tableData.value = false;
}


const closePromoContainer = () => {
  selectedImage.value = null;
  promoContainer.value = false;
  firstForm.value = false;
  viewFormTwo.value = false;
  formThree.value = false;
  previewForm.value = false;
}

const testSpecialUser = (uid) => {
  const uidArray = ['G1BgX25eD4QgVXIDix7Eg9WPSfI2', 'q69fgpRfQQh5XnP9w07O15CLIxZ2', 'pNvUqLp58rOBuKjkUklsldzkaIg1', 'ocz8ONseuBeYadHLVZDd5P8ekjE3',
    'HvGNXfWBP8eiSjCsq2Fd7KJTcDB3', 'X5RHJlsI8ZTMPyml04WoEBLXGWZ2', 'mdq1TZSIbKQMqmNGV5HNWTnkhQ53', 'NEX94H1QL1VecYadsocEQgETR0Q2', '3bn166acoEgn4idr9wsykzHlx913',
    'HMFkb0szxxdk9uQr25Olw6E5Chg2', 'M8YzXKpkVvcLtGjK0DYcIoj7tUy2'
  ];
  return uidArray.includes(uid);
};

const getSubscriptionStatus = async (uid) => {
      try {
        const subscriptionsRef = collection(db, 'customers', uid, 'subscriptions');
        const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));
        const subscriptionSnapshot = await getDocs(q);
        if(!subscriptionSnapshot.empty) {
           return true;
           
        } else {
            return false;
        }
      } catch (error) {
        alert('Something went wrong..try again.')
      }
    }



const readDocument = async (userId) => {
   try{
    const querySnapshot = await getDocs(collection(db, `Company/${userId}/Docs`));
     querySnapshot.forEach((doc) => {
      docId = doc.id;
      const data = doc.data();
      business.value = data.business;
      email.value = data.email;
      phone.value = data.phone;
      address.value = data.address
      website.value = data.website
      category.value = data.category
      coords.value = data.coordinates
      headline.value = data.headline
    });
    console.log(`Headline: ${headline.value}`);
   }
   catch(error) {
   }
  }



const getPendingPromos = async () => {
  const pendingQuery = query(collection(db, 'CompanyPosts'),
                              where('approved', '==', false),
                              where('uid', '==', userIdentify.value));
  const querySnapshot = await getDocs(pendingQuery);
  const pendingArray = [];
  const deniedArray = [];
  
  querySnapshot.forEach(doc => {
    const postData = {...doc.data(), id: doc.id};
    if (postData.denied === true) {
      deniedArray.push(postData);
    } else {
      pendingArray.push(postData);
    }
  });

  if (pendingArray.length > 0) {
    pendingPromos.value = pendingArray;
  }

  if (deniedArray.length > 0) {
    deniedPromos.value = deniedArray;
  }
  if(deniedArray.length == 0) {
          deniedPromos.value = []
        }
};



const loadRedeemedCouponChart = () => {
  if (redeemedCoupons.value !== null && redeemedCoupons.value.length > 0) {
    const titles = redeemedCoupons.value.map(coupon => coupon.title);
    const redeemedCounts = redeemedCoupons.value.map(coupon => coupon.redemptions);
   



    const backgroundColors = [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(153, 102, 255)',
      'rgb(255, 159, 64)',
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)'
    ];

    const ctx_two = document.getElementById('couponChart');
    new Chart(ctx_two, {
      type: 'pie',
      data: {
        labels: titles,
        datasets: [{
          label: 'Coupons Redeemed',
          data: redeemedCounts,
          backgroundColor: backgroundColors.slice(0, titles.length),
        }]
      },
      options: {
        responsive: true,
      }
    });
  }
}




const loadFavChart = () => {
  if (favItems.value !== null) {
    const ctx = document.getElementById('myChart');
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: favItems.value.map(item => item.title), // Array of titles
        datasets: [{
          label: 'Saved Posts',
          data: favItems.value.map(item => item.clicks), // Array of clicks
          backgroundColor: favItems.value.map((item, index) => `hsla(${191 + index * 30}, 70%, 57%, 0.3)`),
          borderColor: favItems.value.map((item, index) => `hsla(${191 + index * 30}, 70%, 57%, 1)`),
          hoverBackgroundColor: favItems.value.map((item, index) => `hsla(${191 + index * 30}, 70%, 57%, 0.8)`),
          hoverBorderColor: favItems.value.map((item, index) => `hsla(${191 + index * 30}, 70%, 57%, 1)`),
          borderWidth: 2
        }]
      },
      options: {
        responsive: false,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}


  onMounted(async () => {

    const sideBarTabs = document.querySelectorAll('.theSideBar div') 
    sideBarTabs.forEach(tab => {
      tab.addEventListener('click', () => {
      
      });
    });

    const getSubDetails = async () => {
                    try {
                        const subDetailRef = collection(db, 'customers', user.uid, 'subscriptions');
                        const snapShot = await getDocs(subDetailRef);
                        snapShot.forEach( doc => {
                            let document = doc.data();
                            subStatus.value = document.items[0].price.product.name
                        })
                        loading.value = false;
                    } catch (error) {
                        loading.value = false;
                        toast("Something went wrong. Try again.", {
                            type: "error",
                            transition: "zoom",
                            autoClose: 2000
                        });
                    }
                }

  
    const monthlyLineChartFunc = () => {
      const labels = ['January', 'February', 'March', 'April', 'May', 'June',
                    'July', 'August', 'September', 'October', 'November', 'December' ];

        const monthlyData = [0,0,0,0,0,1500, 4600];

        var options = {
          chart: {
            height: 300,
            type: "area"
          },
          dataLabels: {
            enabled: false
          },
          series: [
            {
              name: "Monthly Views",
              data: monthlyData
            }
          ],
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
          },
          xaxis: {
            categories: labels
          }
        };

        var chart = new ApexCharts(document.querySelector("#monthlyLineChart"), options);

        chart.render();

        }

        function counter(id, start, end, duration) {
          let obj = document.getElementById(id),
          current = start,
          range = end - start,
          increment = end > start ? 1 : -1,
          step = Math.abs(Math.floor(duration / range)),
          timer = setInterval(() => {
            current += increment;
            obj.textContent = current;
            if (current == end) {
            clearInterval(timer);
            }
          }, step);
        }


    const getAnalytics = async () => {
      loading.value = true;
      try {
        const response = await axios.get(`https://express-tester-ten.vercel.app/catFacts`);
        if(response.status === 200) {
          loading.value = false;
       
          const activeUsers = response.data.rows[0].metricValues[0].value
          const dailyPageViews = response.data.rows[0].metricValues[1].value
          const events = response.data.rows[0].metricValues[2].value
          console.log(activeUsers)

           
                // counter("userAnalytics", 0, activeUsers, 500);
                counter("engagementAnalytics", 0, dailyPageViews, 500);
                counter("eventAnalytics",0, events, 500 )
        }
     
          }
         catch (error) {
          loading.value = false;
        }
    }

    const getRedeemedCoupons = async () => {
      const count = query(collection(db, 'RedeemedCoupons'),
                          where('uid', '==', userIdentify.value))
                          const snapshot = await getDocs(count);
                          const redeemedArray = []
                          snapshot.forEach(doc => {
                           redeemedArray.push({...doc.data()})
                          })

                          redeemedCoupons.value = redeemedArray
    }
   

    const getFavCount = async () => {
      const count = query(collection(db, 'FavClicks'),
                          where('uid', '==', userIdentify.value))
                          const snapshot = await getDocs(count);
                          const favArray = []
                          snapshot.forEach(doc => {
                           favArray.push({...doc.data()})
                          })

                          favItems.value = favArray
    }
 

    const getPendingPromos = async () => {
      const pendingQuery = query(collection(db, 'CompanyPosts'),
                                  where('approved', '==', false),
                                  where('uid', '==', userIdentify.value));
      const querySnapshot = await getDocs(pendingQuery);
      const pendingArray = [];
      const deniedArray = [];

          querySnapshot.forEach(doc => {
          pendingArray.push({ ...doc.data(), id: doc.id });
        const postData = {...doc.data(), id: doc.id};
        if (postData.denied === true) {
          deniedArray.push(postData);
        } 
      });

        if (pendingArray.length > 0) {
          pendingPromos.value = pendingArray;
          pendingPost.value = true;
        }

        if (deniedArray.length > 0) {
          deniedPromos.value = deniedArray;
        }
        if(deniedArray.length == 0) {
          deniedPromos.value = []
        }
        }

    const getCurrentPromos = async () => {
      const companyQuery = query(collection(db, `CompanyPosts`), 
                                    where('approved', '==', true),
                                    where('uid', '==', userIdentify.value));
        const querySnapshot = await getDocs(companyQuery);
        
        const emptyArray = [];
        querySnapshot.forEach(doc => {
            emptyArray.push({ ...doc.data(), id: doc.id });
        });
        currentPromos.value = emptyArray;
        if(currentPromos.value.length > 0) {
          counter("postAnalytics",0, currentPromos.value.length, 500 )
        }
    }

       
      async function loadCharts() {
        if(freeUser.value === true) {
          subStatus.value = 'Pro Plan'
          analytics.value = true;
            getAnalytics();
            await getFavCount();
            loadFavChart();
            monthlyLineChartFunc();
            await getRedeemedCoupons();
            loadRedeemedCouponChart();
          return;
        }
      await getSubDetails();

        if (subStatus.value === 'Pro Plan' || subStatus.value === 'Standard Plan') {
            analytics.value = true;
            getAnalytics();

            await getFavCount();
            loadFavChart();

            monthlyLineChartFunc();

            await getRedeemedCoupons();
            loadRedeemedCouponChart();
        } else {
          PostForm.value = true;
        }
    }
    const specialSubSatus = testSpecialUser(user.uid);
    if(specialSubSatus === true) {
      freeUser.value = true;
    }
    console.log('Sub Status: ', specialSubSatus)
    const subscriptionStatus = await getSubscriptionStatus(user.uid);

      if(subscriptionStatus === true || specialSubSatus === true) {
        loadCharts();
        getCurrentPromos();
        getPendingPromos();
      } else {
        router.replace('/subscription')
      }
 

  })


  onBeforeMount( () => {
    const auth = getAuth();
    const userID = auth.currentUser.uid
    userIdentify.value = userID;

    readDocument(userID);
    

   
  })


</script>

<style scoped>


body {
  background-color: ghostwhite;
  opacity: 0;
  animation: fade 500ms linear forwards;
}

@keyframes fade {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

.appStability {
  position: relative;
}

#loading-container {
  position: absolute;
  z-index: 1;
  top:0;
  left:0;
  width:100%;
  background:white;
  height:5px;
}

#loader-bar {
  position: absolute;
  top: 0;
  left: -200px; /* Start the bar off-screen */
  width: 200px;
  border-radius: 15px;
  height: 5px; /* Same as loader container height */
  background-color: #11ff09; /* Bar color */
  animation: slide 750ms linear infinite;
  z-index: 10;
}

@keyframes slide {
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
}



.loadingHolder {
    inset: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1000;
  }

  .custom-loader {
      width:40px;
      height:40px;
      border-radius:50%;
      background:conic-gradient(#0000 10%,#FFFFFF);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 0.5s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}


a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

a:first-child {
  margin-top: 25px; 
}

.loaderIcon {
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

.hidePromo {
  visibility: hidden;
}

.createPost {
  position: absolute;
  top:30px;
  right:25px;
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap:20px;
  z-index:1000;
}

.hideButton {
  display: flex;
}

.visible {
  visibility: visible;
  }



.custom {
  grid-row-start: 3;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 3;
}

.gridContainer{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap:1.5rem;
  min-height:100vh;
  padding: 25px;
  opacity: 0;
  animation: fade 500ms linear forwards;
}

.settingsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap:1.5rem;
  overflow: hidden;
  padding: 25px;
  min-height: 50vh;
  opacity: 0;
  animation: fade 500ms linear forwards;
}

.promoContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap:1.5rem;
  overflow: hidden;
  padding: 25px;
  min-height: 100vh;
  opacity: 0;
  animation: fade 500ms linear forwards;
}

@keyframes fade {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
  
}



.grid, .gridPromo, .biz-grid {
  background-color: rgb(255, 255, 255);
  min-height: 150px;
  border-radius: 15px;
  padding:20px 20px;
  color: rgb(0, 0, 0);
  position: relative;
  transition: 400ms ease-in-out;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);

}

.gridPromo {
  max-height: 700px;
  overflow-y: scroll;
}


.gridPromo:nth-child(1) p:nth-child(2) {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridPromo:nth-child(1) {
  grid-row: span 1;
  grid-column: span 1;
  overflow: hidden;
}

.gridPromo:nth-child(2) {
  grid-row: span 1;
  grid-column: span 3;
}

.gridPromo:nth-child(3) {
  grid-row: span 2;
  grid-column: span 2;
}

.gridPromo:nth-child(4) {
  grid-row: span 3;
  grid-column: span 2;
}

.swalTitle {
  font-weight: 500;
  font-size: 1rem;
}


.step-one, .step-two, .step-three {
  position: absolute;
  top:20px;
  right:20px; 
  color:rgb(150, 150, 150);
}

.grid p {
  color: rgb(68, 68, 68);
}

.grid p {
  margin-top: 20px;
  font-size: 0.8rem;
  line-height: 1.6;
}

.grid p:first-child, .gridPromo p:first-child, .biz-grid p:first-child{
  margin-top: 10px;
  color: #5c5c5c;
  font-size: 1.2rem;
}

.biz-grid:first-child {
  grid-column: span 1;
  grid-row: span 1
}

.biz-grid:nth-child(2) {
  grid-column: span 1;
  grid-row: span 1;
}

.biz-grid:nth-child(3) {
  grid-column: span 2;
  grid-row: span 3;
}

.grid:first-child {
 grid-column: span 1;
 grid-row: span 1;
}

.grid:nth-child(2) {
  grid-column: span 2;
  grid-row: span 1;
}

.grid:nth-child(3) {
  grid-column: span 1;
  grid-row: span 1;
}

.grid:nth-child(4) {
  grid-column: span 1;
  grid-row: span 1
}




 




 .credentialContainer, .promotionContainer, .second-form-container {
  border-radius: 8px;
  width: 750px;
  height: 700px;
  background-color: rgb(255, 255, 255);
  margin:15px auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  animation-name: fade-in;
  animation-duration: 500ms;
  outline: thin solid rgb(221, 221, 221);
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  animation: fade-in 500ms;
}

.editPosts {
  width: 100%;
  height: 100%;
  z-index: 10;
}

/* .editPosts ul:nth-child(odd) {
  background-color: rgb(248,248,248);
} */

.approved-ul {
  min-height:75px;
  max-height: 75px;
}

.approved-ul:nth-child(odd) {
  background-color: rgb(250,250,250)
}

.editPosts h2 {
  padding-inline: 25px;
  padding-block: 25px;
  column-rule: navy;
  font-size: 2rem;
}

.edit-ul {
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
  margin: 0;
  list-style-type: none;
}


#pen {
  color: limegreen;
  transition: 400ms ease-in-out;
}

#pen:hover {
  color: #6dfc71;
}

#trash {
  color: #b11414;
  transition: 400ms ease-in-out;
}

#trash:hover {
  color: red;

}




.flex-item {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .flex-item {
  font-weight: bold;
}

.tableBtn {
  background-color: rgb(89, 73, 194); 
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: 500ms ease;
}





.loadingContainer {
  width: 350px;
  height: 400px;
  background-color: rgb(234, 252, 236);
  border-radius: 15px;
  position: fixed;
  z-index: 10;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  animation-name: fade-in;
  animation-duration: 500ms;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
}

label {
      display: block;
      text-align: left;
      padding-bottom: 15px;
      color: rgb(133, 133, 133);
      padding-top: 15px;
      font-size: 1rem;
    }
    
    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea {
      width: 100%;
      padding: 15px;
      border-radius: 5px;
      border:none;
      outline: 1px solid gainsboro;
      background-color: ghostwhite;
    }

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.highLightedTab {
  background-color: ghostwhite;
  color: black;
}


 .credentialContainer img {
  position: absolute;
  top:15px;
  right:25px;
  padding: 10px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
}

.loadingContainer .closeButton {
  position: absolute;
  top:15px;
  right:25px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor:pointer;
  height:15px;
  width:15px;
}


.promotionContainer .closeButton, .second-form-container .closeButton {
  position: absolute;
  top:15px;
  right:25px;
  padding: 10px;
  background-color: rgba(98, 98, 98, 0.5);
  border-radius: 50%;
}
.promotionContainer .closeButton:hover, .second-form-container .closeButton:hover {
  cursor: pointer;
}

 .credentialContainer img:hover, .promotionContainer img:hover {
  cursor: pointer;
}

.grid-opacity {
  filter: brightness(0.3);
  z-index: -10;
}

.second-form-container button {
  background-color: rgb(89, 73, 194); 
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: 500ms ease;
}

 .credentialContainer button, .promotionContainer button{
  background-color: rgb(89, 73, 194); 
      color: #fff;
      margin:40px auto;
      padding: 10px 20px;
      border: none;
    font-weight: 500;
      border-radius: 5px;
      cursor: pointer;
      width: 50%;
      font-size: 14px;
      transition: 0.5s ease-in-out;
  transition: 500ms ease;
}
 .credentialContainer button:hover, .promotionContainer button:hover, .second-form-container button:hover {
  transform: scale(0.95);
}

#businessCategory option[disabled] {
  color: #999; /* Change the color of the placeholder text */
  font-style: italic; /* Optionally, apply italic style to the placeholder text */
}

.drag-drop-zone {
  display: flex;
  position: relative;
  margin: 20px auto;
  border: 1px dotted indigo;
  height:300px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:25px;
  background: white;
  transition: 0.3s ease-in-out;
}

.hasImage {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.submittedImage {
  position: absolute;
  inset:0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.active-dropzone {
  background: indigo;
  color: white;
}

.drag-drop-zone label:hover {
  cursor: pointer;
}


.drag-drop-zone .cloudUp {
  font-size: 35px;
  color: rgb(207, 207, 207);
}

/* [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
} */



.custom-select {
  width: 100%;
  position: relative;
}

select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
  appearance: none;
  -webkit-appearance: none;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
  outline: none;
  border: none;
}

textarea {
  resize: none;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}


.imagePreviewContainer {
          position: fixed;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            height:100vh;
            width: 100vw;
            background:rgba(0,0,0,0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
}

.imagePreviewContainer h1 {
  position: absolute;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  left:50%;
  top:8%;
}

.imagePreviewContainer h5 {
  position: absolute;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  left:50%;
  bottom:0%;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 300ms ease-in-out
}






.imagePreview {
            position: relative;
            height:650px;
            width: 100%;
            background-color: white;
            border-radius: 3px;
            outline: 1px solid gainsboro;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        }
        .greenCheckMark {
            position: absolute;
            right:-20px;
            top:-20px;
            height:40px;
            width:40px;
            border-radius: 50%;
            background-color: rgb(42, 172, 42);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            transition: 0.3s ease-in-out;
        }
        .greenCheckMark:hover {
            background-color: rgb(59, 236, 59);
        }
        .cancelButton {
            position: absolute;
            right:25px;
            top:-20px;
            height:40px;
            width:40px;
            border-radius: 50%;
            background-color: rgb(141, 30, 30);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            transition: 0.3s ease;
        }
        .cancelButton:hover {
            background-color: rgb(209, 44, 44)
        }

        .cancelButton, .greenCheckMark:hover {
            cursor: pointer;
        }

        .imagePreview .promoImg {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            height:35%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-bottom: 1px solid gainsboro;
        }
        
  
        .imagePreview h3 {
            font-size: 1.4em;
            padding-block: 10px;
        }
        .imagePreview h2 {
            font-size: 1.6em;
            padding-block: 10px;
        }

        .imagePreview span {
            margin: 5px auto;
        }

        .imagePreview .timerContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap:20px;
          position:absolute;
          top:30%;
          left:50%;
          min-width: 300px;
          max-width: 300px;
          transform: translate(-50%, 0%);
          padding:3px;
          background-color: rgb(158, 52, 52);
          border-radius: 5px;
          color: white;
          box-shadow: 0 0 20px rgba(0,0,0,0.6);
        }

        .imagePreview .timerContainer .microContainer {
          display: flex;
          flex-direction:column;
        }
        .imagePreview .timerContainer .microContainer p:nth-child(1) {
          padding-left: 15px;
          color:rgb(255, 255, 255);
          letter-spacing: 1px;
        }
        .imagePreview .timerContainer .microContainer p:nth-child(2) {
         font-weight: bold;
        }

            .imagePreview #timer {
              padding:5px;
                background:gainsboro;
                border-radius: 5px;
                min-width: 250px;
                margin: 0px auto;
                color: rgb(65, 65, 65);
                text-align: center;
                position: absolute;
                top:212px;
                left:50%;
                transform: translate(-50%, 0%);
                font-size: 0.8rem;
                font-weight: 500;
                box-shadow:  0 0 2px rgba(0, 0, 0, 0.3);
            }

            .imagePreview div:nth-child(1) {
                width: 200px;
                margin: 0px auto;
            }

            .imagePreview hr {
                width: 75%;
                margin: 10px auto;
          
            }

          

            .imagePreview .couponButton{
               
                background: #1670e6;
                border-radius: 20px;
                outline: none;
                border: none;
                color: white;
                padding-block: 12px;
                padding-inline: 100px;
                width: 300px;
                text-align: center;
                font-weight: 600;
                text-transform: uppercase;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
                justify-content: center;
                align-items: center;
                margin: 10px auto;
                transition: 0.3s ease-in-out;
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translate(-50%, 0%);
            }

            .imagePreview .couponButton:hover {
                background:#104d9c;
                box-shadow: 0 0 5px rgba(0,0,0,0.9);
                cursor: pointer;
            }


            #descriptionText {
                padding-inline: 25px;
                color: rgb(46, 46, 46);
             
            }

      
           .promotionContainer .custom-select {
            width: 90%;
            position: relative;
          }

select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
  appearance: none;
  -webkit-appearance: none;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}

.progress-container {
  margin-top: 15px;
  width: 90%;
  height: 30px;
  background-color: #cacaca;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #65ec6a;
  transition: width 0.3s ease;
}

  
.submitPostQuestion {
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  height:400px;
  width:600px;
  background: ghostwhite;
  border-radius: 25px;
  padding:25px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
  animation-name: fade-in;
  animation-duration: 500ms;
  box-shadow: 0 0 15px rgba(0,0,0,0.7);
}

.submitPostQuestion .postButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:100px;
}

.submitPostQuestion .postButtonContainer button:nth-child(1)
{
  background-color: rgb(89, 73, 194); 
  padding-inline: 25px;
  padding-block: 10px;
  border-radius: 5px;
  color:white;
  outline:none;
  border:none;
  font-size: 1.4rem;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  cursor: pointer;
}

.submitPostQuestion .postButtonContainer button:nth-child(2)
{
  background-color: #b11414;
  padding-inline: 25px;
  padding-block: 10px;
  border-radius: 5px;
  color:white;
  outline:none;
  border:none;
  font-size: 1.4rem;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  cursor:pointer;
}
           


@media(max-width:850px) {
  .gridContainer, .settingsContainer, .promoContainer {
    display: flex;
    flex-direction: column;
  }


  .grid, .biz-grid, .gridPromo {
    height: auto;
    width:100%;
  }

  .gridPromo li {
    font-size: 0.7rem;
  }



}


.dashSideBar {
    width: 100%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    transition: 300ms ease;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    z-index: 0;
}


.faNav {
   color: #3e8de7;
   opacity: 0.9;
    font-size: 1.3rem;
    transition: 300ms;
    padding:15px;
    border-radius: 50%;
    background-color: #eeeeee;
}



.faNav:hover {
    animation: rotate 500ms forwards;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
      
    }
    50% {
        transform: rotate(10deg);
     
    }
    100% {
        transform: rotate(-25deg);
     
    }
}


.iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.iconText {
    font-size: 0.7rem;
    margin-top: 10px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
}

@media(max-width: 840px) {
  .iconText {
    display: none;
  }
}




.selectPostType {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:25px;
  position: relative;
}



.selectPostType h4 {
  color: #858585;
  padding-right: 15px;
}

.PostContainerHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin:25px;
  padding-bottom: 15px;
  border-bottom: thin gainsboro solid;
  position: relative;
}

.PostContainerHolder p {
  padding-top: 15px;
  font-size: 0.9rem;
  display: none;
}

  .PostContainerHolder.clicked p {
    display: block; 
    transition:  0.3s ease-in-out; 
    overflow: hidden; 
  }

.PostContainerHolder button {
  width: 200px;
  padding-block: 10px;
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.6);
  transition: 500ms ease;
  display: none;
}

.PostContainerHolder.clicked button {
  display: block;
}

.PostContainerHolder button:hover {
  box-shadow: 0px 0px;
  transform: scale(0.95);
}

.selectPostType .PostContainer {
  width:100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}



.theSideBar {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 80px;
  max-width:200px;
  height: auto;
  background-color: rgb(97, 9, 240); 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-right: 1px solid gainsboro;
  transition: width 300ms ease-in-out;
  z-index: 100;
}

.theSideBar:hover {
  width: 200px;
}

.theSideBar > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px;
  transition: 200ms ease-in-out;
  width: 100%;
  cursor: pointer;
  padding-left: 30px;
  color: white;
}

.theSideBar > div:hover {
  background: ghostwhite;
  color: black;
}

.theSideBar > div > span {
  opacity: 0;
  font-weight: 500;
  font-size: 0.9rem;
}

.theSideBar:hover > div > span {
  animation:fade-in 100ms linear forwards;
  margin:0;
  padding:0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}


.dashboardContainer {
  display: flex;
  flex-direction: row;
}


.slide-container {
  margin:50px auto;
  width: 100%;
  background: #fff;
  padding: 30px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


@keyframes left {
    from {
      transform: translateX(-500px);
    }
    to {
      transform:translateX(0px);
    }
  }

.slide-nav {
  position: relative;
  height: 60px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-nav::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
  top: 58px;
}
.slide-container .slide-nav label {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.3s;
}

input[type="radio"] {
  display: none;
}
.slide-container .slider {
  position: absolute;
  height: 4.5px;
  background-color: rgb(89, 73, 194);
  border-radius: 20px;
  width: 33%;
  z-index: 1;
  left: 2%;
  bottom: 0;
  transition: all 0.3s;
  filter: blur(0.4px);
}
#home:checked ~ .slide-nav .slider {
  left: 2%;
}
#features:checked ~ .slide-nav .slider {
  left: 35%;
}
#blogs:checked ~ .slide-nav .slider {
  left: 67%;
}

.slide-tab {
  height: 150px;
}
.slide-tab h5 {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 30px;
  padding-bottom: 50px;
}

.slide-tab p {
  text-align: left;
  max-width: 500px;
}



.features-tab,
.blogs-tab,
.about-tab,
.contact-tab {
  display: none;
}

#features:checked ~ .tab-section .home-tab {
  display: none;
}
#features:checked ~ .tab-section .features-tab {
  display: block;
}
#blogs:checked ~ .tab-section .features-tab,
#blogs:checked ~ .tab-section .home-tab {
  display: none;
}
#blogs:checked ~ .tab-section .blogs-tab {
  display: block;
}
#about:checked ~ .tab-section .features-tab,
#about:checked ~ .tab-section .home-tab,
#about:checked ~ .tab-section .blogs-tab,
#about:checked ~ .tab-section .contact-tab {
  display: none;
}
#about:checked ~ .tab-section .about-tab {
  display: block;
}
#contact:checked ~ .tab-section .features-tab,
#contact:checked ~ .tab-section .home-tab,
#contact:checked ~ .tab-section .blogs-tab,
#contact:checked ~ .tab-section .about-tab {
  display: none;
}
#contact:checked ~ .tab-section .contact-tab {
  display: block;
}

/* Media for mobile */
@media only screen and (max-width: 576px) {
  .dash {
    font-size: 1rem;
  }
  #editButton {
    font-size: 0.6rem;
  }
  html {
    font-size: 14px;
  }

  .slide-nav label {
    font-size: 1rem !important;
  }
  .slide-nav {
    width: 90%;
  }
}
@media only screen and (max-width: 360px) {
  html {
    font-size: 12px;
  }
  .slide-nav {
    width: 90%;
  }
  .slide-nav label {
    font-size: 1rem;
  }
}



#messageButton {
outline: none;
border: none;
background-color: transparent;
cursor: pointer;
}



#editButton {
  padding-inline: 15px;
  padding-block: 5px;
  background-color: #26b114;
 border: 1px solid #26b114;
  color: white;
  cursor: pointer;
  font-weight: 600;
  outline: none;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  transition: 200ms ease-in-out
}

#editButton:hover {
  background-color: transparent;
  color: #26b114;
}

.dashNav {
  width:100%;
  height: 75px;
  display: flex;
  align-content: center;
  border-bottom: 1px solid gainsboro;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  padding-left:80px;
}

.dashContain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  padding-inline: 20px;
}

.dash {
  font-size: 1.5rem;
}

.notifyContainer {
  display:inline-flex;
  position: relative;
}

.notifyAlert  {
  display: none;
  height:10px;
  width: 10px;
  border-radius: 50%;
  background:rgb(255, 9, 9);
  position: absolute;
  top:-2px;
  right:30px;
}

.notificationsBox {
  position: absolute;
  top: 1.5rem; /* Adjust based on the size and position of your icons */
  right:-100px;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(86, 107, 177, 0.3);
  padding: 20px 20px;
  z-index: 100;
  width: 250px;
  min-height:300px;
}

.closeNotification {
  position: absolute;
  top:10px;
  right:10px;
  padding:5px;
}

.notificationsBox p:first-child {
  font-size: 1rem;
  font-weight: 700;
}

.analytics, .gridContainer, .promoContainer, .ai-container, .settingsContainer{
  margin-left: 80px;
  width: 100vw - 80px;
  min-height: 100vh;
  padding-inline: 20px;
  padding-block: 20px;
  opacity: 0;
  animation: fade 500ms linear forwards;
  position: relative;
}

.mainAnalytics {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:1.5rem;
  padding-bottom:20px;
}

.mainAnalytics div {
  width: 25%;
  min-height:200px;
  background: white;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
  position: relative;
  border-radius: 20px;
  padding: 20px 20px;
}

.firstAnalytics, .secondAnalytics, .thirdAnalytics {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:1.5rem;
}


.secondAnalytics, .thirdAnalytics {
  margin-top: 1.5rem;
}

.firstAnalytics div, .secondAnalytics div, .thirdAnalytics div {
  position: relative;
  padding: 20px 20px;
  background-color: white;
  border-radius: 20px;
  min-height:450px;
}




.firstAnalytics p:nth-child(1), .secondAnalytics p:nth-child(1), .thirdAnalytics p:nth-child(1) {
  color: #333;
  font-size: 1rem;
}

.analyticTitle {
  font-size: 1.2rem !important;
  position: absolute;
  top:20px;
  left:20px;
}

.users {
  position: absolute;
  padding:10px 10px;
  top:10px;
  right:20px;
  height:20px;
  width:20px;
  background:ghostwhite;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);
  display: grid;
  place-items: center;
  border-radius: 5px;
  color: rgb(167, 167, 167);
}

.thirdAnalytics div {
  width: 50%;
  height: 350px;
}

.firstAnalytics div:nth-child(1) {
  height: 400px;
  width: 30%;
}
.firstAnalytics div:nth-child(2) {
  height: 400px;
  width: 70%;
}


.secondAnalytics div:nth-child(1) {
  height: 400px;
  width: 65%;
}

.secondAnalytics div:nth-child(2) {
  height: 400px;
  width: 35%;
}

.post-drop-down {
  transition: transform 200ms ease-in-out;
  position: absolute;
  top:25px;
  left:150px;
}



.post-drop-down.open  {
  transform: rotate(180deg);
}

#createDropDown {
  display: none;
   flex-direction: column; 
   justify-content: space-evenly;
   opacity: 0;
   transition: opacity 300ms ease-in-out;
}

#createDropDown.see {
  display: flex;
  opacity: 1;
}


.google {
  display: flex;
  justify-content: center;
  align-items: center;
}

 .line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.google .firstLine {
  border-top: 1px solid gainsboro;
  flex-grow: 1;
}

.google .secondLine {
  border-top: 1px solid gainsboro;
  flex-grow: 1;
}

.google .or {
  padding:20px;
}

.file {
  display: none;
}

.file-name {
  position: relative;
  overflow: hidden;
  line-height: 30px;
  padding: 5px;
  box-sizing: border-box;
  font-size: 15px;
  vertical-align: middle;
  width: 100%;
  border: 2px solid #dbdbdb;
  border-radius: 0;
  height: calc(2em + 0.75rem + 2px);
}

.fileBtn {
  background: linear-gradient(to right, rgb(97, 9, 240) 0%, rgb(97, 9, 240) 50%, rgb(72, 25, 126) 50%, rgb(72, 25, 126) 100%);
    background-size: 200% 100%;
    transition: background-position 0.3s;
    border: none;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    height: calc(2em + 0.75rem + 2px);
    margin-left: 5px;
}

.fileBtn:hover {
  background-position: -100% 0;
}

.blur {
  filter: blur(7px);
}

.promoButton {
  outline: none;
      color: hsl(162, 54%, 53%);
      background: transparent;
      border: 1px solid hsl(162, 54%, 53%);
      min-width: 200px;
      max-width: 275px;
      padding-inline: 20px;
      padding-block: 7px;
      text-align: center;
      font-weight: 500;
      font-size: 0.8rem;
      border-radius: 2px;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
      cursor: pointer;
      transition: 400ms ease;
}

.promoButton:hover {
  color: white;
  background: hsl(162, 54%, 53%);
}


.open-ai-loader-container {
  inset: 0;
  display: grid;
  place-content: center;
  position: fixed;
}
.open-ai-loader {
  opacity: 1;
  z-index: 0;
  width: 45px;
  aspect-ratio: .75;
  --c: no-repeat linear-gradient(#505050 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  animation: l7 1s infinite linear alternate;
}
@keyframes l7 {
  0%  {background-size: 20% 50% ,20% 50% ,20% 50% }
  20% {background-size: 20% 20% ,20% 50% ,20% 50% }
  40% {background-size: 20% 100%,20% 20% ,20% 50% }
  60% {background-size: 20% 50% ,20% 100%,20% 20% }
  80% {background-size: 20% 50% ,20% 50% ,20% 100%}
  100%{background-size: 20% 50% ,20% 50% ,20% 50% }
}


.ai-chat-box {
  background-color: white;
  min-width: 100vw - 225px;
  min-height:70vh;
  max-height: 70vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  padding-bottom:50px;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);

}

.chat-box-header {
  height:75px;
  width: 100%;
  border-bottom: 1px solid gainsboro;
  color: #5c5c5c;
  font-size: 1rem;
}

.chat-box-header p {
  margin: 20px;
}

.chat-input-container {
  width:  100%;
  min-height:100px;
  max-height: 100px;
  padding:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background:white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 10px 10px rgba(23, 79, 138, 0.1);

}

.input-holder, .input-holder form{
  width: 100%;
  border: 1px solid gainsboro;
  height:50px;
  display: flex;
  align-items: center;
}

.input-holder form #user-prompt {
  height: 100%;
  width: 75%;
  outline: none;
  border: none;
  background-color: ghostwhite;
}

.input-holder form #user-prompt-btn {
  width: 25%;
  height: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  background: linear-gradient(to right, rgb(97, 9, 240) 0%, rgb(97, 9, 240) 50%, rgb(72, 25, 126) 50%, rgb(72, 25, 126) 100%);
    background-size: 200% 100%;
    transition: background-position 0.3s;
    border: none;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.input-holder form #user-prompt-btn:hover {
  background-position: -100% 0;
}

  .chat-message {
    display: inline-block;
    padding: 10px 15px;
    margin:20px 20px;
    border-radius: 15px;
    max-width: 70%;
  }

  .userActivity {
    position: relative;
  }

  .userActivity p:nth-child(2) {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    font-weight:600;
    font-size: 3rem;
  }

  .ai-chat-box .chat-message.user-message {
  align-self: flex-end !important;
  background-color: #DCF8C6 !important;
}

.ai-chat-box .chat-message.ai-message {
  align-self: flex-start !important;
  background-color: #E0E0E0 !important;
}


.biz-grid button{
  margin-top: 15px;
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  background: linear-gradient(to right, rgb(97, 9, 240) 0%, rgb(97, 9, 240) 50%, rgb(72, 25, 126) 50%, rgb(72, 25, 126) 100%);
    background-size: 200% 100%;
    transition: background-position 0.5s;
    border: none;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.biz-grid button:hover {
  background-position: -100% 0;
}

/* HTML: <div class="tooltip">This is a Speech Bubble with a gradient coloration and with border radius </div> */
.tooltip {
  color: #fff;
  font-size: 18px;
  max-width: 28ch;
  text-align: center;
}
.tooltip {
  /* triangle dimension */
  --b: 1em; /* control the base */
  --h: .7em; /* control the height */

  padding: 1em;
  border-radius: 1.2em;
  border-top-right-radius: 0;
  background: 0 100%/calc(100% + var(--h)) calc(100% + var(--h)) 
    linear-gradient(45deg,#d9ffad,#91ff5e); /* the gradient */
  position: relative;
}
.tooltip:before {
  content: "";
  position: absolute;
  inset: calc(-1*var(--h)) calc(-1*var(--h)) 0 0;
  background-image: inherit;
  clip-path: polygon(100% 0,
    calc(100% - var(--h)) calc(var(--b) + var(--h)),
    calc(100% - var(--h) - var(--b)) var(--h));
}

#couponChart {
  min-height:300px;
  min-width:300px;
  max-height: 300px;
  max-width:300px;
}

#myChart {
  margin: 50px auto;
  min-height:350px;
  max-height:350px;
  min-width:300px;
}

#monthlyLineChart {
  margin: 50px auto;
  min-height:300px;
  min-width:800px;
}

#userAnalytics {
  height:75px;
  width:75px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 2px dotted rgb(113, 255, 19);
}


#userAnalytics, #engagementAnalytics, #viewAnalytics, #eventAnalytics, #postAnalytics {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 400;
  color: navy;
}

.imagePreview p {
  margin-block:3px;
}

@media(max-width: 1300px) {
  .firstAnalytics, .secondAnalytics, .thirdAnalytics, .mainAnalytics {
    display: flex;
    flex-direction: column;
  }

  .mainAnalytics div {
    min-width:100%;

  }

  .firstAnalytics div, .secondAnalytics div, .thirdAnalytics div   {
    min-width: 100%;
    min-height: 450px;
  }
}


#myChart {
  height:350px;
}

.switch {
  --_switch-bg-clr: #4369d1;
  --_switch-padding: 4px; /* padding around button*/
  --_slider-bg-clr: rgb(9, 20, 85); /* slider color unchecked */
  --_slider-bg-clr-on: rgb(9, 20, 85); /* slider color checked */
  --_slider-txt-clr: #ffffff;
  --_label-padding: 0.25rem 0.5rem; /* padding around the labels -  this gives the swith it's global width and height */
  --_switch-easing: cubic-bezier(
    0.47,
    1.64,
    0.41,
    0.8
  ); /* easing on toggle switch */

  width: fit-content;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 9999px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  position: relative;
  isolation: isolate;
  margin: 0px auto;
  margin-top:10px;
    
}

.switch input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.switch > span {
  display: grid;
  place-content: center;
  transition: opacity 300ms ease-in-out 150ms;
  padding: var(--_label-padding);
  font-size: 0.8rem;
  color: white;
}
.switch::before,
.switch::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  transition: inset 150ms ease-in-out;
}
/* switch slider */
.switch::before {
  background-color: var(--_slider-bg-clr);
  inset: var(--_switch-padding) 50% var(--_switch-padding)
    var(--_switch-padding);
  transition: inset 500ms var(--_switch-easing),
    background-color 500ms ease-in-out;
  z-index: -1;
  box-shadow:inset 0 1px 1px rgba(0,0,0,.3),0 1px rgba(255,255,255,.3);
}
/* switch bg color */
.switch::after {
  background-color: var(--_switch-bg-clr);
  inset: 0;
  z-index: -2;
  
}
/* switch hover */
.switch:hover::after {
  inset: -0.25rem;
}
/* checked - move slider to right */
.switch:has(input:checked)::before {
  background-color: var(--_slider-bg-clr-on);
  inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding)
    50%;
}
/* checked - set opacity */
.switch > span:last-of-type,
.switch > input:checked + span:first-of-type {
  opacity: 0.5;
}
.switch > input:checked ~ span:last-of-type {
  opacity: 1;
}

</style>