<template>
    <h1>Congrats! You Signed up!</h1>
    <p>Welcome {{ actualUser }}</p>
</template>



<script setup>
import { getAuth } from 'firebase/auth';
import { ref } from 'vue';

const auth = getAuth();
const user = auth.currentUser;

const actualUser = ref('')

actualUser.value = user.email

</script>