<template>
    <body>
      <div class="container" >
      
   
    
        <form @submit.prevent="firstNext(selectedUserType)" v-if="firstForm" class="registration-form-one">
          <h1 style="color: navy;">Create Account <font-awesome-icon icon="user-plus" class="fa" style="margin-left: 15px; font-size: 2rem;" /></h1>
          <hr>
          <div class="form-group">
            <label for="businessName">Are you an Individual or Business?</label>
                <select id="userType" v-model="selectedUserType" class="custom-select">
                <option selected value="Individual">Individual</option>
                <option value="Business">Business</option>
                </select>
    
          </div>
          <button type="submit" style="width: 100%;">Next</button>
        </form>
  
  
        <form @submit.prevent="register" v-if="secondForm" class="registration-form-three">
          <h1 style="padding-bottom: 25px; color: navy; font-weight: 700">Sign Up</h1>

 
        
          <!-- <div class="googleSignUp" @click="googleSignUp"> <img src="../assets/icons8-google-48.png" height="30" width="30" style="margin-right: 10px;" />Google</div>
          <div class="google">
                    <div class="line">
                        <div class="firstLine"></div>
                        <div class="or">OR</div>
                        <div class="secondLine"></div>
                    </div>
                </div> -->
          <div class="form-group">
            <label for="email">First Name</label>
            <div class="inputHolder">
                <font-awesome-icon icon="user" class="fa" />
              <input type="text" id="name" v-model="firstName" required />
              </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <div class="inputHolder">
                <font-awesome-icon icon="envelope" class="fa" />
              <input type="email" id="email" v-model="email" required />
              </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="inputHolder">
              <font-awesome-icon icon="lock" class="fa" />
              <input type="password" id="password" v-model="password" required />
             </div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
         
            <button type="submit" style="width: 100%;" ><span>Submit</span>
            </button>
            
          </div>

          <p>By Registering, you agree to our Terms and Conditions & Privacy Policy.</p>

        </form>
     
      </div>

      <div class="loadingHolder" v-if="loading || registerCompleted">
        <div class="custom-loader" v-if="loading">
                </div>
                <Vue3Lottie  v-if="registerCompleted" :animationData="CheckmarkAnimation" :height="200" :width="200" speed="1" loop="1" />
      </div>
     
    </body>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup   } from 'firebase/auth'; // Import Firebase authentication method
  import { collection, addDoc } from "firebase/firestore"; 
  import { db } from '../../firebase'
  import { useRouter } from 'vue-router';
  import CheckmarkAnimation from '../assets/animations/checkmark_animation.json';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
 
  const router = useRouter();
  const provider = new GoogleAuthProvider();
  
  
  const firstName = ref('');
  const email = ref('');
  const password = ref('');
  const firstForm = ref(true);
  const secondForm = ref(false)
  const registerCompleted = ref(false);
  const selectedUserType = ref('Individual'); // Default selection
  let loading = ref(false)
  const auth = getAuth();
  
  
  
  
  const firstNext = (userType) => {
    if(userType == 'Individual') {
        firstForm.value = false;
        secondForm.value = true;
    }
     else {
        router.push({name: 'advertise'})
     }

  }
  

  
  const goToPreviousForm = () => {
    if (secondForm.value) {
      secondForm.value = false;
      firstForm.value = true;
    } 
  };

  const addExtraDocument = async (uid) => {
  try {
    const docRef = await addDoc(collection(db, `Users/${uid}/Docs`), {
      userType: 'Individual',
      name: firstName.value,
    });
    return docRef;
  } catch (e) {
    console.error('Error adding document: ', e);
    throw e;
  }
};

const addGoogleExtraDocument = async (uid, name) => {
  try {
    const docRef = await addDoc(collection(db, `Users/${uid}/Docs`), {
      userType: 'Individual',
      name: name,
    });
    return docRef;
  } catch (e) {
    console.error('Error adding document: ', e);
    throw e;
  }
};
  
  
  const addDocument = async (uid, emailValue, name) => {
    try {
      const docRef = await addDoc(collection(db, `Individual/${uid}/Docs`), {
        email: emailValue,
        name: name
      });
      return docRef; // return docRef for better control
    } catch (e) {
      console.error('Error adding document: ', e);
      throw e; // rethrow error to handle it in the calling function
    }
  };

  const saveUser = async (uid, email, name) => {
        try {
          await addDocument(uid, email, name);
            await addGoogleExtraDocument(uid, name);
            loading.value = false;
           
            router.push('/');
        } catch(error) {
          Alert('Something went wrong.')
        }
  }

  
  const googleSignUp = async () => {
      loading.value = true;
        const auth = getAuth();
        signInWithPopup(auth, provider)
          .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            if(user) {
              saveUser(user.uid, user.email, user.displayName)
            }
            
          
          }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            loading.value = false;
            
            
          });
      }
  
  const register = async () => {
    loading.value = true;
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
      const user = userCredential.user;
      const uid = user.uid;
      // await addDocument(uid);
      await addExtraDocument(uid)
      .then(() => {
        registerCompleted.value = true;
        loading.value = false;
        setTimeout(() => {
          router.push('/');
        }, 2000);
      })
     
    } catch (error) {
      loading.value = false;
      toast("Registration Failed.", {
              "type": "error",
              "transition": "bounce",
              autoClose: 2000
            })
    }
  };

  onMounted(() => {
    window.scrollTo(0,0);
  })

 
  
  </script>
  
  <style scoped>
  
  body {
    margin: 0px;
    padding: 0px;
    background: #ffffff;
  }

  .svgContainer {
    position: relative;
  }

  .custom-shape-divider-bottom-1719694011 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1719694011 svg {
    position: relative;
    display: block;
    width: calc(137% + 1.3px);
    height: 150px;
    z-index: -1;
}

.custom-shape-divider-bottom-1719694011 .shape-fill {
    fill: rgb(209, 23, 23);
    z-index: -1;
}

  .loadingHolder {
    inset: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-loader {
      width:40px;
      height:40px;
      border-radius:50%;
      background:conic-gradient(#0000 10%,#FFFFFF);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 0.5s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}


  .custom-select {
  width: 100%;
  position: relative;
}

select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
  appearance: none;
  -webkit-appearance: none;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}

  
  
  .lottieContainer {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  h1  {
    padding-top:50px;
    font-weight: 500;
    text-align: center;
    color: rgb(41, 41, 41);
  }
  hr {
    width: 70%;
    margin: 15px auto;
  }
  
  .currentlyLoading {
    opacity: 0.5;
    filter: brightness(0.5);
    }
  
  .loaderIcon {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
  
  .registration-form-one {
    margin-top: 50px;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    background: rgba(255,255,255,1);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    animation: left 500ms;
}


  
  .registration-form-three {
    margin-top: 50px;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    background: rgba(255,255,255,1);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    animation: left 500ms;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes left {
    from {
      transform: translateX(-500px);
    }
    to {
      transform:translateX(0px);
    }
  }
  
  .form-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  .form-group img {
    position: absolute;
      right:20px;
      top:70%;
      transform: translateY(-50%);
  }
  
  label {
    display: block;
    text-align: left;
    padding-bottom: 15px;
    margin-left: 5px;
    color: navy;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 90%;
        padding: 15px;
        box-sizing: border-box;
        border: none;
        background-color: #fafafa;
        border-bottom: 1px solid gainsboro;
  }
  
  /* input[type="text"]:valid, input[type="email"]:valid, input[type="password"]:valid {
    border:1px solid rgb(61, 245, 61);
  } */

  
  .googleSignUp {
    width:100%;
    padding:10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 10px auto;
    cursor: pointer;
    font-weight: 500;
    color:rgb(46, 46, 46);
  }

  .google {
    display: flex;
    justify-content: center;
    align-items: center;
  }

   .line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .google .firstLine {
    border-top: 1px solid gainsboro;
    flex-grow: 1;
  }

  .google .secondLine {
    border-top: 1px solid gainsboro;
    flex-grow: 1;
  }

  .google .or {
    padding:20px;
  }

  
  
  
  button {
    background-color: rgb(89, 73, 194); 
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  transition: 500ms ease;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  button:hover {
    transform: scale(0.95);
  }

  .inputHolder {
        display: flex;
        flex-direction: row;
      }

      .container .form-group .inputHolder .fa {
        color: rgb(255, 255, 255);
        padding:16px;
        background-color: #d4d4d4;
      }
  

      .stepcontainer {
  text-align: center;
  margin-top: 50px;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  max-width: 100%;
  width: 350px;
  position: relative
}

.progress-container::before {
  content: "";
  background-color: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  width: 100%;
  transform: translateY(-50%);
  z-index: -1;
}

.progress {
  background-color: var(--line-border-fill);
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  width: 0%;
  transform: translateY(-50%);
  z-index: -1;
  transition: .4s ease;
}

.circle {
  background-color: white;
  color: #999;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--line-border-empty);
  transition: .4s ease;
}

.circle.active {
  border-color: var(--line-border-fill)
}

.btn {
  background-color: var(--line-border-fill);
  color: #fff;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 30px;
  margin: 5px;
  font-size: 14px;
}

.btn:active {
  transform: scale(.98);
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  background-color: var(--line-border-empty);
  cursor: not-allowed;
}

p {
  font-size: 0.7rem;
  margin-top: 15px;
  text-align: center;
}


  
  @media(max-width: 700px) {
        .registration-form-one, .registration-form-two, .registration-form-three {
        background-color: transparent;
        box-shadow: 0px 0px;
        }
        h1 {
          text-align: left;
          font-weight: 600;
          color: navy;
        }
        .container {
          margin-top: -75px;
        }

        hr {
          margin:15px;
          margin-left: 2px;
        }

      }

   
  </style>
  